import React from 'react';
import { Decimal } from 'decimal.js';
import { formatCurrency } from 'src/helpers/currency';
import { ColumnType } from 'src/components/molecules/DataTable';
import ProductAllocationForm, {
  AllocationAmountField,
  AllocationDestination,
} from 'src/components/molecules/ProductAllocationForm';

function AllocateTransferPreSeason(props: {
  actualValueSelected: string;
  balanceAvailable: string;
  onAllocationChanged: (allocations: AllocationDestination[]) => void;
  products: Product[];
}) {
  const calculateStandardDeduction = (recuperationRate): string =>
    new Decimal(props.actualValueSelected).times(new Decimal(recuperationRate)).toFixed(2);

  const onAutofill = (product, remaining) => {
    return remaining.greaterThan(0) ? new Decimal(remaining) : new Decimal(0);
  };

  const columns = [
    { displayName: 'ID', name: 'identifier' },
    { displayName: 'Company', name: 'companyName' },
    {
      displayName: 'Std. Deduction',
      name: 'recuperationRate',
      type: ColumnType.Number,
      formatter: val => formatCurrency(calculateStandardDeduction(val)),
    },
    {
      displayName: 'Amount',
      name: 'amount',
      type: ColumnType.Number,
      formatter: (_, row) => <AllocationAmountField category="pre-season" product={row} />,
    },
  ];

  return (
    <ProductAllocationForm
      balanceAvailable={props.balanceAvailable}
      category="pre-season"
      columns={columns}
      description="Pre-Season Products"
      handleAutofillRow={onAutofill}
      onAllocationChanged={props.onAllocationChanged}
      products={props.products}
    />
  );
}

export default AllocateTransferPreSeason;
