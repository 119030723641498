import React from 'react';

import { Link, Route, useParams } from 'react-router-dom';
import { Edit2 } from 'react-feather';
import { groupBy } from 'lodash';

import GET_FUNDING_ACCOUNTS from 'src/graphql/queries/getFundingAccounts';
import { useQueryWithToast } from 'src/helpers/graphql';
import { Button, LoadingSpinner, TabBar } from '@producepay/pp-ui';
import DataTable, { DataTableColumn } from 'src/components/molecules/DataTable';
import Card from 'src/components/elements/Card';
import * as routes from 'src/routes';

import CreateFundingAccount from './CreateFundingAccount';
import EditFundingAccount from './EditFundingAccount';
import SetDefaultPrepaymentSource from './SetDefaultPrepaymentSource';

import styles from './styles.module.css';

const COLUMNS = (companyIdentifier: string): DataTableColumn<FundingAccount>[] => [
  { displayName: 'Account Name', itemClass: 'font-bold', name: 'name' },
  { displayName: 'Bank', name: 'institutionName' },
  { displayName: 'Country', name: 'country' },
  { displayName: 'Wire Fee', name: 'wireFee' },
  {
    displayName: '',
    name: 'identifier',
    formatter: ident => (
      <Link to={routes.companyFundingAccountEdit(companyIdentifier, ident)}>
        <Edit2 className="text-primary" size="14" strokeWidth="2" />
      </Link>
    ),
  },
];

const FundingAccounts = ({ companyIdentifier }) => {
  const { status } = useParams();
  const { data, error, loading, refetch } = useQueryWithToast(GET_FUNDING_ACCOUNTS, {
    variables: { companyIdentifier },
  });

  const handleUpdate = () => setTimeout(refetch, 2000);

  if (loading) {
    return (
      <div className="w-full flex flex-row justify-center">
        <LoadingSpinner />
      </div>
    );
  }
  if (!error) {
    const fundingAccountsByStatus = groupBy(data.fundingAccounts, 'status');
    return (
      <>
        <div className="flex flex-row justify-between items-center">
          <h2 className="font-bold text-xl py-4">Bank Accounts</h2>
          <Link to={routes.companyFundingAccountNew(companyIdentifier)}>
            <Button className="bg-white" variant="outlined">
              Add Account
            </Button>
          </Link>
        </div>
        <div className="pb-3">
          <TabBar
            tabs={[
              {
                badge: fundingAccountsByStatus.active?.length,
                name: 'Active',
                route: routes.companyFundingAccounts(companyIdentifier, 'active'),
              },
              {
                badge: fundingAccountsByStatus.inactive?.length,
                name: 'Inactive',
                route: routes.companyFundingAccounts(companyIdentifier, 'inactive'),
              },
            ]}
          />
        </div>
        <Card square={false}>
          {fundingAccountsByStatus[status] ? (
            <DataTable<FundingAccount>
              className={styles.fundingAccountsTable}
              columns={COLUMNS(companyIdentifier)}
              keyName="identifier"
              rows={fundingAccountsByStatus[status] || []}
              thClass="p-6 pb-0"
              tdClass="p-6 border-b-1"
            />
          ) : (
            <p className="p-6">We did not find any {status} bank accounts for this company.</p>
          )}
        </Card>
        <div className="my-4">
          <SetDefaultPrepaymentSource companyIdentifier={companyIdentifier} />
        </div>
        <Route
          path={routes.companyFundingAccountEdit(':companyId', ':fundingAccountIdentifier')}
          render={({ match }) => (
            <EditFundingAccount
              companyIdentifier={companyIdentifier}
              fundingAccount={data.fundingAccounts.find(fa => fa.identifier === match.params.fundingAccountIdentifier)}
              onUpdate={handleUpdate}
            />
          )}
        />
        <Route
          path={routes.companyFundingAccountNew(':companyId')}
          render={() => <CreateFundingAccount companyIdentifier={companyIdentifier} onUpdate={handleUpdate} />}
        />
      </>
    );
  }
  return null;
};

export default FundingAccounts;
