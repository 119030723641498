import React from 'react';

import { formatCurrency } from 'src/helpers/currency';

interface ExcessDetailRowProps {
  companyName: string;
  note: string;
  amount: string;
}

function ExcessDetailRow(props: ExcessDetailRowProps) {
  return (
    <tr>
      <td>{props.companyName}</td>
      <td>{props.note}</td>
      <td className="text-right">{formatCurrency(props.amount)}</td>
      <td />
    </tr>
  );
}

export default ExcessDetailRow;
