import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { Link as LinkIcon } from 'react-feather';
import Container from 'src/components/elements/Container';
import { Grid } from '@producepay/pp-ui';
import SideNav from 'src/components/molecules/SideNav';
import * as routes from 'src/routes';

import FundingAccounts from './FundingAccounts';

interface CompanyProfileProps {
  companyIdentifier: string;
}

const CompanyProfile = ({ companyIdentifier }: CompanyProfileProps) => (
  <>
    <Container>
      <Grid container>
        <Grid sm="1/5">
          <SideNav
            items={[
              {
                name: 'Bank Accounts',
                icon: <LinkIcon size={20} />,
                url: routes.companyFundingAccounts(companyIdentifier, ''),
              },
            ]}
          />
        </Grid>
        <Grid sm="4/5">
          <Switch>
            <Route
              path={routes.companyFundingAccounts(':companyIdentifier', ':status')}
              render={() => <FundingAccounts companyIdentifier={companyIdentifier} />}
            />
            <Redirect
              from={routes.companyProfile(':companyIdentifier')}
              to={routes.companyFundingAccounts(companyIdentifier)}
            />
          </Switch>
        </Grid>
      </Grid>
    </Container>
  </>
);

export default CompanyProfile;
