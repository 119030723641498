import React from 'react';

interface DotIndicatorProps {
  size?: string;
  color?: string;
}

function DotIndicator({ size, color }: DotIndicatorProps) {
  const dotStyle = {
    backgroundColor: color,
    width: size,
    height: size,
    borderRadius: '50%',
    boxShadow: `0 0 0px 4px ${color}33`,
  };

  return (
    <div className="inline-block bottom-0 pr-3 align-baseline">
      <div style={dotStyle} />
    </div>
  );
}

DotIndicator.defaultProps = {
  size: '10px',
  color: 'currentColor',
};

export default DotIndicator;
