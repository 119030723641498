import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import UnreconciledPaymentDashboard from '../../components/organisms/UnreconciledPaymentDashboard';

function UnreconciledIndexView(): JSX.Element {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={match.path}>
        <UnreconciledPaymentDashboard />
      </Route>
    </Switch>
  );
}

export default UnreconciledIndexView;
