import React, { useState, ReactNode, useEffect } from 'react';
import { isFunction } from 'lodash';
import Checkbox from 'src/components/elements/Checkbox';
import ChevronDown from '@producepay/pp-ui/dist/components/icons/ChevronDown';
import ChevronUp from '@producepay/pp-ui/dist/components/icons/ChevronUp';
import * as styles from './index.module.css';

export enum ColumnType {
  String,
  Number,
  Date,
}

export interface DataTableColumn<RowType> {
  headerClass?: string;
  displayName: string | null;
  itemClass?: string;
  formatter?: (value: string | number | void, row: RowType) => JSX.Element | ReactNode;
  name: string | null;
  sorted?: boolean | null;
  type?: ColumnType;
  maxWidth?: number | string;
}

export interface DataTableProps<RowType> {
  checkboxClass?: string;
  className?: string;
  columns: DataTableColumn<RowType>[];
  keyName: string;
  rows: RowType[];
  filter?: (row: RowType) => boolean;
  onClick?: (row: RowType) => void;
  onSelect?: (justSelected: RowType | RowType[], rowsSelected: RowType[]) => void;
  onSortChanged?: (comparator: (a, b) => number) => void;
  selectable?: boolean;
  sortable?: boolean;
  tbodyClass?: string;
  tdClass?: string | ((item: RowType) => string);
  thClass?: string;
  trClass?: string | ((item: RowType) => string);
}

export const LinkFormatter = ({ url, children = null, newTab = true, ...props }) => (
  <a
    className="text-primary font-bold"
    href={url}
    rel={newTab && 'noopener noreferrer'}
    target={newTab && '_blank'}
    {...props}
  >
    {children}
  </a>
);

export const DataTableStyleWide = {
  tdClass: "p-4 border-b-1",
  thClass: "bg-white px-4 pt-5",
};

function evalClass<T>(format: string | ((item: T) => string), item: T): string {
  return isFunction(format) ? format(item) : format;
}

function DataTable<T>({
  checkboxClass = '',
  className = '',
  columns,
  keyName,
  filter,
  onClick = null,
  onSelect,
  rows,
  onSortChanged = null,
  sortable = false,
  selectable = false,
  tbodyClass = '',
  tdClass = '',
  thClass = '',
  trClass = '',
  ...props
}: DataTableProps<T>) {
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDir, setSortDir] = useState('desc');
  const [selectedKeys, setSelectedKeys] = useState([]);
  const changeSort = (column: string, dir: string) => {
    setSortColumn(column);
    setSortDir(dir);
  };
  const _rows = filter ? rows.filter(filter) : Array.from(rows);
  const cast = val => {
    switch (columns.find(({ name }) => name === sortColumn).type) {
      case ColumnType.Number:
        return parseFloat(val);
      case ColumnType.Date:
        return Date.parse(val) || 0;
      default:
        return val;
    }
  };
  const compare = (a, b) => {
    const comp = cast(a[sortColumn]) > cast(b[sortColumn]) ? 1 : -1;
    return sortDir === 'desc' ? comp : -1 * comp;
  };
  if (sortColumn) {
    _rows.sort(compare);
  }
  useEffect(() => {
    onSortChanged && onSortChanged(sortColumn && compare);
  }, [sortColumn, sortDir, onSortChanged]);

  const selectAll = () => {
    setSelectedKeys(_rows.map(r => r[keyName]));
    onSelect && onSelect(null, _rows);
  };
  const deselectAll = () => {
    setSelectedKeys([]);
    onSelect && onSelect(null, []);
  };
  const selectOne = key => {
    const newKeys = [...selectedKeys, key];
    setSelectedKeys(newKeys);
    onSelect &&
      onSelect(
        _rows.find(r => r[keyName] === key),
        _rows.filter(r => newKeys.includes(r[keyName])),
      );
  };
  const deselectOne = key => {
    const newKeys = selectedKeys.filter(k => k !== key);
    setSelectedKeys(newKeys);
    onSelect &&
      onSelect(
        null,
        _rows.filter(r => newKeys.includes(r[keyName])),
      );
  };
  return (
    <table className={`${styles.dataTable} ${className}`} {...props}>
      <thead>
        <tr>
          {selectable && (
            <th className={checkboxClass}>
              <Checkbox
                checked={selectedKeys.length === _rows.length}
                data-testid="select-all"
                onClick={e => (e.target.checked ? selectAll() : deselectAll())}
              />
            </th>
          )}
          {columns.map(({ headerClass, displayName, name, sorted = true, type, maxWidth = 'auto' }) => (
            <th
              className={`${type === ColumnType.Number ? 'text-right' : 'text-left'} p-2 ${thClass} ${headerClass ||
                ''}`}
              key={`table-header-${name}`}
              style={{ maxWidth }}
            >
              {displayName}
              &nbsp;
              {sortable &&
                sorted &&
                (name === sortColumn && sortDir === 'desc' ? (
                  <ChevronDown className="inline-block" size={11} onClick={() => changeSort(name, 'asc')} />
                ) : (
                  <ChevronUp className="inline-block" size={11} onClick={() => changeSort(name, 'desc')} />
                ))}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className={tbodyClass}>
        {_rows.map(row => (
          <tr
            key={`table-row-${row[keyName]}`}
            className={evalClass(trClass, row)}
            onClick={() => onClick && onClick(row)}
          >
            {selectable && (
              <td className={checkboxClass}>
                <Checkbox
                  checked={selectedKeys.includes(row[keyName])}
                  data-testid="select-row"
                  onClick={e => (e.target.checked ? selectOne(row[keyName]) : deselectOne(row[keyName]))}
                />
              </td>
            )}
            {columns.map(({ formatter, name, itemClass = '', type, maxWidth = 'auto' }) => (
              <td
                className={`${type === ColumnType.Number ? 'text-right' : 'text-left'} p-2 ${evalClass(
                  tdClass,
                  row,
                )} ${itemClass}`}
                key={`table-datum-${row[keyName]}-${name}`}
                style={{ maxWidth }}
              >
                {(formatter ? formatter(row[name], row) : row[name]) || ''}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default DataTable;
