import React from 'react';
import { formatCurrency } from 'src/helpers/currency';
import { Decimal } from 'decimal.js';
import { isFuture } from 'date-fns';
import DataTable, { ColumnType, LinkFormatter } from 'src/components/molecules/DataTable';
import AlertCircle from '@producepay/pp-ui/dist/components/icons/AlertCircle';
import CommandToggle from 'src/components/molecules/CommandToggle';
import Tooltip from 'src/components/elements/Tooltip';
import { HoldProductFundsCommand, UnholdProductFundsCommand } from 'src/helpers/command/types';

interface InSeasonTableProps {
  onHoldStatusChanged: () => void;
  onSelectionChanged: (selectedRows: Product[]) => void;
  products: Product[];
}

function InSeasonTable({ onHoldStatusChanged, onSelectionChanged, products }: InSeasonTableProps): JSX.Element {
  const columns = [
    {
      displayName: 'Slug',
      name: 'slug',
      type: ColumnType.String,
      formatter: (slug, row) => (
        <LinkFormatter url={row.detailsUrl} newTab>
          {slug}
        </LinkFormatter>
      ),
    },
    { displayName: 'Inv / Ref', name: 'referenceNumber', type: ColumnType.String, maxWidth: '6em' },
    { displayName: 'Received On', name: 'receivedDate', type: ColumnType.String },
    {
      displayName: 'Available to Transfer',
      name: 'availableBalance',
      formatter: val => formatCurrency(val),
      type: ColumnType.Number,
    },
    { displayName: 'Released On', name: 'releaseDate', type: ColumnType.Number },
    {
      displayName: '',
      name: 'detailsUrl',
      type: ColumnType.String,
      sorted: false,
      formatter: (detailsUrl, { releaseDate }) =>
        isFuture(new Date(releaseDate)) ? (
          <Tooltip
            tooltipText="Transfer activity will need to be reverted if a check bounces."
            align={{ points: ['tc', 'bc'] }}
          >
            <AlertCircle color="#FFAB48" size="12" />
          </Tooltip>
        ) : null,
    },
    {
      displayName: 'Hold',
      name: 'identifier',
      sorted: false,
      formatter: (identifier, { heldBalance, slug }) => (
        <CommandToggle<HoldProductFundsCommand | UnholdProductFundsCommand>
          commandTemplate={checked => ({
            command: checked ? 'HoldProductFunds' : 'UnholdProductFunds',
            productIdentifier: identifier,
            productType: 'in-season',
          })}
          id={slug}
          data-testid="hold-toggle"
          initialState={new Decimal(heldBalance || '0').greaterThan(0)}
          onSuccess={onHoldStatusChanged}
          errorTemplate={(err, checked) => ({
            body: err.message,
            header: `Error changing status to "${checked ? 'held' : 'released'}"`,
          })}
          successTemplate={checked => ({
            body: slug,
            header: `Status changed to ${checked ? 'held' : 'released'}`,
          })}
        />
      ),
    },
  ];

  return (
    <DataTable<Product>
      columns={columns}
      keyName="identifier"
      onSelect={(_, rows) => onSelectionChanged(rows)}
      rows={products}
      sortable
      selectable
    />
  );
}

export default React.memo(InSeasonTable);
