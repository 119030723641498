import gql from 'graphql-tag';

export default gql`
  fragment outboundPaymentItemFields on OutboundPaymentItem {
    amount
    approvedAt
    approvedBy
    destinationAccountIdentifier
    destinationAccountName
    detailsUrl
    identifier
    liableCompanyId
    liableCompanyIdentifier
    liableCompanyName
    method
    notes
    preparedAt
    preparedBy
    productIdentifier
    productType
    purpose
    recipientCompanyId
    recipientCompanyIdentifier
    recipientCompanyName
    referenceNumber
    slug
    sourceAccountIdentifier
    sourceAccountName
    status
    suggestedAmount
  }
`;
