import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Container from '../Container';

function CardHeader(props) {
  const { anchorId, title, subtitle, actionItem, actionItemClassName, titleClassName, size } = props;

  const baseTitleClassName = cx(
    '',
    {
      'text-xl': size === 'xl',
      'text-md': size === 'md',
      'text-lg': size === 'lg',
      'mb-1 sm:mb-2': subtitle,
      'anchor-section': anchorId,
    },
    titleClassName,
  );

  return (
    <Container className="border-b-1">
      <div>
        <h2 id={anchorId} className={baseTitleClassName}>
          {title}
        </h2>
        {subtitle ? <div className="text-xs-sm sm:text-sm">{subtitle}</div> : null}
      </div>
      {actionItem ? <div className={actionItemClassName}>{actionItem}</div> : null}
    </Container>
  );
}

CardHeader.propTypes = {
  anchorId: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  actionItem: PropTypes.node,
  actionItemClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  size: PropTypes.string,
};

CardHeader.defaultProps = {
  anchorId: null,
  subtitle: null,
  actionItem: null,
  actionItemClassName: '',
  titleClassName: '',
  size: 'xl',
};

export default React.memo(CardHeader);
