import React from 'react';
import { Decimal } from 'decimal.js';
import { formatCurrency } from 'src/helpers/currency';
import AllocationFooter, { Sum, SubmitButton } from 'src/components/molecules/AllocationFooter';

interface AllocateTransferFooterProps {
  balanceAllocated: string;
  balanceAvailable: string;
  handleAllocation: () => void;
}

const AllocateTransferFooter = (props: AllocateTransferFooterProps): JSX.Element => {
  const { balanceAvailable, balanceAllocated, handleAllocation } = props;
  const positiveBalanceAllocated = new Decimal(balanceAllocated).greaterThan(0);
  const positiveBalanceAvailable = new Decimal(balanceAvailable).greaterThan(0);

  const confirmationMessage = (
    <>
      <span className="font-bold">{formatCurrency(balanceAvailable)} </span>
      has not been allocated and will be kept as amount available to transfer. Proceed anyway?
    </>
  );

  return (
    <AllocationFooter
      sumLayout={
        <>
          <Sum amount={balanceAvailable} label="Balance Available" />
          <Sum amount={balanceAllocated} label="Balance Allocated" />
        </>
      }
    >
      {positiveBalanceAllocated && (
        <SubmitButton
          className="px-12 py-3"
          label="Save Balance Allocation"
          handleSubmit={handleAllocation}
          shouldConfirmSubmission={() => (positiveBalanceAvailable ? confirmationMessage : false)}
        />
      )}
    </AllocationFooter>
  );
};

export default AllocateTransferFooter;
