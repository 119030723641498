import React from 'react';
import { filter, map as lodashMap, uniq, orderBy } from 'lodash';
import InSeasonDestination from './InSeasonDestination';
import InSeasonReleaseHeld from './InSeasonReleaseHeld';
import PreSeasonDetail from '../PreSeasonDetail';
import ExcessDetail from '../ExcessDetail';
import UnreconciledDetail from '../UnreconciledDetail';

interface ReallocationDestinationProps {
  allocations: FundActivityAllocation[];
}

function ReallocationDestination(props: ReallocationDestinationProps) {
  const { allocations } = props;
  const COMPONENT_MAP = {
    'in-season': InSeasonDestination,
    'pre-season': PreSeasonDetail,
    excess: ExcessDetail,
    unreconciled: UnreconciledDetail,
  };

  const allocationTypes = uniq(lodashMap(orderBy(allocations, ['destinationType'], ['asc']), 'destinationType'));

  // for inseason allocations, filter out held funds
  const inseasonTransfers = filter(allocations, alloc => {
    return (
      alloc.destinationType === 'in-season' && alloc.sourceProductIdentifier !== alloc.destinationProductIdentifier
    );
  });
  // find a hold release, if any
  const releaseHeldAllocation = allocations.find(alloc => {
    return (
      alloc.destinationType === 'in-season' && alloc.sourceProductIdentifier === alloc.destinationProductIdentifier
    );
  });

  return (
    <div className="pl-5 md:border-l">
      {allocationTypes.map(type => {
        const DetailComponent = COMPONENT_MAP[type];
        // for inseason allocations, use already filtered inseason_transfers
        if (!DetailComponent) {
          return <p>Cannot display details for this transaction.</p>;
        }
        const componentAllocations =
          type === 'in-season' ? inseasonTransfers : filter(allocations, { destinationType: type });

        return componentAllocations.length > 0 ? (
          <div key={componentAllocations[0].allocationIdentifier} className="bg-white">
            <DetailComponent allocations={componentAllocations} />
          </div>
        ) : null;
      })}
      {/* render held funds released component if necessary */}
      {releaseHeldAllocation ? (
        <div className="bg-white">
          <InSeasonReleaseHeld details={releaseHeldAllocation} />
        </div>
      ) : null}
    </div>
  );
}

export default ReallocationDestination;
