import { groupBy, some, uniq, zip } from 'lodash';
import GET_COMPANY_EXPOSURES from 'src/graphql/queries/getCompanyExposures';
import { decimalSum } from 'src/helpers/currency';
import { getIsOverExposed } from 'src/helpers/exposure';
import { PreparationItem } from './preparationItemReducer';

const getExposures = async (client, preparations: PreparationItem[]) => {
  const companyIdentifiers: string[] = uniq(preparations.map(p => p.liableCompanyIdentifier));
  const exposures = await Promise.all(
    companyIdentifiers.map(ident =>
      client.query({ query: GET_COMPANY_EXPOSURES, variables: { companyIdentifier: ident } }),
    ),
  );
  const exposureByCompanyIdentifier = zip(companyIdentifiers, exposures).reduce(
    (acc, [ident, result]) => ({
      ...acc,
      [ident]: result.data.companyExposures,
    }),
    {},
  );
  return exposureByCompanyIdentifier;
};

const getAnyOverExposed = (preparations: PreparationItem[], exposureByCompanyIdentifier) => {
  const preparationsByLiableCompany = groupBy(preparations, p => p.liableCompanyIdentifier);
  return some(preparations, ({ liableCompanyIdentifier }) =>
    getIsOverExposed(
      exposureByCompanyIdentifier[liableCompanyIdentifier],
      decimalSum('amount', preparationsByLiableCompany[liableCompanyIdentifier]),
    ),
  );
};

/** checkExposure
 * Helper specific to the payment preparation process. Checks exposure that will occur given a set of
 * 'PreparationItems', i.e.: possible payments that have been checked but not yet persisted to the server.
 * Returns the warning message to show or null if exposure is under 100% for all items.
 * Currently used only to determine whether to show a warning before "preparing" these payments.
 */

export const checkExposure = async (apolloClient, preparations: PreparationItem[]) => {
  const prepaymentPreparations = preparations.filter(({ purpose }) => purpose === 'prepayment');
  try {
    const exposureByCompanyIdentifier = await getExposures(apolloClient, prepaymentPreparations);
    return (
      getAnyOverExposed(prepaymentPreparations, exposureByCompanyIdentifier) &&
      'One or more companies will be overexposed when this payment is processed. Do you want to proceed?'
    );
  } catch (e) {
    return 'Exposure for the selected products could not be calculated at this time. Do you want to proceed?';
  }
};
