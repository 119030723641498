import React, { useState } from 'react';
import { Select } from '@producepay/pp-ui';

interface AccountSelectProps {
  defaultAccount?: FundingAccount;
  fundingAccounts: FundingAccount[];
  onChange: (item: { label: string; value: string }) => void;
}

const AccountSelect = ({ defaultAccount = null, fundingAccounts, onChange = null }: AccountSelectProps) => {
  const accounts = fundingAccounts.map(a => ({ label: a.name, value: a.identifier }));
  const [selectedItem, setSelectedItem] = useState(
    defaultAccount ? accounts.find(({ value }) => value === defaultAccount.identifier) : accounts[0],
  );
  return (
    <Select
      caretStyle={{ padding: 2 }}
      className="border-b-1 border-t-0 border-l-0 border-r-0 border-dashed p-0 text-xs bg-transparent"
      items={accounts}
      onChange={item => {
        setSelectedItem(item);
        onChange && onChange(item);
      }}
      size={null}
      rounded={false}
      selectedItem={selectedItem}
    />
  );
};

export default AccountSelect;
