import React, { useState } from 'react';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useCommand } from 'src/helpers/command';
import { Modal, useToast } from '@producepay/pp-ui';

import * as routes from 'src/routes';

import SvgBankIcon from 'src/components/icons/Bank';
import { CreateFundingAccountCommand } from 'src/helpers/command/types';
import FundingAccountFields from './FundingAccountFields';
import schema from './FundingAccountSchema';

interface CreateFundingAccountProps {
  companyIdentifier: string;
  onUpdate: () => void;
}

const CreateFundingAccount = ({ companyIdentifier, onUpdate }: CreateFundingAccountProps) => {
  const { send } = useCommand();
  const history = useHistory();
  const { addToastToQueue } = useToast();
  const [isSaving, setIsSaving] = useState(false);

  const handleClose = () => {
    history.push(routes.companyFundingAccounts(companyIdentifier));
  };

  const handleSubmit = async data => {
    try {
      setIsSaving(true);
      await send<CreateFundingAccountCommand>({
        command: 'CreateFundingAccount',
        companyIdentifier,
        country: data.country,
        name: data.name,
        institutionName: data.institutionName,
        status: data.status,
        wireFee: data.wireFee,
      });
      addToastToQueue({
        header: 'Success',
        body: 'Bank Account has been created.',
        type: 'success',
      });
      onUpdate();
      handleClose();
    } catch (e) {
      addToastToQueue({
        actions: [{ label: 'Dismiss' }],
        header: 'Error creating Bank Account',
        body: e.message,
        type: 'error',
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Formik initialValues={{}} onSubmit={handleSubmit} validationSchema={schema}>
      {formik => (
        <Modal
          actions={[
            {
              label: 'Cancel',
              className: 'px-10',
              color: 'default',
              variant: 'text',
              onClick: handleClose,
            },
            { label: 'Save', className: 'px-10', onClick: formik.submitForm, isLoading: isSaving },
          ]}
          title={
            <div className="flex flex-row items-center p-2">
              <span className="inline-block rounded p-2 mr-4 text-primary bg-primary-lighter bg-opacity-25">
                <SvgBankIcon />
              </span>
              <span className="font-bold">Add Bank Account</span>
            </div>
          }
          onClose={handleClose}
        >
          <FundingAccountFields />
        </Modal>
      )}
    </Formik>
  );
};

export default CreateFundingAccount;
