import React from 'react';
import AlignedTable from 'src/components/molecules/ElasticTable/AlignedTable';
import ExcessDetailRow from './ExcessDetailRow';

interface ExcessDetailProps {
  allocations: FundActivityAllocation[];
}

function ExcessDetail(props: ExcessDetailProps) {
  const { allocations } = props;

  const columns = [
    { displayName: 'Company', alignTo: ['created-on', 'effective-on'] },
    { displayName: 'Note', alignTo: ['company', 'link', 'entry-type'] },
    { displayName: 'Allocated', alignTo: ['amount'], align: 'right' },
    { displayName: '', alignTo: ['balance', 'hold'] },
  ];

  return (
    <>
      <h3 className="font-bold p-2">Excess Payment</h3>
      <AlignedTable columns={columns}>
        {allocations.map(allocation => (
          <ExcessDetailRow
            key={`excess-${allocation.allocationIdentifier}`}
            companyName={allocation.destinationCompanyName}
            note={allocation.destinationNotes}
            amount={allocation.destinationAmount}
          />
        ))}
      </AlignedTable>
    </>
  );
}

export default ExcessDetail;
