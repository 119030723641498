import React from 'react';
import cx from 'classnames';

interface FooterProps {
  className?: string;
  children: React.ReactNode;
}

function Footer(props: FooterProps): JSX.Element {
  const { className, children } = props;

  const containerClassName = cx(
    'fixed inset-x-0 bottom-0 w-full px-5 sm:px-8 py-6 flex justify-between text-sm items-center bg-dark w-full',
    className,
  );

  return (
    <div className={containerClassName} data-testid="footer">
      {children}
    </div>
  );
}

export default React.memo(Footer);
