import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { Decimal } from 'decimal.js';

import { formatCurrency } from 'src/helpers/currency';
import { useExcessPaymentContext } from 'src/contexts/ExcessPaymentContext';
import { Button, LoadingSpinner, Grid } from '@producepay/pp-ui';
import Footer from 'src/components/elements/Footer';

const AllocatePaymentFooter = (): JSX.Element => {
  const { ...formik } = useFormikContext();
  const { balanceAvailable, balanceAllocated } = useExcessPaymentContext();
  const [confirmAllocation, setConfirmAllocation] = useState(false);
  const positiveBalanceAllocated = new Decimal(balanceAllocated).greaterThan(0);
  const positiveBalanceAvailable = new Decimal(balanceAvailable).greaterThan(0);
  const onAllocateClick = () => {
    if (positiveBalanceAvailable) {
      setConfirmAllocation(true);
    } else {
      formik.handleSubmit();
    }
  };

  return (
    <Footer>
      <div className="w-full">
        <Grid container>
          <Grid sm="1/6">
            <div className="text-xxs-xs uppercase text-white pb-2" id="amount-available-label">
              Amount Available
            </div>
            <div aria-labelledby="amount-available-label" className="text-lg text-white">
              {formatCurrency(balanceAvailable)}
            </div>
          </Grid>
          <Grid sm="1/6">
            <div className="text-xxs-xs uppercase text-white pb-2" id="amount-allocated-label">
              Amount Allocated
            </div>
            <div aria-labelledby="amount-allocated-label" className="text-lg text-white">
              {formatCurrency(balanceAllocated)}
            </div>
          </Grid>
          {formik.isSubmitting ? (
            <>
              <Grid sm="1/3" />
              <Grid sm="1/3" spacing={0}>
                <div className="px-5 sm:px-8 float-right">
                  <LoadingSpinner className="h-px pr-16" />
                </div>
              </Grid>
            </>
          ) : confirmAllocation ? (
            <>
              <Grid sm="1/2">
                <div className="py-3 text-gray-100 float-right">
                  <span className="font-bold">{formatCurrency(balanceAvailable)} </span>
                  has not been allocated and will be kept as excess payment. Proceed anyway?
                </div>
              </Grid>
              <Grid sm="1/6">
                <div className="float-right w-1/2 px-3">
                  <Button
                    className="w-full py-3 bg-dark"
                    variant="outlined"
                    label="Cancel"
                    type="button"
                    onClick={() => setConfirmAllocation(false)}
                  />
                </div>
                <div className="float-right w-1/2 px-3">
                  <Button className="w-full py-3" label="Yes" type="submit" onClick={formik.handleSubmit} />
                </div>
              </Grid>
            </>
          ) : (
            <>
              <Grid sm="1/3" />
              <Grid sm="1/3">
                <div className="px-5 sm:px-8 float-right">
                  {positiveBalanceAllocated && (
                    <Button className="py-3" label="Save Payment Allocation" type="button" onClick={onAllocateClick} />
                  )}
                </div>
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </Footer>
  );
};

export default AllocatePaymentFooter;
