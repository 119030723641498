import gql from 'graphql-tag';

const UNPREPARED_PRODUCT_FIELDS = `
  category
  detailsUrl
  identifier
  productType
  referenceNumber
  slug
  recipientCompanyName
  recipientCompanyIdentifier
  state
`;

const UNPREPARED_PRODUCTS = gql`
  fragment prepaymentProductFields on PrepaymentProductItem {
    ${UNPREPARED_PRODUCT_FIELDS}
    liableCompanyName
    liableCompanyIdentifier
    maxPrepayment
  }
  fragment balanceReturnFields on BalanceReturnItem {
    ${UNPREPARED_PRODUCT_FIELDS}
    amount
    checkReleaseDate: checkClearDate
    heldAmount
  }
`;

export default UNPREPARED_PRODUCTS;
