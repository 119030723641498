import React from 'react';
import { isEqual, filter, orderBy, uniqWith } from 'lodash';
import ReallocationDetailRow from './ReallocationDetailRow';

interface ReallocationDetailProps {
  allocations: FundActivityAllocation[];
}

function ReallocationDetail(props: ReallocationDetailProps) {
  const { allocations } = props;

  // find uniq sources by type and product identifier (to distinguish source products)
  // and source funds identifier (to distinguish excess payments)
  const orderedAllocations = orderBy(
    allocations,
    ['sourceType', 'sourceProductIdentifier', 'sourceFundsIdentifier', 'sourceSlug'],
    ['asc'],
  );
  const allocationSources = orderedAllocations.map(alloc => {
    return {
      sourceType: alloc.sourceType,
      sourceProductIdentifier: alloc.sourceProductIdentifier,
      sourceFundsIdentifier: alloc.sourceFundsIdentifier,
    };
  });
  const uniqueSources = uniqWith(allocationSources, isEqual);

  return (
    <>
      {/* filter allocations for each source and render the row */}
      {uniqueSources.map(source => {
        const componentAllocations = filter(allocations, {
          sourceType: source.sourceType,
          sourceProductIdentifier: source.sourceProductIdentifier,
          sourceFundsIdentifier: source.sourceFundsIdentifier,
        });
        return (
          componentAllocations.length && (
            <div
              key={componentAllocations[0].allocationIdentifier}
              className="bg-white border-b-4 border-gray-200 last:border-white"
            >
              <ReallocationDetailRow allocations={componentAllocations} />
            </div>
          )
        );
      })}
    </>
  );
}

export default ReallocationDetail;
