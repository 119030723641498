import React from 'react';
import { formatCurrency } from 'src/helpers/currency';
import DataTable, { LinkFormatter } from 'src/components/molecules/DataTable';

interface ReallocationSourceProps {
  details: FundActivityAllocation;
  total: number;
}

function ReallocationSourceInseason(allocation) {
  const columns = [
    {
      name: 'sourceSlug',
      displayName: 'Slug',
      formatter: (slug: string, row) => <LinkFormatter url={row.sourceDetailsUrl}>{slug}</LinkFormatter>,
    },
    { name: 'sourceReferenceNumber', displayName: 'Inv/Ref#' },
    { name: 'sourceFundsCheckNumber', displayName: 'Check #' },
    {
      name: 'sourceLegacyPaymentUrl',
      displayName: 'ID',
      formatter: (url: string) => <LinkFormatter url={url}>view payment</LinkFormatter>,
    },
    {
      name: 'sourceCustomerBalance',
      displayName: 'Cust. Balance',
      headerClass: 'text-right',
      itemClass: 'text-right',
      formatter: (amount: string) => formatCurrency(amount),
    },
  ];

  return (
    <>
      <h3 className="font-bold p-2">In-season</h3>
      <DataTable<FundActivityAllocation> columns={columns} keyName="sourceSlug" rows={[allocation]} />
    </>
  );
}

function ReallocationSourceOther(type: string, allocation: FundActivityAllocation, totalAmount: number) {
  const columns = [
    { name: 'sourceFundsCheckNumber', displayName: 'Check #' },
    {
      name: 'sourceLegacyPaymentUrl',
      displayName: 'ID',
      formatter: (url: string) => <LinkFormatter url={url}>view payment</LinkFormatter>,
    },
    {
      name: 'sourceNotes',
      displayName: 'Note',
    },
    {
      name: 'totalAmount',
      displayName: 'Amount',
      headerClass: 'text-right',
      itemClass: 'text-right',
      formatter: () => formatCurrency(totalAmount),
    },
  ];
  return (
    <>
      <h3 className="font-bold p-2 capitalize">{type}</h3>
      <DataTable<FundActivityAllocation> columns={columns} rows={[allocation]} keyName="sourceSlug" />
    </>
  );
}

function ReallocationSource(props: ReallocationSourceProps) {
  const { details, total } = props;
  const { sourceType } = details;

  if (sourceType === 'in-season') {
    return ReallocationSourceInseason(details);
  }
  return ReallocationSourceOther(sourceType, details, total);
}

export default ReallocationSource;
