import React from 'react';
import { Grid } from '@producepay/pp-ui';
import { formatCurrency } from 'src/helpers/currency';

interface ExternalDetailRowProps {
  referenceNumber: string;
  companyName: string;
  note: string;
  amount: string;
}

function ExternalDetailRow(props: ExternalDetailRowProps) {
  return (
    <>
      <Grid container className="text-sm even:bg-table-highlight hover:border-2 hover:border-blue-400" spacing={0}>
        <Grid sm="1/3">{props.companyName}</Grid>
        <Grid sm="1/6">{props.referenceNumber}</Grid>
        <Grid sm="1/3">{props.note}</Grid>
        <Grid sm="1/6">{formatCurrency(props.amount)}</Grid>
      </Grid>
    </>
  );
}

export default ExternalDetailRow;
