/* eslint-disable react/state-in-constructor */

import React, { PureComponent } from 'react';

import RootView from './view';

class Root extends PureComponent {
  state = {
    isErrored: false,
  };

  static getDerivedStateFromError() {
    return { isErrored: true };
  }

  render() {
    return <RootView isErrored={this.state.isErrored} />;
  }
}

export default React.memo(Root);
