import React from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { useQuery } from '@apollo/client';
import { LoadingSpinner } from '@producepay/pp-ui';
import _ from 'lodash';
import { formatISO } from 'date-fns';
import Card from 'src/components/elements/Card';
import CardBody from 'src/components/elements/CardBody';
import CardHeader from 'src/components/elements/CardHeader';
import Container from 'src/components/elements/Container';
import GET_EXCESS_PAYMENTS from 'src/graphql/queries/getExcessPayments';

import api from 'src/helpers/axios';
import { snakeCaseKeys } from 'src/helpers/lodash';
import { useAuth } from 'src/contexts/auth/AuthProvider';
import { getCompanyId } from 'src/helpers/products';

import { ExcessPaymentContext } from 'src/contexts/ExcessPaymentContext';
import { decimalSum, toDecimal } from 'src/helpers/currency';
import ExcessPaymentIndexTable from './table/ExcessPaymentIndexTable';
import ExcessPaymentFooter from './ExcessPaymentFooter';
import AllocateExcessPaymentForm from './AllocateExcessPaymentForm';

const REDIRECT_DELAY = 2000;

interface ExcessPaymentProps {
  companyIdentifier: string;
  action: string;
}

interface ExcessPayment {
  checkNumber: string;
  senderName: string;
  paymentDate: string;
  excessAmount: string;
}

interface ExcessPaymentValues {
  excessPayments: ExcessPayment[];
  products: Products;
  amountReleased: string;
  allocationDate: Date;
}

function ExcessPaymentIndex(props: ExcessPaymentProps): JSX.Element {
  const companyId = getCompanyId(props.companyIdentifier);
  const history = useHistory();
  const { loading, data } = useQuery(GET_EXCESS_PAYMENTS, {
    variables: { companyIdentifier: props.companyIdentifier },
    fetchPolicy: 'no-cache',
  });
  const { getTokenSilently } = useAuth();

  const initialValues: ExcessPaymentValues = {
    allocationDate: new Date(),
    excessPayments: data && data.excessPayments,
    products: {
      inSeason: [],
      preSeason: [],
    },
    amountReleased: '',
  };

  const calculateBalanceAllocated = (values: ExcessPaymentValues) => {
    return decimalSum<Product>('amount', [...values.products.inSeason, ...values.products.preSeason]).toString();
  };

  const calculateBalanceAvailable = (values: ExcessPaymentValues) => {
    return decimalSum<ExcessPayment>('excessAmount', values.excessPayments)
      .minus(calculateBalanceAllocated(values))
      .toString();
  };

  const mapProducts = (products: Products) => {
    const mergedProducts = products.inSeason.concat(products.preSeason);
    return mergedProducts.map(
      product =>
        product.amount &&
        snakeCaseKeys({
          productIdentifier: product.identifier,
          type: product.category,
          amount: toDecimal(product.amount),
          held: product.held,
        }),
    );
  };

  async function onAllocateExcessPayment(values, formik): Promise<void> {
    const products = mapProducts(values.products);
    const payload = snakeCaseKeys({
      command: 'AllocateExcessPayments',
      companyId,
      allocations: _.compact(products),
      allocationDate: formatISO(values.allocationDate, { representation: 'date' }),
    });
    try {
      const token = await getTokenSilently();
      await api.post('/command', payload, { headers: { Authorization: `Bearer ${token}` } });
      await new Promise(resolve => setTimeout(resolve, REDIRECT_DELAY));
      history.push('/fund_activities');
    } catch (error) {
      const errorMessage = error.response ? error.response.data.error.message : error.message;
      formik.setErrors({ amountReleased: `Error: ${errorMessage}` });
      formik.setSubmitting(false);
    }
  }

  return loading ? (
    <div className="text-center py-32">
      <LoadingSpinner />
    </div>
  ) : (
    <Formik initialValues={initialValues} enableReinitialize onSubmit={onAllocateExcessPayment}>
      {({ values }) => (
        <ExcessPaymentContext.Provider
          value={{
            balanceAvailable: calculateBalanceAvailable(values),
            balanceAllocated: calculateBalanceAllocated(values),
          }}
        >
          <Form>
            {props.action === 'allocate' ? (
              <AllocateExcessPaymentForm companyId={companyId} />
            ) : (
              <>
                <Container>
                  <h2 className="text-xl font-extrabold my-0">Excess Payment</h2>
                </Container>
                <Container>
                  <Card square={false}>
                    <CardHeader size="lg" title="Excess Payments" titleClassName="font-bold" />
                    <CardBody>
                      {values.excessPayments.length ? <ExcessPaymentIndexTable /> : 'No Excess Payments Available'}
                    </CardBody>
                  </Card>
                </Container>
                {!loading && <ExcessPaymentFooter companyIdentifier={props.companyIdentifier} />}
              </>
            )}
          </Form>
        </ExcessPaymentContext.Provider>
      )}
    </Formik>
  );
}

export default ExcessPaymentIndex;
