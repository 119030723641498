import React from 'react';
import PropTypes from 'prop-types';

import { Field } from 'formik';
import { TextField } from '@producepay/pp-ui';
import Wrapper from '../FormikInputWrapper';

/** FormikTextField
 * Wrapper for wiring up a TextField field to the Formik Context.
 * Defers to `FormikInputWrapper` which adds labels and validation
 * error printing.
 */

const FormikTextField = ({ className = '', name, label, type = 'text', ...rest }) => {
  return (
    <Field name={name}>
      {({ field, form }) => (
        <Wrapper className={`${className} pb-4`} name={name} form={form} label={label}>
          <TextField
            aria-labelledby={`form-label-${name}`}
            size="small"
            type={type}
            name={name}
            value={field.value || ''}
            {...field}
            {...rest}
          />
        </Wrapper>
      )}
    </Field>
  );
};

FormikTextField.propTypes = {
  ...TextField.propTypes,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
};

FormikTextField.defaultProps = {
  label: null,
};

export default React.memo(FormikTextField);
