import React from 'react';
import Decimal from 'decimal.js';
import { Card } from '@producepay/pp-ui';
import { groupBy } from 'lodash';
import { decimalSum, formatCurrency } from 'src/helpers/currency';
import { CompanyFundingAccountConfiguration, formatProductType, getDefaultAccounts } from 'src/helpers/products';
import DataTable, { LinkFormatter, DataTableColumn, ColumnType } from 'src/components/molecules/DataTable';

import AccountSelect from './AccountSelect';
import ExposureHeader from './ExposureHeader';
import { PreparationAction, PreparationItem, selectItems, setDestination, setSource } from './preparationItemReducer';

interface UnpreparedCompanyProductsProps {
  dispatchPreparations: (action: PreparationAction) => void;
  fundingAccountConfig: CompanyFundingAccountConfiguration;
  prepaymentProducts: ProductPrepaymentItem[];
  preparations: { [identifier: string]: PreparationItem };
}

const getColumns = (
  dispatch: (action: PreparationAction) => void,
  config: CompanyFundingAccountConfiguration,
): DataTableColumn<ProductPrepaymentItem>[] => [
  {
    name: 'slug',
    displayName: 'Slug',
    headerClass: 'w-3/10',
    formatter: (slug, { detailsUrl }) => (
      <LinkFormatter url={detailsUrl} newTab>
        {slug}
      </LinkFormatter>
    ),
  },
  { name: 'referenceNumber', displayName: 'Inv/Ref #', headerClass: 'w-2/10' },
  {
    name: 'source',
    displayName: 'Source',
    formatter: (_, { identifier }) => (
      <AccountSelect
        defaultAccount={getDefaultAccounts(config).prepayment.source}
        fundingAccounts={config.sourceAccounts}
        onChange={({ value }) => dispatch(setSource(identifier, value))}
      />
    ),
    headerClass: 'w-2/10',
  },
  {
    name: 'destination',
    displayName: 'Destination',
    itemClass: 'max-w-xs',
    formatter: (_, { identifier }) =>
      config.destinationAccounts.length && (
        <AccountSelect
          fundingAccounts={config.destinationAccounts}
          onChange={({ value }) => dispatch(setDestination(identifier, value))}
        />
      ),
    headerClass: 'w-2/10',
  },
  {
    name: 'amount',
    displayName: 'Amount',
    headerClass: 'w-1/10',
    itemClass: 'font-black',
    formatter: (_, p) => formatCurrency(new Decimal(p.maxPrepayment || '0.00')),
    type: ColumnType.Number,
  },
];

const UnpreparedCompanyPrepayments = ({
  dispatchPreparations,
  fundingAccountConfig,
  prepaymentProducts,
  preparations,
}: UnpreparedCompanyProductsProps) => {
  const { liableCompanyName, liableCompanyIdentifier } = prepaymentProducts[0];
  return (
    <>
      <Card className="my-4">
        <ExposureHeader
          companyName={liableCompanyName}
          companyIdentifier={liableCompanyIdentifier}
          totalSelected={decimalSum(
            'amount',
            Object.values(preparations).filter(
              ({ selected, liableCompanyIdentifier: preparationLiableCompanyIdentifier }) =>
                selected === true && preparationLiableCompanyIdentifier === liableCompanyIdentifier,
            ),
          ).toFixed(2)}
        />
        {Object.entries(groupBy(prepaymentProducts, p => p.productType)).map(([type, products]) => (
          <div key={`unprepared-${liableCompanyIdentifier}-${type}`} className="p-4">
            <h5 className="uppercase text-xs text-gray-700 font-black pb-2 tracking-tight">
              {formatProductType(type)}
            </h5>
            <DataTable<ProductPrepaymentItem>
              checkboxClass="pl-2"
              columns={getColumns(dispatchPreparations, fundingAccountConfig)}
              keyName="slug"
              onSelect={(_, selectedItems) => {
                dispatchPreparations(
                  selectItems(
                    selectedItems.map(p => p.identifier),
                    products.map(p => p.identifier),
                  ),
                );
              }}
              rows={products}
              selectable
              sortable
              trClass="even:bg-table-highlight"
            />
          </div>
        ))}
      </Card>
    </>
  );
};

export default UnpreparedCompanyPrepayments;
