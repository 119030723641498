import React from 'react';
import { useFormikContext } from 'formik';
import { Decimal } from 'decimal.js';
import { Grid } from '@producepay/pp-ui';
import Card from 'src/components/elements/Card';

import { formatCurrency } from 'src/helpers/currency';

import InSeasonTable from './InSeasonTable';

interface AllocatePaymentInSeasonProps {
  showHeld?: boolean;
}

function AllocatePaymentInSeason(props: AllocatePaymentInSeasonProps): JSX.Element {
  const { showHeld = true } = props;
  const { values } = useFormikContext<ReceivePaymentFormValues>();

  if (values.products.inSeason.length === 0) {
    return (
      <div className="px-5 sm:px-8 sm:py-3">
        <Card className="px-5 sm:py-8">
          <Grid container>
            <Grid className="md:border-b">
              <div className="float-left font-bold text-lg pb-4">In-Season</div>
            </Grid>
            <Grid>No In-Season Products Available</Grid>
          </Grid>
        </Card>
      </div>
    );
  }

  const totalAllocated = values.products.inSeason
    .reduce((acc, row) => new Decimal(row.amount || '0.00').plus(acc), new Decimal('0.00'))
    .toString();

  return (
    <div className="px-5 sm:px-8 sm:py-3" data-testid="received-payment-allocate-in-season">
      <Card className="px-5 sm:py-8">
        <Grid container>
          <Grid className="md:border-b">
            <div className="float-left font-bold text-lg pb-4">In-Season</div>
            <div className="float-right text-sm">
              <span className="font-bold">{formatCurrency(totalAllocated)}</span>
              <span> allocated to In-Season Products</span>
            </div>
          </Grid>
          <Grid>
            <InSeasonTable showHeld={showHeld} />
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}

export default React.memo(AllocatePaymentInSeason);
