import React, { useEffect, useState } from 'react';
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { ApolloProvider } from '@apollo/client/react';
import { setContext } from '@apollo/client/link/context';
import _ from 'lodash';
import { useAuth } from './auth/AuthProvider';

const backendLink = new HttpLink({
  uri: _.trim(`${process.env.REACT_APP_BACKEND_URL}/query`),
  headers: {},
});

const apolloCache = new InMemoryCache();

const AuthorizedApolloProvider = ({ children }) => {
  const { getTokenSilently, isAuthenticated } = useAuth();
  const [apolloClient, setApolloClient] = useState();
  const authLink = setContext(async (request, { headers }) => {
    const token = await getTokenSilently();

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  useEffect(() => {
    setApolloClient(
      new ApolloClient({
        link: authLink.concat(backendLink),
        cache: apolloCache,
        connectToDevTools: true,
      }),
    );
  }, [isAuthenticated]);

  return apolloClient ? <ApolloProvider client={apolloClient}>{children}</ApolloProvider> : null;
};

export default AuthorizedApolloProvider;
