import React, { useState } from 'react';
import { Select } from '@producepay/pp-ui';
import _ from 'lodash';

import './paginator.css';

const PER_PAGE_OPTIONS = [
  { label: '15', value: '15' },
  { label: '30', value: '30' },
  { label: '50', value: '50' },
];

export interface PaginatorProps {
  pageLimit?: number;
  perPage: number;
  page: number;
  totalCount: number;
  setPerPage: (perPage: number) => void;
  setPage: (page: number) => void;
}

function PageLink(props) {
  return (
    <span key={props.num}>
      <button
        onClick={e => {
          e.preventDefault();
          props.handler(props.num);
        }}
        className={`inline-block mx-1 p-1 page-link rounded-sm bg-white  ${
          props.num === props.current ? 'active font-bold' : ''
        }`}
        type="button"
      >
        {props.num}
      </button>
    </span>
  );
}

function Paginator(props: PaginatorProps) {
  const { pageLimit = 7, perPage, page, totalCount, setPerPage, setPage } = props;
  const [selectedItem, setSelectedItem] = useState(PER_PAGE_OPTIONS[1]);

  const totalPages = Math.ceil(totalCount / perPage);
  const newPages = [];

  // render the pages between the first and the last pages

  // render the following if the page limit is hit:
  // 1 ... 4 5 6 7 8 ... 9
  if (totalPages > pageLimit) {
    // we always want to show the first page
    newPages.push(1);

    if (props.page - 2 > 2) {
      newPages.push('...');
    }

    newPages.push(..._.range(Math.max(2, page - 2), Math.min(totalPages, page + 3)));

    if (page + 2 < totalPages - 1) {
      newPages.push('...');
    }

    // if there's more than 1 page, we always want to show the last page
    if (totalPages > 1) {
      newPages.push(totalPages);
    }
    // if the page count is small enough, we can just render all the pages
    // 1 2 3 4 5 6 7
  } else if (totalPages > 0) {
    newPages.push(..._.range(1, totalPages + 1));
  }

  const startRecord = totalCount === 0 ? 0 : (page - 1) * perPage + 1;
  const endRecord = page * perPage > totalCount ? totalCount : page * perPage;

  return (
    <div className="paginator whitespace-no-wrap">
      <div className="results-count inline-block mr-5 invisible lg:visible">
        {totalCount === 0 ? (
          <span>No results</span>
        ) : (
          <span>
            {startRecord} - {endRecord} of {totalCount} results
          </span>
        )}
      </div>
      <div className="per-page-selector inline-block px-5 invisible lg:visible">
        <span>Show per page</span>
        <Select
          items={PER_PAGE_OPTIONS}
          selectedItem={selectedItem}
          onChange={item => {
            setPerPage(parseInt(item.value, 10));
            setPage(1);
            setSelectedItem(item);
          }}
        />
      </div>
      <div className="page-control inline-block ml-5">
        {newPages.map((pageNumber, index) => (
          <PageLink
            // eslint-disable-next-line react/no-array-index-key
            key={`pagination-${index}`}
            num={pageNumber}
            handler={Number.isInteger(pageNumber) ? props.setPage : () => null}
            current={props.page}
          />
        ))}
      </div>
    </div>
  );
}

export default React.memo(Paginator);
