import React from 'react';
import { format } from 'date-fns';
import { formatCurrency } from 'src/helpers/currency';

interface InSeasonDetailRowProps {
  slug: string;
  referenceNumber: string;
  stillDue: string;
  amount: string;
  heldAmount: string;
  customerBalance: string;
  detailsUrl: string;
  checkReleaseDate: string;
}

const renderReleaseDate = (dateString: string) => {
  if (!dateString) {
    return '';
  }
  const isPassed = Date.parse(dateString) < Date.now();
  const date = new Date(dateString);
  return `${isPassed ? 'Released' : 'Release'} ${format(date, 'MM/dd')}`;
};

function InSeasonDetailRow(props: InSeasonDetailRowProps) {
  return (
    <>
      <tr className="text-sm even:bg-table-highlight">
        <td>
          <a href={props.detailsUrl} className="text-primary font-bold" rel="noopener noreferrer" target="_blank">
            {props.slug}
          </a>
        </td>
        <td>{props.referenceNumber}</td>
        <td>{formatCurrency(props.stillDue)}</td>
        <td className="font-bold text-right">{formatCurrency(props.amount)}</td>
        <td className="text-right">{formatCurrency(props.customerBalance)}</td>
        <td>
          <span className="pl-2">
            {props.heldAmount === '0.00' ? renderReleaseDate(props.checkReleaseDate) : 'Held'}
          </span>
        </td>
      </tr>
    </>
  );
}

export default InSeasonDetailRow;
