import React from 'react';
import AlignedTable from 'src/components/molecules/ElasticTable/AlignedTable';
import InSeasonDetailRow from './InSeasonDetailRow';

interface InSeasonDetailProps {
  allocations: FundActivityAllocation[];
  checkReleaseDate: string;
}

const columns = [
  { displayName: 'Slug', alignTo: ['created-on', 'effective-on'] },
  { displayName: 'Inv/Ref#', alignTo: ['company'] },
  { displayName: 'Still Due', alignTo: ['link', 'entry-type'] },
  { displayName: 'Allocated', alignTo: ['amount'], align: 'right' },
  { displayName: 'Customer Balance', alignTo: ['balance'], align: 'right' },
  { displayName: '', alignTo: ['hold'] },
];

function InSeasonDetail(props: InSeasonDetailProps) {
  const { checkReleaseDate, allocations } = props;

  return (
    <>
      <h3 className="p-2 font-bold">In-season</h3>
      <AlignedTable columns={columns}>
        {allocations.map(allocation => (
          <InSeasonDetailRow
            key={allocation.allocationIdentifier}
            slug={allocation.destinationSlug}
            referenceNumber={allocation.destinationReferenceNumber}
            stillDue={allocation.destinationMinimumStillDue}
            amount={allocation.destinationAmount}
            heldAmount={allocation.destinationHeldAmount}
            customerBalance={allocation.destinationCustomerBalance}
            detailsUrl={allocation.destinationDetailsUrl}
            checkReleaseDate={checkReleaseDate}
          />
        ))}
      </AlignedTable>
    </>
  );
}

export default InSeasonDetail;
