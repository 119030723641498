import React from 'react';
import { Button } from '@producepay/pp-ui';
import { X } from 'react-feather';

export const SidebarHeader = ({
  amount,
  destination,
  onCloseClicked,
}: {
  amount: string;
  destination: string;
  onCloseClicked: () => void;
}) => (
  <>
    <div className="flex flex-row justify-between items-center w-full border-b-1">
      <h4 className="text-sm font-bold p-4">Payment Details</h4>
      <Button color="default" variant="text" onClick={onCloseClicked}>
        <X className="mr-2" size={18} />
      </Button>
    </div>
    <div className="flex flex-col items-center justify-center p-6">
      <h5 className="text-sm">{destination}</h5>
      <h2 className="text-xl font-bold py-1">{amount}</h2>
    </div>
  </>
);

/** Magic number alert:
 * In order to anchor this to the bottom while fitting it in the column,
 * we calculate a % width based on the 1/3w parent and 4/5w grandparent.
 */
export const SidebarFooter = ({ children }: { children: React.ReactNode }) => (
  <div className="fixed bottom-0 bg-white p-3 border-t-1" style={{ width: '26%' }}>
    <div className="w-full flex justify-between">{children}</div>
  </div>
);

export const Sidebar = ({ children }: { children: React.ReactNode }) => (
  <div className="bg-white min-h-full relative overflow-hidden mb-12" data-testid="send-payments-sidebar">
    {children}
  </div>
);

export default Sidebar;
