import React from 'react';
import Decimal from 'decimal.js';
import { Link } from 'react-router-dom';
import { Card } from '@producepay/pp-ui';
import { groupBy } from 'lodash';
import { Edit2 } from 'react-feather';
import { decimalSum, formatCurrency } from 'src/helpers/currency';
import { CompanyFundingAccountConfiguration, formatProductType, getDefaultAccounts } from 'src/helpers/products';
import DataTable, { LinkFormatter, DataTableColumn, ColumnType } from 'src/components/molecules/DataTable';
import * as routes from 'src/routes';

import AccountSelect from './AccountSelect';
import { PreparationAction, selectItems, setDestination, setSource } from './preparationItemReducer';

interface UnpreparedBalanceReturnGroupProps {
  allowPreparation?: boolean;
  balanceReturns: BalanceReturn[];
  dispatchPreparations: (action: PreparationAction) => void;
  fundingAccountConfig: CompanyFundingAccountConfiguration;
  title: string;
}

const getColumns = (
  dispatch: (action: PreparationAction) => void,
  config: CompanyFundingAccountConfiguration,
): DataTableColumn<BalanceReturn>[] =>
  [
    {
      name: 'slug',
      displayName: 'Slug',
      headerClass: 'w-3/10',
      formatter: (slug, { detailsUrl }) => (
        <LinkFormatter url={detailsUrl} newTab>
          {slug}
        </LinkFormatter>
      ),
    },
    { name: 'referenceNumber', displayName: 'Inv/Ref #', headerClass: 'w-1/10' },
    { name: 'checkReleaseDate', displayName: 'Released On', headerClass: 'w-1/10', type: ColumnType.Date },
    config.sourceAccounts && {
      name: 'source',
      displayName: 'Source',
      formatter: (_, { identifier }) => (
        <AccountSelect
          defaultAccount={getDefaultAccounts(config).balanceReturn.source}
          fundingAccounts={config.sourceAccounts}
          onChange={({ value }) => dispatch(setSource(identifier, value))}
        />
      ),
      headerClass: 'w-2/10',
    },
    config.destinationAccounts && {
      name: 'destination',
      displayName: 'Destination',
      itemClass: 'max-w-xs',
      formatter: (_, { identifier }) =>
        config.destinationAccounts.length && (
          <AccountSelect
            fundingAccounts={config.destinationAccounts}
            onChange={({ value }) => dispatch(setDestination(identifier, value))}
          />
        ),
      headerClass: 'w-2/10',
    },
    {
      name: 'amount',
      displayName: 'Amount',
      headerClass: 'w-1/10',
      itemClass: 'font-black',
      formatter: (_, p) => formatCurrency(new Decimal(p.amount || '0.00')),
      type: ColumnType.Number,
    },
  ].filter(Boolean);

const UnpreparedBalanceReturnGroup = ({
  allowPreparation = true,
  balanceReturns,
  dispatchPreparations,
  fundingAccountConfig,
  title,
}: UnpreparedBalanceReturnGroupProps) => {
  return (
    <>
      <Card className="my-4">
        <div className="border-b-1 p-4 flex flex-row justify-between items-center">
          <h3 className="font-bold text-lg">{title}</h3>
          <h4 className="flex flex-row">
            <span className="px-2">{formatCurrency(decimalSum('amount', balanceReturns).toFixed(2))}</span>
            {!allowPreparation && (
              <Link to={routes.companyTransfer(balanceReturns[0].recipientCompanyIdentifier)}>
                <Edit2 className="stroke-current text-primary" size={16} />
              </Link>
            )}
          </h4>
        </div>
        {Object.entries(groupBy(balanceReturns, p => p.productType)).map(([type, products]) => (
          <div key={`unprepared-${title}-${type}`} className="p-4">
            <h5 className="uppercase text-xs text-gray-700 font-black pb-2 tracking-tight">
              {formatProductType(type)}
            </h5>
            <div className={`${allowPreparation ? '' : '-ml-2'}`}>
              <DataTable<BalanceReturn>
                checkboxClass="pl-2"
                columns={getColumns(dispatchPreparations, allowPreparation && fundingAccountConfig)}
                keyName="slug"
                onSelect={(_, selectedItems) => {
                  dispatchPreparations(
                    selectItems(
                      selectedItems.map(p => p.identifier),
                      products.map(p => p.identifier),
                    ),
                  );
                }}
                rows={products}
                selectable={allowPreparation}
                sortable
                trClass="even:bg-table-highlight"
              />
            </div>
          </div>
        ))}
      </Card>
    </>
  );
};

export default UnpreparedBalanceReturnGroup;
