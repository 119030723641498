import React from 'react';
import RCTooltip from 'rc-tooltip';
import { AlignType } from 'rc-trigger/lib/interface';

import './tooltip.css';

interface TooltipProps {
  align?: AlignType;
  children: React.ReactNode;
  placement?: string;
  tooltipText: React.ReactNode;
}

function Tooltip(props: TooltipProps) {
  const { align = null, placement = 'bottom' } = props;

  return (
    <RCTooltip align={align} placement={placement} overlay={<span>{props.tooltipText}</span>}>
      <span>{props.children}</span>
    </RCTooltip>
  );
}

export default React.memo(Tooltip);
