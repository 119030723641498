import Decimal from 'decimal.js';
import { keyBy, isUndefined } from 'lodash';
import { CompanyFundingAccountConfiguration, getDefaultAccounts } from 'src/helpers/products';

enum ReducerTypes {
  SET_STATE,
  SELECT_PREPARATION_ITEMS,
  SET_PREPARATION_ITEM_SOURCE,
  SET_PREPARATION_ITEM_DESTINATION,
}

export interface PreparationItem {
  amount: string;
  destinationAccountIdentifier: string;
  productIdentifier: string;
  purpose: string;
  selected: boolean;
  sourceAccountIdentifier: string;
  liableCompanyIdentifier?: string;
}

export interface PreparationAction {
  type: ReducerTypes;
  productIdentifier?: string;
  selections?: { [productIdentifier: string]: boolean };
  destination?: string;
  source?: string;
  state?: string;
}

interface PreparationState {
  [ident: string]: PreparationItem;
}

export const reducer = (state, { type, ...action }): PreparationState => {
  switch (type) {
    // when changing companies, the reducer will stay alive but we want to wipe out its state
    case ReducerTypes.SET_STATE:
      return action.state;
    // due to the API of DataTable, it's best if we always do these in bulk
    case ReducerTypes.SELECT_PREPARATION_ITEMS:
      return Object.keys(state).reduce(
        (acc, id) => ({
          ...acc,
          [id]: {
            ...state[id],
            selected: isUndefined(action.selections[id]) ? state[id].selected : action.selections[id],
          },
        }),
        {},
      );
    case ReducerTypes.SET_PREPARATION_ITEM_SOURCE:
      return {
        ...state,
        [action.productIdentifier]: {
          ...state[action.productIdentifier],
          sourceAccountIdentifier: action.source,
        },
      };
    case ReducerTypes.SET_PREPARATION_ITEM_DESTINATION:
      return {
        ...state,
        [action.productIdentifier]: {
          ...state[action.productIdentifier],
          destinationAccountIdentifier: action.destination,
        },
      };
    default:
      return state;
  }
};

const normalizePrepaymentProduct = (
  prepayment: ProductPrepaymentItem,
  sourceAccount,
  destinationAccount,
): PreparationItem => ({
  amount: new Decimal(prepayment.maxPrepayment || '0.00').toFixed(2),
  destinationAccountIdentifier: destinationAccount.identifier,
  productIdentifier: prepayment.identifier,
  selected: false,
  purpose: 'prepayment',
  sourceAccountIdentifier: sourceAccount?.identifier,
  liableCompanyIdentifier: prepayment.liableCompanyIdentifier,
});

const normalizeBalanceReturn = (br: BalanceReturn, sourceAccount, destinationAccount): PreparationItem => ({
  amount: new Decimal(br.amount || '0.00').toFixed(2),
  destinationAccountIdentifier: destinationAccount?.identifier,
  productIdentifier: br.identifier,
  selected: false,
  purpose: 'balance_return',
  sourceAccountIdentifier: sourceAccount?.identifier,
});

export const initialize = (
  prepaymentProducts: ProductPrepaymentItem[],
  balanceReturns: BalanceReturn[],
  config: CompanyFundingAccountConfiguration,
  currentState?: PreparationState,
): PreparationState => {
  if (!config) {
    return {};
  }
  const defaults = getDefaultAccounts(config);
  const newState = {
    ...keyBy(
      prepaymentProducts.map(p =>
        normalizePrepaymentProduct(p, defaults.prepayment.source, defaults.prepayment.destination),
      ),
      ({ productIdentifier }) => productIdentifier,
    ),
    ...keyBy(
      balanceReturns.map(br =>
        normalizeBalanceReturn(br, defaults.balanceReturn.source, defaults.balanceReturn.destination),
      ),
      ({ productIdentifier }) => productIdentifier,
    ),
  };
  if (!currentState) {
    return newState;
  }
  // If currentState is provided, we want to preserve the selected state of any items
  // that are in both currentState and newState.
  return Object.keys(newState).reduce(
    (memo: PreparationState, ident: string) => ({
      ...memo,
      [ident]: currentState[ident] || newState[ident],
    }),
    {},
  );
};

export const setState = state => ({
  type: ReducerTypes.SET_STATE,
  state,
});

export const setSource = (productIdentifier: string, source: string) => ({
  type: ReducerTypes.SET_PREPARATION_ITEM_SOURCE,
  productIdentifier,
  source,
});

export const setDestination = (productIdentifier: string, destination: string) => ({
  type: ReducerTypes.SET_PREPARATION_ITEM_DESTINATION,
  productIdentifier,
  destination,
});

/** selectItems(selectedIdentifiers, affectedIdentifiers)
 * This is a bit of a weird api, but it reflects the fact that we want to set selected/unselected
 * on a certain subset of preparation items and leave the rest as is:
 *
 * * item in affectedIdentifiers AND selectedIdentifiers will be selected
 * * item in affectedIdentifiers BUT NOT in selectedIdentifier will be deselected.
 * * item not in affectedIdentifiers will be unaffected.
 */

export const selectItems = (selectedIdentifiers: string[], affectedIdentifiers: string[]) => {
  const selections = affectedIdentifiers.reduce(
    (acc, identifier) => ({
      ...acc,
      [identifier]: selectedIdentifiers.includes(identifier),
    }),
    {},
  );
  return {
    type: ReducerTypes.SELECT_PREPARATION_ITEMS,
    selections,
  };
};
