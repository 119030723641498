import React from 'react';
import { Link } from 'react-router-dom';
import * as routes from 'src/routes';
import { CardHeader } from '@producepay/pp-ui';

const SendPaymentsNav = () => (
  <CardHeader
    className="bg-white space-x-8"
    titleClassName="font-extrabold text-2xl"
    title="Send Payments"
    headerComponents={
      <ul className="flex flex-row space-x-4">
        <li>
          <Link to={routes.sendPayments()}>Prepare</Link>
        </li>
        <li>
          <Link to={routes.sendPaymentsApproved('')}>Send</Link>
        </li>
      </ul>
    }
  />
);

export default SendPaymentsNav;
