import React, { createContext } from 'react';

const DescriptionListContext = createContext({ datumClass: '', titleClass: '' });

export const DescriptionList = ({
  className = '',
  titleClass = 'border-b-1 text-gray-500 py-2',
  datumClass = 'border-b-1 py-2',
  ...props
}) => (
  <DescriptionListContext.Provider value={{ titleClass, datumClass }}>
    <dl className={`flex flex-wrap text-xs border-t-1 ${className}`} {...props} />
  </DescriptionListContext.Provider>
);

export const Title = ({ className = '', ...props }) => (
  <DescriptionListContext.Consumer>
    {({ titleClass }) => (
      <dt className={`w-1/3 overflow-hidden whitespace-no-wrap ${titleClass} ${className}`} {...props} />
    )}
  </DescriptionListContext.Consumer>
);

export const Datum = ({ className = '', ...props }) => (
  <DescriptionListContext.Consumer>
    {({ datumClass }) => <dd className={`w-2/3 pl-4 ${className} ${datumClass}`} {...props} />}
  </DescriptionListContext.Consumer>
);

export default DescriptionList;
