import gql from 'graphql-tag';

const GET_EXCESS_PAYMENTS = gql`
  query CompanyExcessPayments($companyIdentifier: String!) {
    excessPayments(companyIdentifier: $companyIdentifier) {
      checkNumber
      senderName
      paymentDate
      excessAmount
    }
  }
`;

export default GET_EXCESS_PAYMENTS;
