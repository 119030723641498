import React from 'react';
import { Grid } from '@producepay/pp-ui';
import { formatCurrency } from 'src/helpers/currency';
import CircleChart from 'src/components/elements/CircleChart';

import { ExposureData, calculateExposure, getExposureLevel, getExposureColor } from 'src/helpers/exposure';

const ExposureEntity = ({
  exposure,
  selectedAmount,
  type,
}: {
  exposure: ExposureData;
  selectedAmount: string;
  type: string;
}) => {
  const { available, factor, used } = calculateExposure(exposure, { selectedAmount });
  const exposureLevel = getExposureLevel(factor);
  const headerClass = 'uppercase text-xs text-gray-600 font-bold mb-1';
  return (
    <div className="rounded-md border-gray-300 border-1 p-4 mt-2">
      <Grid container className="items-center">
        <Grid sm="1/3">
          <h4 className="text-sm font-semibold">{type}</h4>
        </Grid>
        <Grid sm="1/3" className="text-xs">
          <CircleChart
            title={`Exposure Chart for ${type}`}
            className="float-left mr-2"
            color={getExposureColor(exposureLevel)}
            factor={factor.toNumber()}
            strokeWidth={5}
            size={30}
            style={{ transform: `rotate(-${factor.times(360)}deg)` }}
          />
          <h6 className={headerClass}>Credit Exposure</h6>
          <span className="font-bold">{formatCurrency(used)}</span> / {formatCurrency(exposure.creditLimit)}
        </Grid>
        <Grid sm="1/6" className="text-xs text-right">
          <h6 className={headerClass}>Available</h6>
          <div className="font-bold">{formatCurrency(available)}</div>
        </Grid>
        <Grid sm="1/6" className="text-xs text-right">
          <h6 className={headerClass}>Exposure</h6>
          <div className={`text-${getExposureColor(exposureLevel, { okColor: 'default' })} font-bold`}>
            {factor.times(100).toFixed(2)}%
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ExposureEntity;
