import React from 'react';
import { Field, useFormikContext } from 'formik';
import { Decimal } from 'decimal.js';

import { Grid, TextField } from '@producepay/pp-ui';
import FormLabel from '../../elements/FormLabel';
import Card from '../../elements/Card';
import CompanyAutocomplete from '../CompanyAutocomplete/CompanyAutocomplete';

import { formatCurrency } from '../../../helpers/currency';

interface ExcessPaymentProps {
  companies: [];
}

function AllocatePaymentExcess(props: ExcessPaymentProps): JSX.Element {
  const { values, ...formik } = useFormikContext<ReceivePaymentFormValues>();

  const totalAllocated = new Decimal(values.excessPayment.amount || '0.00') || new Decimal('0.00');

  return (
    <div className="px-5 sm:px-8 sm:py-3" data-testid="allocate-payment-excess">
      <Card className="px-5 sm:py-8">
        <Grid container>
          <Grid className="md:border-b">
            <div className="float-left font-bold text-lg pb-4">Excess Payment</div>
            <div className="float-right text-sm">
              <span className="font-bold">{formatCurrency(totalAllocated)}</span>
              <span> categorized as Excess Payment</span>
            </div>
          </Grid>
          <Grid sm="1/6">
            <FormLabel label="To/Recipient's Ledger *" id="excess-payment-company" />
            <CompanyAutocomplete
              items={props.companies}
              inputSize="small"
              labelledBy="excess-payment-company"
              onItemSelected={item => formik.setFieldValue('excessPayment.recipientId', item.value)}
            />
          </Grid>
          <Grid sm="1/6">
            <FormLabel label="Amount *" />
            <Field name="excessPayment.amount">
              {({ field }) => (
                <>
                  <input
                    type="number"
                    step="0.01"
                    className="w-full textfield border outline-none focus:border-blue-400 textfield-sm text-sm"
                    data-testid="received-payment-excess-amount"
                    {...field}
                  />
                </>
              )}
            </Field>
          </Grid>
          <Grid sm="1/2">
            <FormLabel label="Note" for="excess-payment-note" />
            <Field id="excess-payment-note" name="excessPayment.note" size="small" as={TextField} />
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}

export default React.memo(AllocatePaymentExcess);
