import React from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { formatISO } from 'date-fns';
import { Formik, Form } from 'formik';
import { useQuery } from '@apollo/client';
import { Decimal } from 'decimal.js';
import { LoadingSpinner, Grid, useToast } from '@producepay/pp-ui';
import Breadcrumbs from '../../elements/Breadcrumbs';
import Card from '../../elements/Card';
import UnreconciledPaymentIndexTable from './table/UnreconciledPaymentIndexTable';
import { decimalSum, toDecimal } from '../../../helpers/currency';
import { snakeCaseKeys } from '../../../helpers/lodash';
import { UnreconciledPaymentContext } from '../../../contexts/UnreconciledPaymentContext';
import GET_UNRECONCILED_PAYMENTS from '../../../graphql/queries/getUnreconciledPayments';
import AllocateUnreconciledPaymentForm from '../AllocateUnreconciledPaymentForm/AllocateUnreconciledPaymentForm';
import { useAuth } from '../../../contexts/auth/AuthProvider';
import api from '../../../helpers/axios';

const REDIRECT_DELAY = 2000;

function UnreconciledPaymentDashboard() {
  const { loading, data } = useQuery(GET_UNRECONCILED_PAYMENTS, { fetchPolicy: 'no-cache' });
  const history = useHistory();
  const { getTokenSilently } = useAuth();
  const { addToastToQueue } = useToast();

  const initialValues: UnreconciledPaymentValues = {
    allocationDate: new Date(),
    unreconciledPayments: data && data.unreconciledPayments,
    sourcePayment: null,
    products: {
      inSeason: [],
      preSeason: [],
    },
    amountReleased: '',
  };

  const calculateBalanceAllocated = (values: UnreconciledPaymentValues) => {
    return decimalSum<Product>('amount', [...values.products.inSeason, ...values.products.preSeason]).toString();
  };

  const calculateBalanceAvailable = (values: UnreconciledPaymentValues) => {
    return values.sourcePayment
      ? new Decimal(values.sourcePayment.unreconciledAmount).minus(calculateBalanceAllocated(values)).toString()
      : '0.00';
  };

  const mapProducts = (products: Products) => {
    const mergedProducts = products.inSeason.concat(products.preSeason);
    return mergedProducts.map(
      product =>
        product.amount &&
        snakeCaseKeys({
          productIdentifier: product.identifier,
          type: product.category,
          amount: toDecimal(product.amount),
          held: product.held,
        }),
    );
  };

  async function onAllocateUnreconciledPayment(values, formik): Promise<void> {
    const products = mapProducts(values.products);
    const payload = snakeCaseKeys({
      command: 'AllocateUnreconciledPayment',
      sourceIdentifier: values.sourcePayment.identifier,
      allocations: _.compact(products),
      allocationDate: formatISO(values.allocationDate, { representation: 'date' }),
    });
    try {
      const token = await getTokenSilently();
      await api.post('/command', payload, { headers: { Authorization: `Bearer ${token}` } });
      await new Promise(resolve => setTimeout(resolve, REDIRECT_DELAY));
      history.push('/fund_activities');
    } catch (error) {
      const errorMessage = error.response ? error.response.data.error.message : error.message;
      addToastToQueue({
        actions: [{ label: 'Dismiss' }],
        body: errorMessage,
        header: 'Error Allocating Payment',
        type: 'error',
      });
      formik.setSubmitting(false);
    }
  }

  return loading ? (
    <div className="text-center py-32">
      <LoadingSpinner />
    </div>
  ) : (
    <Formik initialValues={initialValues} onSubmit={onAllocateUnreconciledPayment}>
      {({ values }) => (
        <UnreconciledPaymentContext.Provider
          value={{
            balanceAvailable: calculateBalanceAvailable(values),
            balanceAllocated: calculateBalanceAllocated(values),
          }}
        >
          <Form>
            {values.sourcePayment ? (
              <AllocateUnreconciledPaymentForm companyId={values.sourcePayment.companyId} />
            ) : (
              <>
                <Grid container className="px-8 py-6 items-center">
                  <Grid sm="1/3">
                    <Breadcrumbs items={[{ name: 'Unreconciled Payment' }]} />
                  </Grid>
                </Grid>
                <div>
                  <div className="px-5 sm:px-8 sm:pb-3">
                    <Card className="px-5 sm:py-8">
                      <Grid container>
                        <Grid className="md:border-b">
                          <div className="float-left font-bold text-lg pb-4">Unreconciled Payments</div>
                        </Grid>
                        <Grid>
                          {values.unreconciledPayments && values.unreconciledPayments.length ? (
                            <UnreconciledPaymentIndexTable />
                          ) : (
                            'No Unreconciled Payments Available'
                          )}
                        </Grid>
                      </Grid>
                    </Card>
                  </div>
                </div>
              </>
            )}
          </Form>
        </UnreconciledPaymentContext.Provider>
      )}
    </Formik>
  );
}

export default UnreconciledPaymentDashboard;
