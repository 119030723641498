import React, { useState } from 'react';
import { useCommand } from 'src/helpers/command';
import { Button, LoadingSpinner, useToast } from '@producepay/pp-ui';

import Trash from 'src/components/icons/Trash';
import { RevertReceivedPayment, RevertTransferAllocated } from 'src/helpers/command/types';

export const REVERTABLE_FUND_ACTIVITY_TYPES: Array<string> = ['Transfer', 'Received Payment'];

const RevertButton = (props: { entryType: string; identifier: string; onSuccess: Function; refetchDelay?: number }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { send } = useCommand();
  const { addToastToQueue } = useToast();

  // We don't currently have a mechanism to know when the projectors have processed
  // the revert, so this will have to do for now.
  const refetchDelay = props.refetchDelay || 2000;

  const sendRevert = async () => {
    switch (props.entryType) {
      case 'Received Payment':
        return send<RevertReceivedPayment>({
          command: 'RevertReceivedPayment',
          eventIdentifier: props.identifier,
        });
      case 'Transfer':
        return send<RevertTransferAllocated>({
          command: 'RevertTransferAllocated',
          eventIdentifier: props.identifier,
        });
      default:
        throw new Error(`We do not support reverting ${props.entryType}s at this time.`);
    }
  };

  const onRevertClicked = async () => {
    setIsOpen(false);
    setIsLoading(true);
    try {
      await sendRevert();
      window.setTimeout(() => {
        addToastToQueue({
          body: `The ${props.entryType} was reverted successfully`,
          header: 'Revert Success',
          type: 'success',
        });
        props.onSuccess && props.onSuccess();
        setIsLoading(false);
      }, refetchDelay);
    } catch (e) {
      addToastToQueue({
        actions: [{ label: 'Dismiss' }],
        body: e.message,
        header: 'Revert Error',
        type: 'error',
      });
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!isOpen) {
    return (
      <button
        className="border-gray-400 border bg-white rounded-sm hover:shadow-inner focus:outline-none p-2 float-right"
        color="white"
        type="button"
        onClick={evt => {
          evt.stopPropagation();
          setIsOpen(true);
        }}
      >
        <Trash size={14} />
      </button>
    );
  }
  return (
    <div className="float-right p-2">
      <p className="mb-2">Are you sure you want to revert this {props.entryType}?</p>
      <div className="float-right flex-row w-full items-stretch">
        <Button
          className="w-1/2"
          color="primary"
          onClick={evt => {
            evt.stopPropagation();
            onRevertClicked();
          }}
        >
          Revert
        </Button>
        <Button
          className="w-1/2"
          variant="outlined"
          onClick={evt => {
            evt.stopPropagation();
            setIsOpen(false);
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default RevertButton;
