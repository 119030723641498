import gql from 'graphql-tag';

const GET_UNRECONCILED_PAYMENTS = gql`
  query ListUnreconciledPayments {
    unreconciledPayments {
      identifier
      companyId
      checkNumber
      referenceNumber
      senderName
      paymentDate
      paymentAmount
      unreconciledAmount
      unreconciledNotes
    }
  }
`;

export default GET_UNRECONCILED_PAYMENTS;
