import React, { useEffect, useState } from 'react';
import { groupBy, some } from 'lodash';
import { Grid, TextField } from '@producepay/pp-ui';
import { useHistory, useParams } from 'react-router-dom';
import * as routes from 'src/routes';
import { decimalSum, formatCurrency } from 'src/helpers/currency';
import { ChevronRight, MessageSquare } from 'react-feather';
import DataTable, { DataTableStyleWide } from 'src/components/molecules/DataTable';
import CommandButton from 'src/components/molecules/CommandButton';
import { ReconcileOutboundPayment } from 'src/helpers/command/types';
import ApprovedPaymentDetail from './ApprovedPaymentDetail';
import AmountAlert from '../AmountAlert';

interface ColumnProps {
  getIsSelected: (item: OutboundPaymentItem) => boolean;
  wireFees: { [identifier: string]: string };
  setWireFees: (value: { [identifier: string]: string }) => void;
  notes: string;
  onSuccess: () => void;
}

// approvedItems should be filtered according to the current filter.
// allApprovedItems should be all of the approvedItems.
interface ApprovedListProps {
  allApprovedItems: OutboundPaymentItem[];
  fundingAccounts: FundingAccount[];
  visibleApprovedItems: OutboundPaymentItem[];
  refetch: () => void;
}

const getAnyPrepayments = (batch: OutboundPaymentItemBatch) =>
  some(batch.items, ({ purpose }) => purpose === 'prepayment');

const getWireFeesByFundingAccount = (opis: OutboundPaymentItem[], fundingAccounts: FundingAccount[]) => {
  return opis.reduce((acc, current) => {
    const { wireFee } = fundingAccounts.find(account => account.identifier === current.destinationAccountIdentifier);
    return {
      ...acc,
      [current.identifier]: wireFee || '10.00',
    };
  }, {});
};

const columns = ({ getIsSelected, wireFees, setWireFees, notes, onSuccess }: ColumnProps) => [
  { displayName: 'Destination', name: 'destinationAccountName' },
  {
    displayName: 'Amount',
    name: 'totalAmount',
    formatter: (totalAmount, { productType, purpose, totalSuggestedAmount }) => (
      <AmountAlert
        amount={totalAmount}
        context="approved"
        paymentPurpose={purpose}
        productType={productType}
        suggestedAmount={totalSuggestedAmount}
      />
    ),
  },
  {
    displayName: 'Wire Fee',
    headerClass: 'text-right w-1/10',
    itemClass: 'text-right',
    name: 'identifier',
    formatter: (identifier, batch) => (
      <div className="flex flex-row items-center text-xs">
        <span className="font-bold pr-1">$</span>
        <TextField
          className="p-2"
          disabled={!getAnyPrepayments(batch)}
          value={getAnyPrepayments(batch) ? wireFees[identifier] : 0}
          onChange={e => setWireFees({ ...wireFees, [identifier]: e.target.value })}
          onClick={e => e.stopPropagation()} // don't necessarily want to selct the row when we click this
          size={null}
          type="number"
        />
      </div>
    ),
  },
  {
    displayName: '',
    headerClass: 'w-1/6',
    name: 'sourceAccountIdentifier',
    formatter: (_, batch) =>
      !getIsSelected(batch) && (
        <CommandButton<ReconcileOutboundPayment>
          className="w-full"
          command={{
            command: 'ReconcileOutboundPayment',
            payload: {
              outboundPaymentItemIdentifiers: batch.items.map(({ identifier }) => identifier),
              sourceAccountIdentifier: batch.sourceAccountIdentifier,
              destinationAccountIdentifier: batch.destinationAccountIdentifier,
              wireFee: wireFees[batch.identifier],
              notes,
            },
          }}
          variant="outlined"
          successTemplate={() => ({
            body: `This payment to ${batch.recipientCompanyName} has been approved.`,
            header: 'Payment Approved',
          })}
          onSuccess={onSuccess}
        >
          Send
        </CommandButton>
      ),
  },
  {
    displayName: '',
    name: 'notes',
    formatter: (_, item) => item.notes && item.notes.length && <MessageSquare className="transform scale-75" />,
  },
  {
    displayName: '',
    name: 'arrow',
    formatter: (user, item) => getIsSelected(item) && <ChevronRight className="transform scale-75" />,
  },
];

const getDetailRoute = (item: OutboundPaymentItem) =>
  routes.sendPaymentsApprovedDetail(item.sourceAccountIdentifier, item.destinationAccountIdentifier);
// since we don't have real accounts right now, we gotta do this
// when we have real accounts we can check against the identifiers
// for real
const getIsSelected = params => (item: OutboundPaymentItem) =>
  params.destinationAccountIdentifier === item.destinationAccountIdentifier &&
  params.sourceAccountIdentifier === item.sourceAccountIdentifier;

/**
 * ApprovedList
 * ============
 * Have to provide both visible items (currently visible based on criteria)
 * from FilterBar and all items. This is so we can display _all_ items in a given
 * payment batch, even when some of them would otherwise be hidden by the current filter.
 */
const ApprovedList = ({ allApprovedItems, fundingAccounts, visibleApprovedItems, refetch }: ApprovedListProps) => {
  const params = useParams();
  const history = useHistory();
  const [currentNote, setCurrentNote] = useState('');
  const { destinationAccountIdentifier, sourceAccountIdentifier } = params;
  const items = visibleApprovedItems.filter(item => item.sourceAccountIdentifier === sourceAccountIdentifier);
  const batchedItems = groupBy(items, item => item.destinationAccountIdentifier);
  const rows: OutboundPaymentItemBatch[] = Object.values(batchedItems).map(batch => {
    const selectedApprovedItems = allApprovedItems.filter(getIsSelected(batch[0]));
    return {
      ...batch[0],
      totalAmount: decimalSum('amount', selectedApprovedItems).toFixed(2),
      totalSuggestedAmount: decimalSum('suggestedAmount', selectedApprovedItems).toFixed(2),
      items: allApprovedItems.filter(getIsSelected(batch[0])),
    };
  });

  const [wireFees, setWireFees] = useState(getWireFeesByFundingAccount(visibleApprovedItems, fundingAccounts));

  const selectedBatch = rows.find(getIsSelected(params));
  useEffect(() => {
    setCurrentNote(selectedBatch?.notes || '');
  }, [destinationAccountIdentifier]);

  if (!items.length) {
    return <p className="p-4">No payments are currently prepared.</p>;
  }

  return (
    <Grid container>
      <Grid md={destinationAccountIdentifier ? '2/3' : '1/1'}>
        <div className="px-3">
          <h2 className="text-xl font-black my-6 flex flex-row justify-between items-baseline">
            {items[0].sourceAccountName}
            <span className="text-sm font-medium align-baseline">
              {formatCurrency(decimalSum('amount', items))} approved
            </span>
          </h2>
          <DataTable<OutboundPaymentItemBatch>
            tbodyClass="bg-white"
            trClass={item => `cursor-pointer ${getIsSelected(params)(item) ? 'bg-primary-lighter bg-opacity-25' : ''}`}
            onClick={item => history.push(getDetailRoute(item))}
            columns={columns({
              getIsSelected: getIsSelected(params),
              wireFees,
              setWireFees,
              notes: currentNote,
              onSuccess: () => {
                refetch();
                history.push(routes.sendPaymentsApproved(sourceAccountIdentifier));
              },
            })}
            keyName="identifier"
            rows={rows}
            {...DataTableStyleWide}
          />
        </div>
      </Grid>
      {destinationAccountIdentifier && selectedBatch && (
        <>
          <Grid className="min-h-screen" md="1/3">
            <ApprovedPaymentDetail
              batch={selectedBatch}
              refetch={refetch}
              onNotesChanged={setCurrentNote}
              wireFee={wireFees[selectedBatch.identifier]}
              notes={currentNote}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ApprovedList;
