import React from 'react';
import Decimal from 'decimal.js';
import { startCase } from 'lodash';
import { LoadingSpinner } from '@producepay/pp-ui';
import { AlertTriangle, Triangle } from 'react-feather';
import { DescriptionList, Title, Datum } from 'src/components/elements/DescriptionList';
import { formatCurrency } from 'src/helpers/currency';
import { useQuery } from '@apollo/client';
import Alert from 'src/components/elements/Alert';
import GET_COMPANY_EXPOSURES from 'src/graphql/queries/getCompanyExposures';

import { calculateExposure, getExposureLevel, getMostExposed, ExposureLevel } from 'src/helpers/exposure';

const ALERT_ICON_SIZE = 18;

export default ({ companyIdentifier, companyName }: { companyIdentifier: string; companyName: string }) => {
  const { data, loading, error } = useQuery(GET_COMPANY_EXPOSURES, {
    variables: {
      companyIdentifier,
    },
    fetchPolicy: 'no-cache',
  });
  if (loading) {
    return <LoadingSpinner centered={true} className="m-4" size={14} />;
  }
  if (error) {
    return <Alert title="Exposure Data could not be found at this time." />;
  }
  if (!data) {
    return null;
  }
  const mostExposedKey = getMostExposed(data.companyExposures);
  const maxExposure = data.companyExposures[mostExposedKey];
  const currentExposure = calculateExposure(maxExposure, { includePending: false });
  const newExposure = calculateExposure(maxExposure);

  if (getExposureLevel(newExposure.factor) === ExposureLevel.OVER) {
    return (
      <Alert
        className="m-4"
        title={
          <span>
            <div
              className="relative inline-block mr-1 align-text-top"
              style={{ height: ALERT_ICON_SIZE, width: ALERT_ICON_SIZE }}
            >
              <AlertTriangle
                className="text-secondary fill-current absolute left-0 top-0"
                stroke="white"
                size={ALERT_ICON_SIZE}
              />
              {/* Triangle is used to occlude white outer stroke on AlertTriangle */}
              <Triangle
                className="text-secondary stroke-current absolute left-0 top-0"
                fill="transparent"
                strokeWidth={3}
                size={ALERT_ICON_SIZE}
              />
            </div>
            {startCase(mostExposedKey)} exposure exceeds 100%
          </span>
        }
      >
        <h5 className="font-bold my-2">{companyName}</h5>
        <DescriptionList
          className="border-transparent"
          datumClass="border-transparent py-1"
          titleClass="border-transparent text-default py-1"
        >
          <Title>Current Exposure:</Title>
          <Datum>
            {new Decimal(currentExposure.factor).times(100).toFixed(2)}% ({formatCurrency(currentExposure.used)} /{' '}
            {formatCurrency(maxExposure.creditLimit)})
          </Datum>
          <Title>New Exposure</Title>
          <Datum>
            {new Decimal(newExposure.factor).times(100).toFixed(2)}% ({formatCurrency(newExposure.used)} /{' '}
            {formatCurrency(maxExposure.creditLimit)})
          </Datum>
        </DescriptionList>
      </Alert>
    );
  }
  return null;
};
