import React from 'react';
import { Grid } from '@producepay/pp-ui';
import ExternalDetailRow from './ExternalDetailRow';

interface ExternalDetailProps {
  allocations: FundActivityAllocation[];
}

function ExternalDetail(props: ExternalDetailProps) {
  const { allocations } = props;

  return (
    <>
      <div className="font-bold">Non-PP Product</div>
      <Grid container className="font-light uppercase bg-white" spacing={0}>
        <Grid sm="1/3">Company</Grid>
        <Grid sm="1/6">Inv/Ref#</Grid>
        <Grid sm="1/3">Note</Grid>
        <Grid sm="1/6">Allocated</Grid>
      </Grid>
      {allocations.map(allocation => (
        <ExternalDetailRow
          companyName={allocation.destinationCompanyName}
          note={allocation.destinationNotes}
          amount={allocation.destinationAmount}
          referenceNumber={allocation.destinationReferenceNumber}
        />
      ))}
    </>
  );
}

export default ExternalDetail;
