import gql from 'graphql-tag';
import COMPANY from '../fragments/company';

const GET_COMPANIES = gql`
  ${COMPANY}
  query Companies {
    companies {
      ...companyFields
    }
  }
`;

export default GET_COMPANIES;
