import React from 'react';
import { uniq, filter, map, orderBy } from 'lodash';

// Received Payment detail sub-components
import InSeasonDetail from './InSeasonDetail';
import PreSeasonDetail from './PreSeasonDetail';
import ExternalDetail from './ExternalDetail';
import ExcessDetail from './ExcessDetail';
import UnreconciledDetail from './UnreconciledDetail';
import ReallocationDetail from './ReallocationDetail';

interface FundActivityDetailsProps {
  entryType: string;
  identifier: string;
  details: {
    allocations: FundActivityAllocation[];
    checkReleaseDate?: string;
    paymentMethod: string;
  };
}

function FundActivityDetails(props: FundActivityDetailsProps) {
  const COMPONENT_MAP = {
    'in-season': InSeasonDetail,
    'pre-season': PreSeasonDetail,
    external: ExternalDetail,
    excess: ExcessDetail,
    unreconciled: UnreconciledDetail,
  };
  const { details } = props;
  const allocationTypes = uniq(map(orderBy(details.allocations, ['destinationType'], ['asc']), 'destinationType'));

  return (
    <>
      {props.entryType !== 'Transfer' ? (
        allocationTypes.map(type => {
          const DetailComponent = COMPONENT_MAP[type];
          const componentAllocations = orderBy(
            filter(details.allocations, { destinationType: type }),
            'destinationSlug',
            'desc',
          );
          if (!DetailComponent) {
            return <p>Cannot display details for this transaction.</p>;
          }
          return (
            <div
              key={`${props.identifier}-${type}`}
              className="bg-white py-2 border-b-4 border-gray-200 last:border-white"
            >
              <DetailComponent allocations={componentAllocations} checkReleaseDate={details.checkReleaseDate} />
            </div>
          );
        })
      ) : (
        <div key={props.identifier} className="bg-white py-2 border-b-4 border-gray-200 last:border-white">
          <ReallocationDetail allocations={details.allocations} />
        </div>
      )}
    </>
  );
}

export default FundActivityDetails;
