import gql from 'graphql-tag';

const GET_COMPANY_EXPOSURES = gql`
  query CompanyExposures($companyIdentifier: String!) {
    companyExposures(companyIdentifier: $companyIdentifier) {
      company {
        outstandingAmount
        pendingAmount
        creditLimit
      }
      companyGroup {
        outstandingAmount
        pendingAmount
        creditLimit
      }
    }
  }
`;

export default GET_COMPANY_EXPOSURES;
