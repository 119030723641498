import React from 'react';
import { Decimal } from 'decimal.js';
import { ColumnType, LinkFormatter } from 'src/components/molecules/DataTable';
import { formatCurrency } from 'src/helpers/currency';
import ProductAllocationForm, {
  AllocationAmountField,
  AllocationDestination,
} from 'src/components/molecules/ProductAllocationForm';

interface AllocateTransferInSeasonProps {
  balanceAvailable: string;
  onAllocationChanged: (allocations: AllocationDestination[]) => void;
  products: Product[];
}

const columns = [
  {
    displayName: 'Slug',
    name: 'slug',
    formatter: (slug, { detailsUrl }) => <LinkFormatter url={detailsUrl}>{slug}</LinkFormatter>,
    sortable: true,
  },
  { displayName: 'Invoice / Reference #', name: 'referenceNumber' },
  { displayName: 'State', name: 'state' },
  { displayName: 'DPD', name: 'daysPastDue', type: ColumnType.Number },
  { displayName: 'Late Fees', name: 'lateFees', formatter: v => formatCurrency(v), type: ColumnType.Number },
  {
    displayName: 'Total Still Due',
    name: 'totalDue',
    formatter: v => formatCurrency(v),
    type: ColumnType.Number,
  },
  {
    displayName: 'Minimum Still Due',
    headerClass: 'text-right',
    itemClass: 'text-right',
    name: 'minimumDue',
    formatter: v => formatCurrency(v),
    type: ColumnType.Number,
  },
  {
    displayName: 'Amount',
    name: 'amount',
    type: ColumnType.Number,
    formatter: (_, product) => <AllocationAmountField category="in-season" product={product} />,
  },
];

function AllocateTransferInSeason({
  balanceAvailable,
  onAllocationChanged,
  products,
}: AllocateTransferInSeasonProps): JSX.Element {
  const autofillRow = (product, remaining) => {
    if (remaining.greaterThan(product.minimumDue)) {
      return new Decimal(product.minimumDue);
    }
    return remaining;
  };

  return (
    <ProductAllocationForm
      balanceAvailable={balanceAvailable}
      category="in-season"
      columns={columns}
      defaultStateFilter={['advanced', 'open', 'payment_promised', 'settled', 'sold']}
      description="In-Season Past Dues"
      filterByState
      handleAutofillRow={autofillRow}
      searchableFields={['slug', 'referenceNumber']}
      onAllocationChanged={onAllocationChanged}
      products={products}
    />
  );
}

export default AllocateTransferInSeason;
