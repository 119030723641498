import React from 'react';
import { Field, useFormikContext } from 'formik';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { formatISO } from 'date-fns';

import { Grid, LoadingSpinner } from '@producepay/pp-ui';
import FormikDatePicker from 'src/components/formik/FormikDatePicker/FormikDatePicker';
import InSeason from 'src/components/molecules/AllocatePaymentInSeason';
import PreSeason from 'src/components/molecules/AllocatePaymentPreSeason';

import GET_PRODUCTS from 'src/graphql/queries/getProducts';
import Breadcrumbs from 'src/components/elements/Breadcrumbs';
import FormLabel from 'src/components/elements/FormLabel';

import AllocateExcessPaymentFooter from './AllocateExcessPaymentFooter';

interface AllocateExcessPaymentProps {
  companyId: number;
}

function AllocateExcessPaymentForm(props: AllocateExcessPaymentProps): JSX.Element {
  const { values, ...formik } = useFormikContext<UnreconciledPaymentValues>();
  const updateValues = (data): void => {
    const dataWithAmounts = _.mapValues(data, val => val.map(product => ({ amount: '', held: false, ...product })));
    formik.setFieldValue('products', dataWithAmounts);
  };
  const { loading } = useQuery(GET_PRODUCTS, {
    variables: {
      companyId: props.companyId,
      asOfDate: formatISO(values.allocationDate, { representation: 'date' }),
    },
    fetchPolicy: 'no-cache',
    onCompleted: updateValues,
  });

  return loading ? (
    <div className="text-center py-32">
      <LoadingSpinner />
    </div>
  ) : (
    <>
      <Grid container className="px-8 py-6 items-center">
        <Grid sm="1/3">
          <Breadcrumbs items={[{ name: 'Excess Payment', url: '../' }, { name: 'Allocate Excess Payment' }]} />
        </Grid>
        <Grid sm="1/3" />
        <Grid sm="1/6" />
        <Grid sm="1/6">
          <div className="float-right w-50">
            <FormLabel label="Allocated On *" />
            <Field name="allocationDate" size="sm" alignLeft component={FormikDatePicker} />
          </div>
        </Grid>
      </Grid>
      <InSeason showHeld={false} />
      <PreSeason />
      <AllocateExcessPaymentFooter />
    </>
  );
}

export default React.memo(AllocateExcessPaymentForm);
