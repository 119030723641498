import React, { useEffect, useRef, useState } from 'react';
import { Field, FormikHelpers, useFormikContext, withFormik } from 'formik';
import { Decimal } from 'decimal.js';
import { Button, Card, Grid, TextField, Select } from '@producepay/pp-ui';
import DataTable, { DataTableColumn } from 'src/components/molecules/DataTable';
import { formatCurrency } from 'src/helpers/currency';
import { capitalizeEachWord } from 'src/helpers/format';

export interface AllocationDestination {
  identifier?: string;
  amount: string;
  category: string;
}

const PRODUCT_STATES = ['advanced', 'open', 'closed', 'payment_promised', 'late', 'paid', 'settled', 'sold'].map(
  value => ({
    label: capitalizeEachWord(value),
    value,
  }),
);

interface ProductAllocationFormProps {
  balanceAvailable: string;
  category: string;
  description: string;
  columns: DataTableColumn<Product>[];
  defaultStateFilter?: string[];
  filterByState?: boolean;
  handleAutofillRow: (product: Product, balanceAvailable) => Decimal;
  onAllocationChanged: (allocations: AllocationDestination[]) => void;
  products: Product[];
  searchableFields?: string[];
}

interface ProductAllocationFormValues {
  products: {
    [category: string]: {
      [identifier: string]: {
        amount: string;
      };
    };
  };
}

export const AllocationAmountField = (props: { category: string; product: Product }): JSX.Element => (
  <Field name={`products[${props.category}][${props.product.identifier}].amount`}>
    {({ field }) => (
      <input
        type="number"
        onWheel={e => (e.target as HTMLElement).blur()}
        className="textfield border outline-none focus:border-blue-400 textfield-sm text-sm"
        {...field}
      />
    )}
  </Field>
);

const ProductAllocationForm = ({
  balanceAvailable,
  category,
  columns,
  defaultStateFilter = null,
  description,
  filterByState = false,
  handleAutofillRow,
  onAllocationChanged,
  products,
  searchableFields,
  setFieldValue,
}: ProductAllocationFormProps & FormikHelpers<ProductAllocationFormValues>) => {
  const { values } = useFormikContext<ProductAllocationFormValues>();
  const [search, setSearch] = useState('');
  const [stateFilter, setStateFilter] = useState(defaultStateFilter);
  const comparator = useRef(null);
  const totalAllocated = Object.keys(values.products[category])
    .map(identifier => values.products[category][identifier])
    .reduce((acc, row) => new Decimal(row.amount || '0.00').plus(acc), new Decimal('0.00'))
    .toString();

  const filterRow = (row: Product): boolean => {
    const stateOk = !stateFilter || stateFilter.includes(row.state);
    let searchOk = true;
    if (stateOk && searchableFields) {
      searchOk = searchableFields.reduce((acc, field) => acc || row[field].toLowerCase().includes(search), false);
    }
    return stateOk && searchOk;
  };

  const clearAmounts = () => {
    products.forEach(({ identifier }) => setFieldValue(`products[${category}][${identifier}].amount`, ''));
  };

  const onAutofill = () => {
    clearAmounts();
    let totalAvailable = new Decimal(balanceAvailable);
    const sortedProducts = products.filter(filterRow);
    if (comparator.current) {
      sortedProducts.sort(comparator.current);
    }

    sortedProducts.forEach(product => {
      const amount = handleAutofillRow(product, totalAvailable);
      if (amount.greaterThan(0)) {
        setFieldValue(`products[${category}][${product.identifier}].amount`, new Decimal(amount).toFixed(2));
        totalAvailable = totalAvailable.minus(amount);
      }
    });
  };

  useEffect(() => {
    const allocations = Object.keys(values.products[category])
      .map(identifier => ({
        identifier,
        amount: parseFloat(values.products[category][identifier]?.amount || '0.00').toFixed(2),
        category,
      }))
      .filter(({ amount }) => new Decimal(amount || '0.00').greaterThan(0));
    onAllocationChanged(allocations);
  }, [values.products]);

  return (
    <div>
      <div className="px-5 sm:px-8 sm:py-3">
        <Card className="px-5 sm:py-8">
          <Grid container>
            <Grid className="md:border-b">
              <div className="float-left font-bold text-lg pb-4">{description}</div>
              <div className="float-right text-sm">
                <span className="font-bold">{formatCurrency(totalAllocated)}</span>
                <span> allocated to {description}</span>
              </div>
            </Grid>
            <Grid>
              <>
                <Grid className="py-5 flex justify-end">
                  {filterByState && (
                    <div className="pr-3 flex-shrink-0" style={{ minWidth: '12em' }}>
                      <Select
                        className="border"
                        items={PRODUCT_STATES}
                        isMultiple
                        multipleItemLabel="States"
                        size="small"
                        placeholder="State"
                        onChange={states => setStateFilter(states.map(s => s.value))}
                        selectedItems={PRODUCT_STATES.filter(({ value }) =>
                          stateFilter ? stateFilter.includes(value) : true,
                        )}
                      />
                    </div>
                  )}
                  {searchableFields && searchableFields.length && (
                    <TextField
                      className="border"
                      size="small"
                      placeholder="Search by slug or invoice/reference #"
                      onChange={e => setSearch(e.target.value.toLowerCase())}
                    />
                  )}
                  <div className="pl-3 flex-shrink-0 justify-self-end">
                    <Button label="Autofill Balance Available" type="button" onClick={onAutofill} />
                  </div>
                  <div className="pl-3 pr-2 flex-shrink-0 justify-self-end">
                    <Button label="Clear" type="button" variant="outlined" onClick={clearAmounts} />
                  </div>
                </Grid>
                <div className="pt-3 table-secondary table-p-sm table-alternating-rows">
                  {products.length ? (
                    <DataTable
                      columns={columns}
                      keyName="identifier"
                      onSortChanged={func => {
                        comparator.current = func;
                      }}
                      rows={searchableFields ? products.filter(filterRow) : products}
                      sortable
                    />
                  ) : (
                    `No ${description} Products Available`
                  )}
                </div>
              </>
            </Grid>
          </Grid>
        </Card>
      </div>
    </div>
  );
};

export default withFormik<ProductAllocationFormProps, ProductAllocationFormValues>({
  mapPropsToValues: ({ category, products }) => ({
    products: {
      [category]: products.reduce(
        (acc, p) => ({
          ...acc,
          [p.identifier]: { amount: '' },
        }),
        {},
      ),
    },
  }),
  handleSubmit: () => null,
})(ProductAllocationForm);
