import React from 'react';
import DataTable from 'src/components/molecules/DataTable';
import { formatCurrency } from 'src/helpers/currency';

interface InSeasonReleaseHeldProps {
  details: FundActivityAllocation;
}

const columns = [
  { name: null, displayName: null },
  {
    name: 'destinationAmount',
    displayName: 'Released',
    headerClass: 'text-right',
    itemClass: 'text-right',
    formatter: amount => formatCurrency(amount),
  },
];

function InSeasonReleaseHeld(props: InSeasonReleaseHeldProps) {
  const { details } = props;

  return (
    <>
      <h3 className="font-bold p-2">Held Funds Released</h3>
      <DataTable<FundActivityAllocation> columns={columns} keyName="destinationSlug" rows={[details]} />
    </>
  );
}

export default InSeasonReleaseHeld;
