import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { formatISO } from 'date-fns';
import * as routes from 'src/routes';
import { Grid, LoadingSpinner } from '@producepay/pp-ui';
import GET_PRODUCTS from 'src/graphql/queries/getProducts';
import FormLabel from 'src/components/elements/FormLabel';
import { AllocationDestination } from 'src/components/molecules/ProductAllocationForm';
import DatePicker from 'src/components/elements/DatePicker';
import { getCompanyId } from 'src/helpers/products';
import AllocateTransferPreSeason from './AllocateTransferPreSeason';
import AllocateTransferInSeason from './AllocateTransferInSeason';
import AllocateTransferFooter from './AllocateTransferFooter';

interface AllocateTransferFormProps {
  actualValueSelected: string;
  balanceAvailable: string;
  balanceAllocated: string;
  companyIdentifier: string;
  updateAllocations: (action: { category: string; allocations: AllocationDestination[] }) => void;
  onSubmit: (allocationDate: Date) => void;
}

const AllocateTransferForm = (props: AllocateTransferFormProps) => {
  const { actualValueSelected, balanceAllocated, balanceAvailable, companyIdentifier, updateAllocations } = props;
  const [allocationDate, setAllocationDate] = useState(new Date());
  const companyId = getCompanyId(companyIdentifier);
  const { data, loading } = useQuery(GET_PRODUCTS, {
    variables: { companyId, asOfDate: formatISO(allocationDate, { representation: 'date' }) },
    fetchPolicy: 'no-cache',
  });

  return loading ? (
    <div className="text-center py-32">
      <LoadingSpinner />
    </div>
  ) : (
    <>
      <Grid container className="px-8 py-6 items-center">
        <Grid sm="1/3">
          <div className="font-extrabold text-xl">
            <>
              <a href={routes.companyTransfer(companyIdentifier)}>Transfer Funds</a>
              {' > Allocate'}
            </>
          </div>
        </Grid>
        <Grid sm="1/3" />
        <Grid sm="1/6" />
        <Grid sm="1/6">
          <FormLabel label="Allocated On *" className="float-right w-50">
            <DatePicker onChange={date => setAllocationDate(date)} value={allocationDate} />
          </FormLabel>
        </Grid>
      </Grid>
      <AllocateTransferInSeason
        balanceAvailable={balanceAvailable}
        onAllocationChanged={allocations => updateAllocations({ allocations, category: 'in-season' })}
        products={data.inSeason}
      />
      <AllocateTransferPreSeason
        actualValueSelected={actualValueSelected}
        balanceAvailable={balanceAvailable}
        products={data.preSeason}
        onAllocationChanged={allocations => updateAllocations({ allocations, category: 'pre-season' })}
      />
      <AllocateTransferFooter
        balanceAllocated={balanceAllocated}
        balanceAvailable={balanceAvailable}
        handleAllocation={() => props.onSubmit(allocationDate)}
      />
    </>
  );
};

export default AllocateTransferForm;
