import React from 'react';
import { formatCurrency } from 'src/helpers/currency';

interface UnreconciledDetailRowProps {
  referenceNumber: string;
  note: string;
  amount: string;
}

function UnreconciledDetailRow(props: UnreconciledDetailRowProps) {
  return (
    <>
      <tr className="text-sm even:bg-table-highlight">
        <td>{props.referenceNumber}</td>
        <td>{props.note}</td>
        <td className="text-right">{formatCurrency(props.amount)}</td>
        <td />
      </tr>
    </>
  );
}

export default UnreconciledDetailRow;
