import React, { useState } from 'react';
import { useCommand } from 'src/helpers/command';
import { Command } from 'src/helpers/command/types';
import { LoadingSpinner, Toggle, useToast } from '@producepay/pp-ui';

interface CommandToggleProps<T> {
  commandTemplate: (checked) => T;
  errorTemplate: (err: Error, checked) => { body: string; header: string };
  id: string;
  initialState: boolean;
  onSuccess?: (checked: boolean) => void;
  successTemplate: (checked) => { body: string; header: string };
}

const CommandToggle = <T extends Command>({
  commandTemplate,
  errorTemplate = err => ({
    body: `An error occurred while processing this request: ${err.message}.`,
    header: 'Error',
  }),
  id,
  initialState = false,
  onSuccess,
  successTemplate = () => ({ body: 'The request completed successfully.', header: 'Success' }),
  ...props
}: CommandToggleProps<T>) => {
  const { send } = useCommand();
  const { addToastToQueue } = useToast();
  const [checked, setChecked] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const handleToggle = async event => {
    const state = event.target.checked;
    try {
      setIsLoading(true);
      await send<T>(commandTemplate(state));
      setIsLoading(false);
      const { body, header } = successTemplate(state);
      setChecked(state);
      onSuccess && onSuccess(state);
      addToastToQueue({
        body,
        header,
        type: 'success',
      });
    } catch (e) {
      setIsLoading(false);
      const { body, header } = errorTemplate(e, state);
      addToastToQueue({
        actions: [{ label: 'Dismiss' }],
        body,
        header,
        type: 'error',
      });
    }
  };

  return isLoading ? (
    <div className="overflow-hidden" style={{ height: 16 }}>
      <LoadingSpinner size={14} />
    </div>
  ) : (
    <Toggle id={id} isOn={checked} handleToggle={handleToggle} {...props} />
  );
};

export default CommandToggle;
