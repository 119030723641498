import React from 'react';
import { useHistory } from 'react-router-dom';
import { useExcessPaymentContext } from 'src/contexts/ExcessPaymentContext';
import * as routes from 'src/routes';
import { Button, Grid } from '@producepay/pp-ui';
import Footer from 'src/components/elements/Footer';
import { formatCurrency } from 'src/helpers/currency';

const ExcessPaymentFooter = (props: { companyIdentifier: string }): JSX.Element => {
  const { balanceAvailable } = useExcessPaymentContext();
  const history = useHistory();
  return (
    <Footer>
      <div className="w-full">
        <Grid container>
          <Grid sm="1/3">
            <div className="text-xxs-xs uppercase text-white pb-2">Excess Payment Available</div>
            <div className="text-lg text-white">{formatCurrency(balanceAvailable)}</div>
          </Grid>
          <Grid sm="1/3" />
          <Grid sm="1/3">
            <div className="float-right">
              <Button
                className="px-12 py-3"
                onClick={() => history.push(routes.companyExcessPayment(props.companyIdentifier, 'allocate'))}
                label="Allocate Excess Payment"
                type="button"
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </Footer>
  );
};

export default ExcessPaymentFooter;
