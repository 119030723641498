import React, { ReactNode } from 'react';

import { ElasticTableContext } from './ElasticTable';

interface AlignedTableProps {
  children: ReactNode;
  columns: { align?: string; alignTo: string[]; displayName: string }[];
}

const getTotalWidth = (names, elasticTable) => {
  return names.reduce((m, name) => m + (elasticTable.columns.find(c => name === c.name)?.bbox?.width || 0), 0);
};

export default ({ children, columns }) => (
  <table className="FinanceDataTable table-fixed bg-white w-full">
    <thead>
      <tr className="text-left uppercase">
        <ElasticTableContext.Consumer>
          {elasticTable =>
            columns.map(({ align = 'left', alignTo, displayName }) => {
              if (alignTo) {
                const width = getTotalWidth(alignTo, elasticTable);
                return (
                  <th className={`text-${align}`} key={displayName} style={{ width }}>
                    {displayName}
                  </th>
                );
              }
              return <th key={displayName}>{displayName}</th>;
            })
          }
        </ElasticTableContext.Consumer>
      </tr>
    </thead>
    <tbody>{children}</tbody>
  </table>
);
