import React from 'react';
import { Field, useFormikContext } from 'formik';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { formatISO } from 'date-fns';
import { Grid, LoadingSpinner } from '@producepay/pp-ui';
import FormikDatePicker from 'src/components/formik/FormikDatePicker/FormikDatePicker';
import InSeason from 'src/components/molecules/AllocatePaymentInSeason';
import PreSeason from 'src/components/molecules/AllocatePaymentPreSeason';

import GET_PRODUCTS from 'src/graphql/queries/getProducts';
import Breadcrumbs from 'src/components/elements/Breadcrumbs';
import FormLabel from 'src/components/elements/FormLabel';
import { formatCurrency } from 'src/helpers/currency';

import AllocateUnreconciledPaymentFooter from './AllocateUnreconciledPaymentFooter';

interface AllocateUnreconciledPaymentProps {
  companyId: string;
}

function AllocateUnreconciledPaymentForm(props: AllocateUnreconciledPaymentProps): JSX.Element {
  const { values, ...formik } = useFormikContext<UnreconciledPaymentValues>();

  const updateValues = (data): void => {
    const dataWithAmounts = _.mapValues(data, val => val.map(product => ({ amount: '', held: false, ...product })));
    formik.setFieldValue('products', dataWithAmounts);
  };
  const { loading, error } = useQuery(GET_PRODUCTS, {
    variables: {
      companyId: parseInt(props.companyId, 10),
      asOfDate: formatISO(values.allocationDate, { representation: 'date' }),
    },
    fetchPolicy: 'no-cache',
    onCompleted: updateValues,
  });

  if (loading) {
    return (
      <div className="text-center py-32">
        <LoadingSpinner />
      </div>
    );
  }

  if (error) return null;

  return (
    <div className="pb-32">
      <Grid container className="px-8 py-6">
        <Grid className="my-6">
          <Breadcrumbs
            items={[
              { name: 'Unreconciled Payment', url: '/payments/unreconciled' },
              { name: 'Allocate Unreconciled Payment' },
            ]}
          />
        </Grid>
        <Grid sm="2/3">
          <Grid container>
            <Grid sm="1/6" className="border-r">
              <FormLabel label="Check #">{values.sourcePayment.checkNumber}</FormLabel>
            </Grid>
            <Grid sm="1/6" className="border-r">
              <FormLabel label="From/Sender" className="pl-4 pr-4">
                {values.sourcePayment.senderName}
              </FormLabel>
            </Grid>
            <Grid sm="1/6" className="border-r">
              <FormLabel label="Invoice/Reference #" className="pl-4 pr-4">
                {values.sourcePayment.referenceNumber}
              </FormLabel>
            </Grid>
            <Grid sm="1/6" className="border-r">
              <FormLabel label="Received Amount" className="pl-4 pr-4">
                {formatCurrency(values.sourcePayment.paymentAmount)}
              </FormLabel>
            </Grid>
            <Grid sm="1/6" className="border-r">
              <FormLabel label="Unreconciled Amount" className="pl-4 pr-4">
                {formatCurrency(values.sourcePayment.unreconciledAmount)}
              </FormLabel>
            </Grid>
            <Grid sm="1/6" className="border-r">
              <FormLabel label="Received On" className="pl-4 pr-4">
                {values.sourcePayment.paymentDate}
              </FormLabel>
            </Grid>
          </Grid>
        </Grid>
        <Grid sm="1/3">
          <Grid container>
            <Grid sm="1/2">
              <FormLabel className="pl-4 pr-4" label="Notes">
                {values.sourcePayment.unreconciledNotes}
              </FormLabel>
            </Grid>
            <Grid sm="1/2" className="pl-4 pr-4">
              <FormLabel label="Allocated On *" className="float-right w-50">
                <Field name="allocationDate" size="sm" alignLeft component={FormikDatePicker} />
              </FormLabel>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <InSeason showHeld={false} />
      <PreSeason />
      <AllocateUnreconciledPaymentFooter />
    </div>
  );
}

export default React.memo(AllocateUnreconciledPaymentForm);
