import React, { useEffect, useReducer } from 'react';
import { startCase, groupBy } from 'lodash';
import { useApolloClient, useQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import { useCommand } from 'src/helpers/command';
import { LoadingSpinner, useToast } from '@producepay/pp-ui';
import AllocationFooter, { SubmitButton, Sum } from 'src/components/molecules/AllocationFooter';
import GET_COMPANY_FUNDING_ACCOUNT_CONFIGURATION from 'src/graphql/queries/getCompanyFundingAccountConfiguration';
import { decimalSum } from 'src/helpers/currency';
import { PAYMENT_GROUPINGS, getPaymentGrouping, CompanyFundingAccountConfiguration } from 'src/helpers/products';
import { PreparedOutboundPaymentItem, PrepareItemsForOutboundPayment } from 'src/helpers/command/types';
import * as routes from 'src/routes';

import { checkExposure } from './unpreparedExposureWarning';
import UnpreparedBalanceReturnGroup from './UnpreparedBalanceReturnGroup';
import UnpreparedCompanyPrepayments from './UnpreparedCompanyPrepayments';
import { PreparationAction, PreparationItem, setState, initialize, reducer } from './preparationItemReducer';

interface UnpreparedListProps {
  balanceReturns: BalanceReturn[];
  prepaymentProducts: ProductPrepaymentItem[];
  refetch: () => void;
}

const getVisibleProducts = (prepaymentProducts, companyIdentifier) =>
  prepaymentProducts.filter(product => product.recipientCompanyIdentifier === companyIdentifier);

const UnpreparedList = (props: UnpreparedListProps) => {
  const { balanceReturns, prepaymentProducts, refetch } = props;
  const { companyIdentifier } = useParams();
  const history = useHistory();
  // probably move this to useQueries with filter for specific company once avail
  const { data, loading } = useQuery(GET_COMPANY_FUNDING_ACCOUNT_CONFIGURATION, { variables: { companyIdentifier } });
  const apolloClient = useApolloClient();
  const { send } = useCommand();
  const { addToastToQueue } = useToast();

  const recipientCompany = data?.company;

  const fundingAccountConfig = !loading && (data as CompanyFundingAccountConfiguration);

  // Have to re-filter these each time to avoid using outdated enclosed vars.
  const getInitialPreparations = (currentState = null) =>
    initialize(
      getVisibleProducts(prepaymentProducts, companyIdentifier),
      getVisibleProducts(balanceReturns, companyIdentifier),
      fundingAccountConfig,
      currentState,
    );

  const [preparations, dispatchPreparations]: [
    { [identifier: string]: PreparationItem },
    (action: PreparationAction) => void,
  ] = useReducer(reducer, getInitialPreparations());

  useEffect(() => {
    // If the selected company or data changes, we should wipe out our reducer state.
    dispatchPreparations(setState(getInitialPreparations(preparations)));
  }, [companyIdentifier, prepaymentProducts, data]);

  const visiblePrepaymentProducts = getVisibleProducts(prepaymentProducts, companyIdentifier);
  const prepaymentProductsByCompany = groupBy(visiblePrepaymentProducts, 'liableCompanyIdentifier');
  const visibleBalanceReturns = getVisibleProducts(balanceReturns, companyIdentifier);

  if (!visiblePrepaymentProducts.length && !visibleBalanceReturns.length) {
    return <h3 className="my-3">No products are available to prepare for this company.</h3>;
  }

  if (loading) {
    return <LoadingSpinner centered={true} className="py-32" />;
  }

  const preparedItems: PreparationItem[] = Object.values(preparations).filter(({ selected }) => selected);

  const selectedPaymentItems: PreparedOutboundPaymentItem[] = preparedItems.map(
    ({ selected, ...preparation }) => preparation,
  );

  const onPrepareSuccess = () => {
    addToastToQueue({
      body: `Successfully prepared the payment for ${recipientCompany.name}`,
      containerClassName: `last:mb-20`,
      header: 'Prepared payment',
      type: 'success',
    });
    refetch();
    setTimeout(() => history.push(routes.sendPayments()), 3000);
  };
  const balanceReturnsByGrouping = groupBy(visibleBalanceReturns, getPaymentGrouping);

  return (
    <div className="mr-3 mb-24">
      <h2 className="text-xl font-black my-6">{recipientCompany?.name}</h2>
      <hr />
      {visiblePrepaymentProducts.length ? <h4 className="text-lg my-6 font-bold">Prepayments</h4> : ''}
      {Object.keys(prepaymentProductsByCompany).map(destination => (
        <UnpreparedCompanyPrepayments
          dispatchPreparations={dispatchPreparations}
          key={destination}
          fundingAccountConfig={fundingAccountConfig}
          prepaymentProducts={prepaymentProductsByCompany[destination]}
          preparations={preparations}
        />
      ))}

      {visibleBalanceReturns.length ? <h4 className="text-lg my-6 font-bold">Balance Returns</h4> : ''}
      {PAYMENT_GROUPINGS.map(groupingName => {
        const items = balanceReturnsByGrouping[groupingName];
        return (
          items && (
            <UnpreparedBalanceReturnGroup
              allowPreparation={groupingName !== 'held'}
              balanceReturns={items}
              dispatchPreparations={dispatchPreparations}
              key={`balanceReturns-${groupingName}`}
              fundingAccountConfig={fundingAccountConfig}
              title={startCase(groupingName)}
            />
          )
        );
      })}

      <AllocationFooter
        sumLayout={<Sum label="Total Amount" amount={decimalSum('amount', selectedPaymentItems).toFixed(2)} />}
      >
        {selectedPaymentItems.length && (
          <SubmitButton
            className="bg-primary px-12"
            label="Prepare"
            handleSubmit={() =>
              send<PrepareItemsForOutboundPayment>({
                command: 'PrepareItemsForOutboundPayment',
                payload: {
                  outboundPaymentItems: selectedPaymentItems,
                },
              }).then(onPrepareSuccess)
            }
            shouldConfirmSubmission={() => checkExposure(apolloClient, preparedItems)}
          />
        )}
      </AllocationFooter>
    </div>
  );
};

export default UnpreparedList;
