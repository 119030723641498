import React from 'react';

import cx from 'classnames';
import './toggle.css';

function FormikToggle({ field, form, meta, className, ...props }) {
  const computedClassName = cx(
    className,
    {
      'bg-primary': field.value,
      'react-toggle-off': !field.value,
    },
    'react-toggle-label',
  );

  return (
    <>
      <label className={computedClassName} htmlFor={field.name}>
        <input
          checked={field.value || false}
          className="react-toggle-checkbox"
          id={field.name}
          type="checkbox"
          {...field}
          {...props}
        />
        <span className="react-toggle-button" />
      </label>
    </>
  );
}

export default React.memo(FormikToggle);
