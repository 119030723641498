import React, { useEffect, useRef, useState } from 'react';
import domAlign from 'dom-align';
import { Button } from '@producepay/pp-ui';
import ChevronDown from '@producepay/pp-ui/dist/components/icons/ChevronDown';

import { ButtonProps } from 'src/components/pp-ui.d.ts';

interface PopoverMenuProps {
  children: React.ReactNode;
  points?: string[];
  label: string;
  buttonClassName: string;
  menuClassName: string;
}

function PopoverMenu(props: PopoverMenuProps & ButtonProps) {
  const buttonRef = useRef(null);
  const menuRef = useRef(null);
  const { children, label, points = ['tl', 'bl'], menuClassName = '', ...rest } = props;
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const listener = () => {
      setIsOpen(false);
    };
    window.addEventListener('click', listener);

    return () => {
      window.removeEventListener('click', listener);
    };
  }, [setIsOpen]);

  useEffect(() => {
    if (isOpen && menuRef.current && buttonRef.current) {
      domAlign(menuRef.current, buttonRef.current, { points });
    }
  }, [buttonRef, isOpen, menuRef]);

  return (
    <div className="flex justify-center relative">
      <Button
        aria-controls={`menu-${label}`}
        color="default"
        variant="text"
        onClick={e => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
        ref={buttonRef}
        {...rest}
      >
        {label} <ChevronDown size="8" className="inline ml-1" />
      </Button>
      <div
        aria-expanded={isOpen}
        className={`${
          isOpen ? 'block' : 'hidden'
        } bg-white absolute flex-shrink-0 border-gray-300 border rounded-md whitespace-no-wrap ${menuClassName}`}
        data-testid="popovermenu"
        hidden={!isOpen}
        id={`menu-${label}`}
        ref={menuRef}
        style={{
          left: -9999,
          top: -9999,
        }}
      >
        {children}
      </div>
    </div>
  );
}

export default React.memo(PopoverMenu);
