import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import * as routes from '.';

import Dashboard from './Dashboard';
import ReceivePayments from './ReceivePayments';
import Companies from './Companies';
import Unreconciled from './Unreconciled';
import FundActivityIndexView from './FundActivityIndex';
import SendPayments from './SendPayments';
import LoginBar from '../components/elements/LoginBar';

function Authenticated() {
  return (
    <>
      <LoginBar />
      <div>
        <Switch>
          <Route exact path={routes.dashboard()} component={Dashboard} />
          <Route exact path={routes.receivePayments()} component={ReceivePayments} />
          <Route path={routes.companiesIndex()} component={Companies} />
          <Route path={routes.unreconciled()} component={Unreconciled} />
          <Route path={routes.fundActivityIndex()} component={FundActivityIndexView} />
          <Route path={routes.sendPayments()} component={SendPayments} />
          <Redirect to={routes.dashboard()} />
        </Switch>
      </div>
    </>
  );
}

export default React.memo(Authenticated);
