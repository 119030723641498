import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Accordion, AccordionBody, AccordionHeader, Badge } from '@producepay/pp-ui';
import { sortBy, uniqBy } from 'lodash';
import { PAYMENT_GROUPINGS, getPaymentGrouping } from 'src/helpers/products';
import { AlertCircle } from 'react-feather';
import { useQueryWithToast } from 'src/helpers/graphql';
import GET_COMPANIES from 'src/graphql/queries/getCompanies';
import Tooltip from 'src/components/elements/Tooltip';
import * as routes from 'src/routes';

import styles from './CompaniesList.module.css';

interface CompaniesListProps {
  approvedItems?: OutboundPaymentItem[];
  balanceReturns?: BalanceReturn[];
  mode: string;
  preparedItems?: OutboundPaymentItem[];
  unpreparedProducts?: ProductPrepaymentItem[];
}

interface CompanyListItem {
  subGroup: string;
  identifier: string;
  name: string;
  valid: boolean;
}

interface CategoryProps {
  active: boolean;
  groupingOrder?: string[];
  title: string;
  linkFactory: (identifier: string) => string;
  items: CompanyListItem[];
  selectedIdentifier: string;
}

const getIsLegacyInfoValid = (
  item: BalanceReturn | ProductPrepaymentItem | OutboundPaymentItem,
  companies: Company[],
) => {
  if (!companies) {
    return true;
  }
  return Boolean(companies.find(c => c.identifier === item.recipientCompanyIdentifier)?.isLegacyInfoValid);
};

const SubGroup = ({ active, title, linkFactory, selectedIdentifier, items }: CategoryProps) => (
  <div className="mx-1">
    <Accordion expanded>
      {title && (
        <AccordionHeader
          className={styles.accordionSubHead}
          caretClassName="text-gray-500 stroke-current"
          clickAnywhere
        >
          <span className="text-gray-500">{title.toUpperCase()}</span>
        </AccordionHeader>
      )}
      <AccordionBody>
        <ul>
          {items.map(({ name, identifier, valid }) => (
            <li
              className={`text-sm py-2 px-2 mx-1 ${
                active && selectedIdentifier === identifier
                  ? 'bg-primary-lighter bg-opacity-25 text-primary font-bold'
                  : ''
              }`}
              key={identifier}
            >
              <Link className="block w-full space-x-2" to={linkFactory(identifier)}>
                <span className="align-baseline">{name}</span>
                {valid ? (
                  ''
                ) : (
                  <Tooltip tooltipText="Company is missing a Bank Account and/or Asset Pool">
                    <AlertCircle
                      aria-label="Legacy Info Error"
                      className="inline align-text-top text-secondary"
                      size={16}
                    />
                  </Tooltip>
                )}
              </Link>
            </li>
          ))}
        </ul>
      </AccordionBody>
    </Accordion>
  </div>
);

const Category = ({ active, title, groupingOrder = [null], linkFactory, selectedIdentifier, items }: CategoryProps) => {
  // `items` is a list of of individual payments. First we sort by payment grouping to ensure
  // that we retain the most relevant grouping (e.g.: select Effective instead of Upcoming).
  // Then we need to uniq by identifier (i.e.: company).
  const companies = uniqBy(
    sortBy(items, p => groupingOrder.indexOf(p.subGroup)),
    p => p.identifier,
  );
  const companiesByGrouping: [string, CompanyListItem[]][] = groupingOrder.map(groupingName => [
    groupingName,
    sortBy(
      companies.filter(i => i.subGroup === groupingName),
      'name',
    ),
  ]);
  return (
    <Accordion expanded={active}>
      <AccordionHeader>
        <Link className="flex flex-row items-center justify-between w-full px-1" to={linkFactory('')}>
          {title}
          <Badge className="bg-primary">{uniqBy(items, p => p.identifier).length}</Badge>
        </Link>
      </AccordionHeader>
      <AccordionBody>
        {companiesByGrouping.map(([subGroupName, subGroupCompanies]) => (
          <SubGroup
            active={active}
            key={`category-${title}-${subGroupName}`}
            title={subGroupName}
            items={subGroupCompanies}
            linkFactory={linkFactory}
            selectedIdentifier={selectedIdentifier}
          />
        ))}
      </AccordionBody>
    </Accordion>
  );
};

const normalizeItemByCompany = (item, companies): CompanyListItem => ({
  identifier: item.recipientCompanyIdentifier,
  name: item.recipientCompanyName,
  subGroup: null,
  valid: getIsLegacyInfoValid(item, companies),
});

const normalizeUnpreparedItem = (item, companies): CompanyListItem => ({
  ...normalizeItemByCompany(item, companies),
  subGroup: getPaymentGrouping(item),
});

export default ({ approvedItems, balanceReturns, mode, unpreparedProducts, preparedItems }: CompaniesListProps) => {
  const { data: companiesData } = useQueryWithToast(GET_COMPANIES);
  const companies: Company[] = companiesData?.companies;
  const { companyIdentifier, sourceAccountIdentifier } = useParams<string>();
  const allUnprepared = [...(unpreparedProducts || []), ...(balanceReturns || [])].map(item =>
    normalizeUnpreparedItem(item, companies),
  );

  return (
    <div className="mb-25">
      {(unpreparedProducts || balanceReturns) && (
        <Category
          active={mode === 'unprepared'}
          title="Not Prepared"
          groupingOrder={PAYMENT_GROUPINGS}
          linkFactory={routes.sendPaymentsUnprepared}
          selectedIdentifier={companyIdentifier}
          items={allUnprepared}
        />
      )}
      {preparedItems && (
        <Category
          active={mode === 'prepared'}
          title="Prepared"
          linkFactory={routes.sendPaymentsPrepared}
          selectedIdentifier={companyIdentifier}
          items={preparedItems.map(item => normalizeItemByCompany(item, companies))}
        />
      )}
      {approvedItems && (
        <Category
          active={mode === 'approved'}
          title="Approved"
          linkFactory={routes.sendPaymentsApproved}
          selectedIdentifier={sourceAccountIdentifier}
          items={approvedItems.map(item => ({
            identifier: item.sourceAccountIdentifier,
            name: item.sourceAccountName,
            subGroup: null,
            valid: true,
          }))}
        />
      )}
    </div>
  );
};
