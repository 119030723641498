import React, { useState, useEffect, useContext, ReactNode } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';
import { snakeCaseKeys } from 'src/helpers/lodash';

import config from '../../auth_config.json';

const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID || config.clientId;

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);

export const AuthContext = React.createContext(null);
export const useAuth = () => useContext(AuthContext);
export const AuthProvider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
}: {
  children: React.ReactNode;
  onRedirectCallback: (appState?) => void;
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState();
  const [auth0Client, setAuth0Client] = useState(null);
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    const initAuth0 = async () => {
      const client = await createAuth0Client({
        cacheLocation: process.env.NODE_ENV === 'production' ? 'memory' : 'localstorage',
        ...snakeCaseKeys({
          ...config,
          clientId: AUTH0_CLIENT_ID,
          redirectUri: window.location.origin,
        }),
      });
      setAuth0Client(client);

      if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
        const { appState } = await client.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const authState = await client.isAuthenticated();
      setIsAuthenticated(authState);

      if (authState) {
        const u = await client.getUser();
        setUser(u);
      }

      setLoading(false);
    };
    initAuth0();
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const u = await auth0Client.getUser();
    setUser(u);
    setIsAuthenticated(true);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
