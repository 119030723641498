import gql from 'graphql-tag';
import { PRODUCE_PAY_COMPANY_IDENTIFIER } from 'src/helpers/products';
import COMPANY from '../fragments/company';
import FUNDING_ACCOUNT from '../fragments/fundingAccount';

export default gql`
  ${COMPANY}
  ${FUNDING_ACCOUNT}
  query CompanyFundingAccountConfiguration($companyIdentifier: String) {
    company(identifier: $companyIdentifier) {
      ...companyFields
    }
    destinationAccounts: fundingAccounts(companyIdentifier: $companyIdentifier, status: "active") {
      ...fundingAccountFields
    }
    sourceAccounts: fundingAccounts(companyIdentifier: "${PRODUCE_PAY_COMPANY_IDENTIFIER}", status: "active") {
      ...fundingAccountFields
    }
  }
`;
