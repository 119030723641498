import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@producepay/pp-ui';

interface MenuBarItem {
  label: string | JSX.Element;
  active: boolean;
  url: string;
}

interface MenuBarProps {
  items: MenuBarItem[];
}

function MenuBar(props: MenuBarProps): JSX.Element {
  const { items } = props;

  const itemClassName = 'py-6';

  const activeClassName = 'pt-6 pb-4 border-b-6 border-primary';

  return (
    <Grid container spacing={0} className="bg-white px-5 sm:px-8 py-6">
      {items.map(item => (
        <Grid key={`menu-${item.url}`} sm={`1/${items.length}`} spacing={0}>
          <Link to={item.url} className={item.active ? activeClassName : itemClassName}>
            {item.label}
          </Link>
        </Grid>
      ))}
    </Grid>
  );
}

export default MenuBar;
