import gql from 'graphql-tag';

const GET_COMPANY_BALANCE = gql`
  query CompanyBalance($companyIdentifier: String!) {
    company(identifier: $companyIdentifier) {
      name
    }
    companyBalance(companyIdentifier: $companyIdentifier) {
      availableForTransfer
      excessPayment
      held
      ledger
      scheduledRelease
    }
  }
`;

export default GET_COMPANY_BALANCE;
