import React from 'react';
import cx from 'classnames';

interface FormHeaderProps {
  className?: string;
  header: string;
  title: string;
}

function FormHeader(props: FormHeaderProps): JSX.Element {
  const { className, header, title } = props;

  const wrapperClassName = cx(className, 'py-4 sm:pt-5 px-5 sm:px-8');

  return (
    <div className={wrapperClassName}>
      <div>
        <div className="text-xs font-bold text-gray-600">{header}</div>
        <h2 className="text-xl font-bold leading-loose">{title}</h2>
      </div>
    </div>
  );
}

export default React.memo(FormHeader);
