import React from 'react';
import { groupBy } from 'lodash';
import { parseISO } from 'date-fns';
import { Grid } from '@producepay/pp-ui';
import { useHistory, useParams } from 'react-router-dom';
import { ChevronRight } from 'react-feather';

import * as routes from 'src/routes';
import { formatFriendly } from 'src/helpers/format';
import { decimalSum, formatCurrency } from 'src/helpers/currency';
import DataTable, { DataTableStyleWide } from 'src/components/molecules/DataTable';

import AmountAlert from '../AmountAlert';
import PreparedPaymentDetail from './PreparedPaymentDetail';

// preparedItems should be the filtered list of allPreparedItems.
interface PreparedListProps {
  allPreparedItems: OutboundPaymentItem[];
  visiblePreparedItems: OutboundPaymentItem[];
  refetch: () => void;
}

const columns = (getIsSelected: (item: OutboundPaymentItem) => boolean) => [
  { displayName: 'Source', name: 'sourceAccountName' },
  { displayName: 'Destination', name: 'destinationAccountName' },
  {
    displayName: 'Amount',
    name: 'totalAmount',
    formatter: (totalAmount, { productType, purpose, totalSuggestedAmount }) => (
      <AmountAlert
        amount={totalAmount}
        context="approved"
        paymentPurpose={purpose}
        productType={productType}
        suggestedAmount={totalSuggestedAmount}
      />
    ),
  },
  {
    displayName: 'Prepared By',
    name: 'preparedBy',
    formatter: (user, item) => (
      <div className="flex flex-no-wrap items-center justify-between">
        <div>
          <p>{user}</p>
          <p className="text-gray-500 text-xs">{formatFriendly(parseISO(item.preparedAt))}</p>
        </div>
        {getIsSelected(item) && <ChevronRight className="transform scale-75" />}
      </div>
    ),
  },
];

const getDetailRoute = (item: OutboundPaymentItem) =>
  routes.sendPaymentsPreparedDetail(
    item.recipientCompanyIdentifier,
    item.sourceAccountIdentifier,
    item.destinationAccountIdentifier,
  );

// Depending on the context, we use this both to determine which items are selected
// by url params, OR whether particular items with a given item in a batch.
const getIsSelected = params => (item: OutboundPaymentItem) =>
  params.recipientCompanyIdentifier === item.recipientCompanyIdentifier &&
  params.sourceAccountIdentifier === item.sourceAccountIdentifier &&
  params.destinationAccountIdentifier === item.destinationAccountIdentifier;

const batchItems = (items: OutboundPaymentItem[], allItems: OutboundPaymentItem[]): OutboundPaymentItemBatch => {
  if (!items[0]) {
    return null;
  }
  const allBatchItems = allItems.filter(getIsSelected(items[0]));
  return {
    ...items[0],
    items: allBatchItems,
    totalAmount: decimalSum('amount', allBatchItems).toFixed(2),
    totalSuggestedAmount: decimalSum('suggestedAmount', allBatchItems).toFixed(2),
  };
};

// We need access to filteredItems to decide which batches to show
// and we need access to allItems to calculate the totalAmount of a batch
// irrespective of what is currently selected.
const BatchList = ({
  allItems,
  filteredItems,
}: {
  allItems: OutboundPaymentItem[];
  filteredItems: OutboundPaymentItem[];
}) => {
  const history = useHistory();
  const params = useParams();
  const itemsByBatch = groupBy(
    filteredItems,
    item => `${item.sourceAccountIdentifier}-${item.destinationAccountIdentifier}`,
  );
  const batches = Object.values(itemsByBatch).map(items => batchItems(items, allItems));
  return (
    <DataTable<OutboundPaymentItemBatch>
      tbodyClass="bg-white"
      trClass={item => `cursor-pointer ${getIsSelected(params)(item) ? 'bg-primary-lighter bg-opacity-25' : ''}`}
      onClick={item => history.push(getDetailRoute(item))}
      columns={columns(getIsSelected(params))}
      keyName="identifier"
      rows={batches}
      {...DataTableStyleWide}
    />
  );
};

/**
 * PreparedList
 * ============
 * Have to provide both visible items (currently visible based on criteria)
 * from FilterBar and all items. This is so we can display _all_ items in a given
 * payment batch, even when some of them would otherwise be hidden by the current filter.
 */
const PreparedList = ({ allPreparedItems, visiblePreparedItems, refetch }: PreparedListProps) => {
  const params = useParams();
  const { recipientCompanyIdentifier, sourceAccountIdentifier: selectedSource } = params;
  const companyItems = visiblePreparedItems.filter(
    item => item.recipientCompanyIdentifier === recipientCompanyIdentifier,
  );
  if (!companyItems.length) {
    return <p className="p-4">No payments are currently prepared.</p>;
  }
  // The company name should be the same for all items in the filtered list.
  const { recipientCompanyName } = companyItems[0];

  return (
    <Grid container>
      <Grid md={selectedSource ? '2/3' : '1/1'}>
        <div className="px-3">
          <h2 className="text-xl font-black my-6 flex flex-row justify-between items-baseline">
            {recipientCompanyName}
            <span className="text-sm font-medium align-baseline">
              {formatCurrency(decimalSum('amount', companyItems))} prepared
            </span>
          </h2>
          <BatchList allItems={allPreparedItems} filteredItems={companyItems} />
        </div>
      </Grid>
      {selectedSource && (
        <>
          <Grid className="min-h-screen" md="1/3">
            <PreparedPaymentDetail
              batch={batchItems(companyItems.filter(getIsSelected(params)), allPreparedItems)}
              refetch={refetch}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default PreparedList;
