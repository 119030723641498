import React from 'react';
import { Field, FieldArray } from 'formik';
import { Decimal } from 'decimal.js';
import { TH } from '@producepay/pp-ui';

import './table.css';

const COLUMN_NAMES = [{ label: 'ID' }, { label: 'Company' }, { label: 'Amount' }];

function PreSeasonTable(): JSX.Element {
  return (
    <FieldArray name="products.preSeason">
      {({ form }) => (
        <div className="pt-3 table-secondary table-p-sm table-alternating-rows">
          <table className="w-full">
            <thead>
              <tr>
                {COLUMN_NAMES.map(th => (
                  <TH align="left" key={th.label} size="xxs-xs" weight="bold">
                    {th.label}
                  </TH>
                ))}
              </tr>
            </thead>
            <tbody>
              {form.values.products.preSeason.map((row, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <tr key={index}>
                  <td className="text-sm">{row.identifier}</td>
                  <td className="text-sm">{row.companyName}</td>
                  <Field name={`products.preSeason[${index}].amount`}>
                    {({ field }) => (
                      <td>
                        ${' '}
                        <input
                          type="number"
                          step="0.01"
                          data-testid="pre-season-amount"
                          className="textfield border outline-none focus:border-blue-400 textfield-sm text-sm"
                          value={field.value && new Decimal(field.value || '0.00').toString()}
                          {...field}
                        />
                      </td>
                    )}
                  </Field>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </FieldArray>
  );
}

export default React.memo(PreSeasonTable);
