import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { hot } from 'react-hot-loader';

import AppProviders from './contexts/AppProviders';
import Root from './routes/Root';

function App() {
  return (
    <BrowserRouter>
      <AppProviders>
        <Root />
      </AppProviders>
    </BrowserRouter>
  );
}

export default hot(module)(App);
