import gql from 'graphql-tag';

const GET_PRODUCTS = gql`
  query Products($companyId: Int!, $asOfDate: String) {
    inSeason: products(companyId: $companyId, asOfDate: $asOfDate, category: "in-season", onlyActive: true) {
      category
      companyName
      daysPastDue
      dueDate
      identifier
      lateFees
      minimumDue
      referenceNumber
      slug
      detailsUrl
      state
      totalDue
      principal
      repaid
      minimumDueRemaining
    }
    preSeason: products(companyId: $companyId, category: "pre-season", onlyActive: true) {
      category
      companyName
      identifier
      recuperationRate
    }
  }
`;

export default GET_PRODUCTS;
