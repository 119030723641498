import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@producepay/pp-ui';
import ProducePayLogo from 'src/assets/images/pp-logo.svg';
import * as routes from 'src/routes';
import { useAuth } from 'src/contexts/auth/AuthProvider';

const LoginBar = () => {
  const { user, isAuthenticated, logout } = useAuth();

  return (
    <div className="w-full px-8 py-4 border-b-1 flex justify-between bg-white">
      <div className="flex">
        <Link to={routes.dashboard}>
          <img src={ProducePayLogo} alt="ProducePay Home" />
        </Link>
      </div>
      {isAuthenticated && (
        <div className="flex items-center">
          <div className="px-5">Logged in as {user && user.name}</div>
          <div>
            <Button variant="outlined" onClick={() => logout({ returnTo: window.location.origin })}>
              Logout
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginBar;
