import React from 'react';
import { useFormikContext } from 'formik';
import { useQuery } from '@apollo/client';
import { LoadingSpinner } from '@producepay/pp-ui';
import _ from 'lodash';
import { formatISO } from 'date-fns';

import GET_PRODUCTS from 'src/graphql/queries/getProducts';

import FormHeader from 'src/components/elements/FormHeader';
import PreSeason from 'src/components/molecules/AllocatePaymentPreSeason';
import Unreconciled from 'src/components/molecules/AllocatePaymentUnreconciled';
import ExcessPayment from 'src/components/molecules/AllocatePaymentExcess';
import InSeason from 'src/components/molecules/AllocatePaymentInSeason';
import AllocatePaymentFooter from './AllocatePaymentFooter';

interface AllocatePaymentProps {
  companies: [];
}

function AllocatePayment(props: AllocatePaymentProps): JSX.Element {
  const { values, ...formik } = useFormikContext<ReceivePaymentFormValues>();
  const updateValues = (data): void => {
    const dataWithAmounts = _.mapValues(data, val => val.map(product => ({ amount: '', ...product })));
    formik.setFieldValue('products', dataWithAmounts);
  };
  const { loading, error } = useQuery(GET_PRODUCTS, {
    variables: {
      companyId: parseInt(values.companyId, 10),
      asOfDate: formatISO(values.receivedDate, { representation: 'date' }),
    },
    fetchPolicy: 'no-cache',
    onCompleted: updateValues,
  });
  if (loading) {
    return (
      <div className="text-center py-32">
        <LoadingSpinner />
      </div>
    );
  }

  if (error) return null;

  return (
    <div className="pb-32">
      <FormHeader header="Step 2" title="Allocate Payment" />
      <InSeason />
      <PreSeason />
      <ExcessPayment companies={props.companies} />
      <Unreconciled />
      <AllocatePaymentFooter />
    </div>
  );
}

export default React.memo(AllocatePayment);
