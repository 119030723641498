import React from 'react';
import FormikTextField from 'src/components/formik/FormikTextField';
import FormikCurrencyField from 'src/components/formik/FormikCurrencyField';
import FormikSelect from 'src/components/formik/FormikSelect';
import { Grid } from '@producepay/pp-ui';

import { CLIENT_COUNTRIES } from 'src/helpers/geo';

const FundingAccountFields = () => (
  <>
    <FormikTextField name="name" label="Account Name" />
    <FormikTextField name="institutionName" label="Bank Name" />
    <Grid container>
      <Grid sm="5/6">
        <FormikSelect
          label="Country"
          name="country"
          items={CLIENT_COUNTRIES.map(c => ({ label: c.name, value: c.code }))}
        />
      </Grid>
      <Grid sm="1/6">
        <FormikCurrencyField name="wireFee" label="Wire Fee" />
      </Grid>
    </Grid>
    <FormikSelect
      name="status"
      label="Status"
      items={[
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' },
      ]}
    />
  </>
);

export default FundingAccountFields;
