import React from 'react';
import { useHistory } from 'react-router-dom';
import { ToastProvider } from '@producepay/pp-ui';
import { AuthProvider } from './auth';
import AuthorizedApolloProvider from './AuthorizedApolloProvider';

function AppProviders({ children }) {
  const history = useHistory();
  const onRedirectCallback = appState => {
    history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
  };

  return (
    <AuthProvider onRedirectCallback={onRedirectCallback}>
      <ToastProvider>
        <AuthorizedApolloProvider>{children}</AuthorizedApolloProvider>
      </ToastProvider>
    </AuthProvider>
  );
}

export default React.memo(AppProviders);
