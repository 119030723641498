import React from 'react';

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  className?: string;
}

const Container = ({ children, className, ...props }: ContainerProps): JSX.Element => (
  <div className={`p-6 ${className || ''}`} {...props}>
    {children}
  </div>
);

export default Container;
