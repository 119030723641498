import React from 'react';
import PropTypes from 'prop-types';

import Container from '../Container';

function CardBody({ children, ...props }) {
  return <Container {...props}>{children}</Container>;
}

CardBody.propTypes = {
  children: PropTypes.node.isRequired,
};

export default React.memo(CardBody);
