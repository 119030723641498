/** CLIENT_COUNTRIES
 * List of countries and ISO Alpha 2 codes
 * for countries where we currently do business.
 */
export const CLIENT_COUNTRIES = [
  { code: 'US', name: 'United States' },
  { code: 'MX', name: 'Mexico' },
  { code: 'CA', name: 'Canada' },
  { code: 'CL', name: 'Chile' },
  { code: 'GT', name: 'Guatemala' },
];
