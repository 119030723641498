import React from 'react';
import { Field, useFormikContext } from 'formik';
import { Select } from '@producepay/pp-ui';

import Wrapper from '../FormikInputWrapper';

import './select.css';

/** FormikSelect
 * Wrapper for wiring up a Select field to the Formik Context.
 * Defers to `FormikInputWrapper` which adds labels and validation
 * error printing.
 */

function FormikSelect({ className = '', label = null, name, items, ...props }): JSX.Element {
  const { values, setFieldValue } = useFormikContext();
  const DEFAULT_ITEM = { label: '', value: null };
  return (
    <Field name={name} {...props}>
      {({ field, form }) => (
        <Wrapper className={className} name={name} label={label} form={form}>
          <Select
            size="small"
            aria-labelledby={`form-label-${name}`}
            name={name}
            items={[DEFAULT_ITEM, ...items]}
            {...field}
            selectedItem={items.find(i => i.value === values[name]) || DEFAULT_ITEM}
            onChange={item => setFieldValue(name, item.value)}
            {...props}
          />
        </Wrapper>
      )}
    </Field>
  );
}

export default React.memo(FormikSelect);
