import React, { RefObject } from 'react';
import RelativePortal from 'react-relative-portal';
import cx from 'classnames';
import dateFnsFormat from 'date-fns/format';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DayPickerInputProps } from 'react-day-picker';
import { FieldProps, useFormikContext } from 'formik';

import { TextField } from '@producepay/pp-ui';
import CalendarIcon from '@producepay/pp-ui/dist/components/icons/CalendarIcon';

import 'react-day-picker/lib/style.css';
import './datepicker.css';

interface DatePickerProps extends DayPickerInputProps {
  alignLeft?: boolean;
  id?: string;
  value: Date;
  placeholderDate?: Date;
}

const DATEPICKER_FORMAT = 'MM/dd/yyyy';

function formatInputDate(date, format, locale): string {
  return dateFnsFormat(date, format, { locale });
}

const FormikDatePicker = React.forwardRef((props: DatePickerProps & FieldProps, ref: RefObject<DayPickerInput>) => {
  const { alignLeft, field, id, placeholderDate = new Date(), inputProps = {}, dayPickerProps = {} } = props;

  const { setFieldValue } = useFormikContext();

  function RelativePortalOverlay({ classNames, children, ...overlayProps }): JSX.Element {
    return (
      <RelativePortal component="div" left={alignLeft ? -85 : 0} top={0}>
        <div className={cx(classNames.overlayWrapper, 'FinanceDatePicker')} {...overlayProps}>
          <div className={classNames.overlay}>{children}</div>
        </div>
      </RelativePortal>
    );
  }

  return (
    <div className="w-full">
      <DayPickerInput
        ref={ref}
        overlayComponent={RelativePortalOverlay}
        keepFocus={false}
        dayPickerProps={{
          ...dayPickerProps,
          onDayClick: day => setFieldValue(field.name, day),
        }}
        placeholder={`${dateFnsFormat(placeholderDate, DATEPICKER_FORMAT)}`}
        component={TextField}
        value={field.value}
        inputProps={{
          ...inputProps,
          iconPrefix: <CalendarIcon size={16} />,
          id,
          size: 'small',
          rounded: true,
          'data-testid': 'date-entry',
        }}
        format={DATEPICKER_FORMAT}
        formatDate={formatInputDate}
      />
    </div>
  );
});

export default React.memo(FormikDatePicker);
