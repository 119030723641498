import React, { RefObject } from 'react';
import RelativePortal from 'react-relative-portal';
import cx from 'classnames';
import dateFnsFormat from 'date-fns/format';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DayModifiers } from 'react-day-picker';

import { TextField } from '@producepay/pp-ui';
import CalendarIcon from '@producepay/pp-ui/dist/components/icons/CalendarIcon';

import 'react-day-picker/lib/style.css';
import './datepicker.css';

interface DatePickerProps {
  value: Date;
  onChange(day: Date, modifiers?: DayModifiers, e?: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
  dayPickerProps?: {};
  inputProps?: {};
  placeholderDate?: Date;
}

const DATEPICKER_FORMAT = 'MM/dd/yyyy';

function formatInputDate(date, format, locale): string {
  return dateFnsFormat(date, format, { locale });
}

function RelativePortalOverlay({ classNames, children, ...props }): JSX.Element {
  return (
    <RelativePortal component="div" left={0} top={0}>
      <div className={cx(classNames.overlayWrapper, 'FinanceDatePicker')} {...props}>
        <div className={classNames.overlay}>{children}</div>
      </div>
    </RelativePortal>
  );
}

const DatePicker = React.forwardRef((props: DatePickerProps, ref: RefObject<DayPickerInput>) => {
  const { value, onChange, placeholderDate = new Date(), inputProps = {}, dayPickerProps = {} } = props;

  return (
    <div className="my-4">
      <div className="w-full">
        <DayPickerInput
          ref={ref}
          overlayComponent={RelativePortalOverlay}
          keepFocus={false}
          dayPickerProps={{
            ...dayPickerProps,
            onDayClick: onChange,
          }}
          placeholder={`${dateFnsFormat(placeholderDate, DATEPICKER_FORMAT)}`}
          component={TextField}
          value={value}
          inputProps={{
            ...inputProps,
            iconPrefix: <CalendarIcon size={16} />,
            size: 'small',
            rounded: false,
          }}
          format={DATEPICKER_FORMAT}
          formatDate={formatInputDate}
        />
      </div>
    </div>
  );
});

export default React.memo(DatePicker);
