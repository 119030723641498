import gql from 'graphql-tag';
import FUNDING_ACCOUNT from '../fragments/fundingAccount';

const GET_FUNDING_ACCOUNTS = gql`
  ${FUNDING_ACCOUNT}
  query FundingAccounts($companyIdentifier: String, $identifier: String, $status: String) {
    fundingAccounts(companyIdentifier: $companyIdentifier, identifier: $identifier, status: $status) {
      ...fundingAccountFields
    }
  }
`;

export default GET_FUNDING_ACCOUNTS;
