import React, { useEffect } from 'react';
import { useAuth } from '../../contexts/auth';

import Authenticated from '../Authenticated';

function RootView({ path, ...props }) {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth();

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: window.location.pathname },
      });
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect, path]);

  return props.isErrored ? (
    <div className="w-full md:max-w-md mx-auto p-6 md:p-8">
      <h2 className="text-center text-3xl mb-6">Uh oh!</h2>

      <div className="leading-relaxed text-center">
        Looks like we&apos;ve run into a problem! Our team has been alerted and we&apos;re working on getting a fix out.
        Thanks for your patience!
      </div>
    </div>
  ) : isAuthenticated ? (
    <Authenticated />
  ) : null;
}

export default React.memo(RootView);
