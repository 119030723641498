import gql from 'graphql-tag';

export default gql`
  fragment companyFields on Company {
    code
    defaultPrepaymentSourceFundingAccountIdentifier
    legacyId
    identifier
    isSuspended
    name
    isLegacyInfoValid
  }
`;
