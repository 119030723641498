import React from 'react';

import { useFormikContext } from 'formik';
import { Decimal } from 'decimal.js';

import { Grid } from '@producepay/pp-ui';
import Card from '../../elements/Card';
import PreSeasonTable from '../PreSeasonTable';

import { formatCurrency } from '../../../helpers/currency';

function AllocatePaymentPreSeason(): JSX.Element {
  const { values } = useFormikContext<ReceivePaymentFormValues>();

  if (values.products.preSeason.length === 0) {
    return (
      <div className="px-5 sm:px-8 sm:py-3">
        <Card className="px-5 sm:py-8">
          <Grid container>
            <Grid className="md:border-b">
              <div className="float-left font-bold text-lg pb-4">Pre-Season</div>
            </Grid>
            <Grid>No Pre-Season Deals Available</Grid>
          </Grid>
        </Card>
      </div>
    );
  }

  const totalAllocated = values.products.preSeason
    .reduce((acc, row) => new Decimal(row.amount || '0.00').plus(acc), new Decimal('0.00'))
    .toString();

  return (
    <div>
      <div className="px-5 sm:px-8 sm:py-3">
        <Card className="px-5 sm:py-8">
          <Grid container>
            <Grid className="md:border-b">
              <div className="float-left font-bold text-lg pb-4">Pre-Season</div>
              <div className="float-right text-sm">
                <span className="font-bold">{formatCurrency(totalAllocated)}</span>
                <span> allocated to Pre-Season Products</span>
              </div>
            </Grid>
            <Grid sm="1/2">
              <PreSeasonTable />
            </Grid>
          </Grid>
        </Card>
      </div>
    </div>
  );
}

export default React.memo(AllocatePaymentPreSeason);
