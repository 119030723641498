import React from 'react';
import * as routes from 'src/routes';
import { Route, Redirect, Switch, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { LoadingSpinner } from '@producepay/pp-ui';
import GET_COMPANY_BALANCE from 'src/graphql/queries/getCompanyBalance';
import { formatCurrency } from 'src/helpers/currency';
import CardHeader from 'src/components/elements/CardHeader';
import MenuBar from 'src/components/elements/MenuBar';
import TransferForm from 'src/components/organisms/TransferForm';
import ExcessPaymentIndex from './ExcessPaymentIndex';
import CompanyProfile from './CompanyProfile';

function DashboardItem({ name, value = null }): JSX.Element {
  return (
    <>
      <span className="font-bold">{name}</span>
      {value && <span className="pl-2">{value}</span>}
    </>
  );
}

function LedgerDashboard(): JSX.Element {
  const { activeItem, companyIdentifier } = useParams();
  const { loading, data } = useQuery(GET_COMPANY_BALANCE, {
    variables: { companyIdentifier },
    fetchPolicy: 'no-cache',
  });

  const menuItems = data && [
    {
      label: <DashboardItem name="Ledger" value={formatCurrency(data.companyBalance.ledger)} />,
      active: activeItem === 'ledger',
      url: routes.companyLedger(companyIdentifier),
    },
    {
      label: <DashboardItem name="Transfer" value={formatCurrency(data.companyBalance.availableForTransfer)} />,
      active: activeItem === 'transfer',
      url: routes.companyTransfer(companyIdentifier),
    },
    {
      label: <DashboardItem name="Excess Payment" value={formatCurrency(data.companyBalance.excessPayment)} />,
      active: activeItem === 'excessPayment',
      url: routes.companyExcessPayment(companyIdentifier, ''),
    },
    {
      label: <DashboardItem name="Profile" />,
      active: activeItem === 'profile',
      url: routes.companyProfile(companyIdentifier),
    },
  ];
  return (
    <div className="pb-32">
      {loading ? (
        <div className="text-center py-32">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <CardHeader className="bg-white" titleClassName="font-extrabold text-2xl" title={data.company.name} />
          <MenuBar items={menuItems} />
          <Switch>
            <Route
              path={routes.companyTransfer(':companyIdentifier')}
              render={({ match }) => <TransferForm companyIdentifier={match.params.companyIdentifier} />}
            />
            <Route
              path={routes.companyExcessPayment(':companyIdentifier', ':action?')}
              render={({ match: { params } }) => (
                <ExcessPaymentIndex action={params.action} companyIdentifier={params.companyIdentifier} />
              )}
            />
            <Route
              path={routes.companyProfile(':companyIdentifier')}
              render={({ match: { params } }) => <CompanyProfile companyIdentifier={params.companyIdentifier} />}
            />
            <Redirect from="/companies/:companyIdentifier/heldBalance" to="/companies/:companyIdentifier/transfer" />
            {/* Redirect for clients who have an old URL in their bookmarks: */}
            <Redirect from="/ledgers" to="/companies" />
          </Switch>
        </>
      )}
    </div>
  );
}

export default LedgerDashboard;
