import React from 'react';
import DataTable, { LinkFormatter } from 'src/components/molecules/DataTable';
import { formatCurrency } from 'src/helpers/currency';

interface InSeasonDestinationProps {
  allocations: FundActivityAllocation[];
}

const columns = [
  {
    name: 'destinationSlug',
    displayName: 'Slug',
    formatter: (slug: string, row) => <LinkFormatter url={row.destinationDetailsUrl}>{slug}</LinkFormatter>,
  },
  { name: 'destinationReferenceNumber', displayName: 'Inv/Ref#' },
  { name: 'destinationMinimumStillDue', displayName: 'Still Due' },
  {
    name: 'destinationAmount',
    displayName: 'Allocated',
    headerClass: 'text-right',
    itemClass: 'text-right',
    formatter: amt => formatCurrency(amt),
  },
];

function InSeasonDestination(props: InSeasonDestinationProps) {
  const { allocations } = props;

  return (
    <>
      <h3 className="font-bold p-2">In-season</h3>
      <DataTable<FundActivityAllocation> columns={columns} keyName="destinationSlug" rows={allocations} />
    </>
  );
}

export default InSeasonDestination;
