import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { Decimal } from 'decimal.js';
import _ from 'lodash';
import { Button, LoadingSpinner, Grid } from '@producepay/pp-ui';

import Footer from 'src/components/elements/Footer';
import { decimalSum, formatCurrency } from 'src/helpers/currency';

const confirmForceCloseText = amount => (
  <>
    The allocation to In-Season product(s) is <span className="font-bold">{formatCurrency(amount)}</span> short and the
    product(s) will be forced to closed state. The missing funds will show as still due in the company&apos;s ledger.
    Proceed anyway?
  </>
);
const confirmPartialAllocationText = amount => (
  <>
    The allocation to In-Season product(s) is <span className="font-bold">{formatCurrency(amount)}</span> short and will
    be saved as a partial payment. The product(s) will not get settled. Proceed anyway?
  </>
);
const confirmForceCloseAndPartialText = amount => (
  <>
    The allocation to In-Season product(s) is <span className="font-bold">{formatCurrency(amount)}</span> short. For the
    product(s) forced to closed state, the missing funds will show as still due in the company&apos;s ledger. The others
    will be saved as a partial payment and as such will not get settled. Proceed anyway?
  </>
);

const AllocatePaymentFooter = (): JSX.Element => {
  const { values, ...formik } = useFormikContext<ReceivePaymentFormValues>();
  const [confirmAllocation, setConfirmAllocation] = useState(false);

  const products: Array<any> = values.products.inSeason.concat(values.products.preSeason);

  const allocations = products.concat(values.unreconciled, [values.excessPayment]);

  const totalAllocated = allocations
    .reduce((acc, row) => new Decimal(row.amount || '0.00').plus(acc), new Decimal('0.00'))
    .toString();

  const amountAvailable = new Decimal(values.amount || '0.00').minus(new Decimal(totalAllocated || '0.00')).toString();

  const heldProducts = products.filter(product => product.held === true);

  const heldBalance = heldProducts
    ? heldProducts
        .reduce((acc, row) => new Decimal(row.amount || '0.00').minus(row.minimumDue).plus(acc), new Decimal('0.00'))
        .toString()
    : '0.00';

  const belowMinimumDue = row =>
    !!row.amount &&
    new Decimal(row.minimumDue)
      .neg()
      .plus(new Decimal(row.amount))
      .lessThan('0');

  const findPartialAllocations = () => values.products.inSeason.filter(row => belowMinimumDue(row));

  const renderConfirmationText = (): JSX.Element => {
    const partialAllocations = findPartialAllocations();

    const hasForceClosedAllocations = _.some(partialAllocations, row => row.forceClosed);

    const hasShortPaidAllocations = _.every(partialAllocations, row => row.forceClosed) === false;

    const partialAllocationTotal = partialAllocations
      ? decimalSum('amount', partialAllocations)
          .minus(decimalSum('minimumDue', partialAllocations))
          .abs()
      : '0.00';

    if (hasShortPaidAllocations && hasForceClosedAllocations) {
      return confirmForceCloseAndPartialText(partialAllocationTotal);
    }
    if (hasForceClosedAllocations) {
      return confirmForceCloseText(partialAllocationTotal);
    }
    if (hasShortPaidAllocations) {
      return confirmPartialAllocationText(partialAllocationTotal);
    }
    return null;
  };

  const onAllocateClick = () => {
    if (findPartialAllocations().length) {
      setConfirmAllocation(true);
    } else {
      formik.handleSubmit();
    }
  };

  return (
    <Footer>
      <div className="w-full">
        <Grid container className="items-center">
          <Grid sm="1/6">
            <div className="text-xxs-xs uppercase text-white pb-2" id="amount-available-label">
              Amount Available
            </div>
            <div aria-labelledby="amount-available-label" className="text-lg text-white">
              {formatCurrency(amountAvailable)}
            </div>
          </Grid>
          <Grid sm="1/6">
            <div className="text-xxs-xs uppercase text-white pb-2" id="amount-allocated-label">
              Amount Allocated
            </div>
            <div aria-labelledby="amount-allocated-label" className="text-lg text-white">
              {formatCurrency(totalAllocated)}
            </div>
          </Grid>
          <Grid sm="1/6" className="border-l">
            <div className="pl-12">
              <div className="text-xxs-xs uppercase text-white pb-2" id="held-balance-label">
                Held Balance
              </div>
              <div aria-labelledby="held-balance-label" className="text-lg text-white">
                {formatCurrency(heldBalance)}
              </div>
            </div>
          </Grid>
          {formik.isSubmitting ? (
            <>
              <Grid sm="1/6" />
              <Grid sm="1/3" spacing={0}>
                <div className="px-5 sm:px-8 float-right">
                  <LoadingSpinner className="pr-16" />
                </div>
              </Grid>
            </>
          ) : confirmAllocation ? (
            <>
              <Grid sm="1/3">
                <div className="py-3 text-gray-100 float-right">{renderConfirmationText()}</div>
              </Grid>
              <Grid sm="1/6">
                <div className="float-right w-1/2 px-3">
                  <Button
                    className="w-full py-3 bg-dark"
                    variant="outlined"
                    label="Cancel"
                    type="button"
                    onClick={() => setConfirmAllocation(false)}
                  />
                </div>
                <div className="float-right w-1/2 px-3">
                  <Button className="w-full py-3" label="Yes" type="submit" onClick={formik.handleSubmit} />
                </div>
              </Grid>
            </>
          ) : (
            <>
              <Grid sm="1/6" />
              <Grid sm="1/3">
                <div className="px-5 sm:px-8 float-right">
                  {values.amount && (
                    <Button className="py-3" label="Save Payment Allocation" type="button" onClick={onAllocateClick} />
                  )}
                </div>
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </Footer>
  );
};

export default React.memo(AllocatePaymentFooter);
