interface RouteList {
  [key: string]: (...args: (string | number)[]) => string;
}

/**
 * Authenticated routes
 */
export const dashboard = () => '/';
export const receivePayments = () => '/payments/receive';
export const companiesIndex = () => '/companies';
export const companyLedger = companyIdentifier => `/companies/${companyIdentifier}/ledger`;
export const companyProfile = companyIdentifier => `/companies/${companyIdentifier}/profile`;
export const companyFundingAccounts = (companyIdentifier, filter = 'active') =>
  `/companies/${companyIdentifier}/profile/fundingAccounts/${filter}`;
export const companyFundingAccountNew = companyIdentifier =>
  `/companies/${companyIdentifier}/profile/fundingAccounts/new`;
export const companyFundingAccountEdit = (companyIdentifier, fundingAccountIdentifier) =>
  `/companies/${companyIdentifier}/profile/fundingAccounts/${fundingAccountIdentifier}/edit`;
export const companyTransfer = companyIdentifier => `/companies/${companyIdentifier}/transfer`;
export const companyExcessPayment = (companyIdentifier, action) =>
  `/companies/${companyIdentifier}/excessPayment/${action}`;
export const unreconciled = () => '/payments/unreconciled';
export const fundActivityIndex = () => '/fund_activities';

/**
 *  Send Payments Routes
 */
export const sendPayments = () => '/payments/send';
export const sendPaymentsUnprepared = companyIdentifier => `/payments/send/unprepared/${companyIdentifier}`;
export const sendPaymentsPrepared = companyIdentifier => `/payments/send/prepared/${companyIdentifier}`;
export const sendPaymentsPreparedDetail = (recipientCompanyIdentifier, sourceAccount, destinationAccountIdentifier) =>
  `/payments/send/prepared/${recipientCompanyIdentifier}/detail/${sourceAccount}/${destinationAccountIdentifier}`;
export const sendPaymentsApproved = sourceAccount => `/payments/send/approved/${sourceAccount}`;
export const sendPaymentsApprovedDetail = (sourceAccountIdentifier, destinationAccountIdentifier) =>
  `/payments/send/approved/${sourceAccountIdentifier}/detail/${destinationAccountIdentifier}`;
