import React, { useContext } from 'react';

interface ExcessPaymentContextValues {
  balanceAvailable: string;
  balanceAllocated: string;
}

const ExcessPaymentContext: React.Context<ExcessPaymentContextValues> = React.createContext({
  balanceAvailable: '0',
  balanceAllocated: '0',
});

function useExcessPaymentContext() {
  return useContext(ExcessPaymentContext);
}

export { ExcessPaymentContext, useExcessPaymentContext };
