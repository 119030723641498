import React from 'react';
import DataTable from 'src/components/molecules/DataTable';
import { last, split } from 'lodash';
import { formatCurrency } from 'src/helpers/currency';

interface PreSeasonDetailProps {
  allocations: FundActivityAllocation[];
}

const columns = [
  { name: 'destinationProductIdentifier', displayName: 'Deal ID', formatter: pid => last(split(pid, '_')) },
  { name: 'destinationCompanyName', displayName: 'Company' },
  {
    name: 'destinationAmount',
    displayName: 'Allocated',
    formatter: amt => formatCurrency(amt),
    headerClass: 'text-right',
    itemClass: 'text-right',
  },
];

function PreSeasonDetail(props: PreSeasonDetailProps) {
  const { allocations } = props;

  return (
    <>
      <h3 className="font-bold p-2">Pre-Season Deal</h3>
      <DataTable<FundActivityAllocation> columns={columns} rows={allocations} keyName="destinationReferenceNumber" />
    </>
  );
}

export default PreSeasonDetail;
