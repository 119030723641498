import React, { useContext } from 'react';

interface UnreconciledPaymentContextValues {
  balanceAvailable: string;
  balanceAllocated: string;
}

const UnreconciledPaymentContext: React.Context<UnreconciledPaymentContextValues> = React.createContext({
  balanceAvailable: '0',
  balanceAllocated: '0',
});

function useUnreconciledPaymentContext() {
  return useContext(UnreconciledPaymentContext);
}

export { UnreconciledPaymentContext, useUnreconciledPaymentContext };
