import gql from 'graphql-tag';
import OUTBOUND_PAYMENT_ITEM from '../fragments/outboundPaymentItem';
import UNPREPARED_PRODUCTS from '../fragments/unpreparedProducts';

export default gql`
  ${OUTBOUND_PAYMENT_ITEM}
  ${UNPREPARED_PRODUCTS}
  query SendPaymentsData {
    outboundPaymentItems {
      ...outboundPaymentItemFields
    }
    balanceReturns(category: "in-season") {
      ...balanceReturnFields
    }
    prepaymentProducts {
      ...prepaymentProductFields
    }
  }
`;
