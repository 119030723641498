import React from 'react';
import AlignedTable from 'src/components/molecules/ElasticTable/AlignedTable';
import UnreconciledDetailRow from './UnreconciledDetailRow';

interface UnreconciledDetailProps {
  allocations: FundActivityAllocation[];
}

const columns = [
  { displayName: 'Inv/Ref#', alignTo: ['created-on', 'effective-on'] },
  { displayName: 'Note', alignTo: ['company', 'link', 'entry-type'] },
  { displayName: 'Allocated', alignTo: ['amount'], align: 'right' },
  { displayName: '', alignTo: ['balance', 'hold'] },
];

function UnreconciledDetail(props: UnreconciledDetailProps) {
  const { allocations } = props;

  return (
    <>
      <h3 className="font-bold p-2">Unreconciled Payment</h3>
      <AlignedTable columns={columns}>
        {allocations.map(allocation => (
          <UnreconciledDetailRow
            key={allocation.allocationIdentifier}
            referenceNumber={allocation.destinationReferenceNumber}
            note={allocation.destinationNotes}
            amount={allocation.destinationAmount}
          />
        ))}
      </AlignedTable>
    </>
  );
}

export default UnreconciledDetail;
