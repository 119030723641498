import gql from 'graphql-tag';

const GET_PRODUCT_BALANCES = gql`
  query ProductBalances($companyIdentifier: String!, $positiveBalance: Boolean) {
    inSeason: products(
      companyIdentifier: $companyIdentifier
      category: "in-season"
      positiveBalance: $positiveBalance
    ) {
      actualValue
      availableBalance
      slug
      detailsUrl
      category
      heldBalance
      identifier
      referenceNumber
      receivedAmount: recentPaymentReceivedAmount
      receivedDate: recentPaymentReceivedDate
      releaseDate: recentPaymentReceivedCheckClearDate
      notes
    }
    external: products(companyIdentifier: $companyIdentifier, category: "external", positiveBalance: $positiveBalance) {
      actualValue
      availableBalance
      category
      heldBalance
      identifier
      referenceNumber
      receivedDate: recentPaymentReceivedDate
      releaseDate: recentPaymentReceivedCheckClearDate
      adminFee
      notes
    }
  }
`;

export default GET_PRODUCT_BALANCES;
