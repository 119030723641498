import React from 'react';
import { Field } from 'formik';
import { Grid, TextField } from '@producepay/pp-ui';
import FormLabel from '../../elements/FormLabel';

function FormRow(props): JSX.Element {
  const { index } = props;
  return (
    <Grid container className="pt-3">
      <Grid sm="1/6">
        <FormLabel label="Amount *" for={`received-payment-unreconciled-amount-${index}`} />
        <Field
          id={`received-payment-unreconciled-amount-${index}`}
          name={`unreconciled[${index}].amount`}
          size="small"
          type="number"
          as={TextField}
        />
      </Grid>
      <Grid sm="1/6">
        <FormLabel label="Invoice #" for={`received-payment-unreconciled-reference-${index}`} />
        <Field
          id={`received-payment-unreconciled-reference-${index}`}
          name={`unreconciled[${index}].referenceNumber`}
          size="small"
          as={TextField}
        />
      </Grid>
      <Grid sm="1/2">
        <FormLabel label="Note" for={`received-payment-unreconciled-note-${index}`} />
        <Field
          id={`received-payment-unreconciled-note-${index}`}
          name={`unreconciled[${index}].note`}
          size="small"
          as={TextField}
        />
      </Grid>
    </Grid>
  );
}

export default React.memo(FormRow);
