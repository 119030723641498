import React from 'react';

const SvgBankIcon = ({ color = 'currentColor', size = '24', ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <line x1="6" y1="11" x2="6" y2="18" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M11.4148 2.92234C11.7642 2.67018 12.2358 2.67018 12.5852 2.92234L21.1377 9.09456C21.5311 9.37848 21.3302 10 20.8451 10H3.15489C2.66971 10 2.46886 9.37848 2.86228 9.09456L11.4148 2.92234Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line x1="10" y1="11" x2="10" y2="18" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <line x1="14" y1="11" x2="14" y2="18" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <line x1="18" y1="11" x2="18" y2="18" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M3 19C3 18.7239 3.22386 18.5 3.5 18.5H20.5C20.7761 18.5 21 18.7239 21 19V21.5H3V19Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgBankIcon;
