import React, { useState } from 'react';
import { TextField } from '@producepay/pp-ui';
import Cancel from '@producepay/pp-ui/dist/components/icons/Cancel';
import { parseISO } from 'date-fns';
import { groupBy, startCase } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';

import { decimalSum, formatCurrency } from 'src/helpers/currency';
import { formatFriendly } from 'src/helpers/format';
import { formatProductType } from 'src/helpers/products';
import {
  ApproveOutboundPayment,
  UnprepareItemsForOutboundPayment,
  UpdateOutboundPaymentNote,
} from 'src/helpers/command/types';
import { DescriptionList, Title, Datum } from 'src/components/elements/DescriptionList';
import CommandButton from 'src/components/molecules/CommandButton';
import DataTable, { LinkFormatter } from 'src/components/molecules/DataTable';
import * as routes from 'src/routes';
import { Sidebar, SidebarHeader, SidebarFooter } from '../Sidebar';
import ExposureAlert from './ExposureAlert';
import AmountAlert from '../AmountAlert';

interface PaymentDetailProps {
  batch: OutboundPaymentItemBatch;
  refetch: () => void;
}

const columns = refetch => [
  {
    displayName: 'Slug',
    name: 'slug',
    itemClass: 'text-primary font-bold w-1/2',
    formatter: (slug, { detailsUrl }) => (
      <LinkFormatter className="whitespace-normal leading-4" url={detailsUrl} newTab>
        {slug}
      </LinkFormatter>
    ),
  },
  { displayName: 'Inv/Ref #', name: 'referenceNumber' },
  {
    displayName: 'Amount',
    name: 'amount',
    formatter: (amount, { productType, purpose, suggestedAmount }) => (
      <AmountAlert
        amount={amount}
        context="approved"
        paymentPurpose={purpose}
        productType={productType}
        suggestedAmount={suggestedAmount}
      />
    ),
  },
  {
    displayName: '',
    name: 'identifier',
    formatter: (identifier, { slug }) => (
      <CommandButton<UnprepareItemsForOutboundPayment>
        className="text-gray-500"
        color="default"
        command={{
          command: 'UnprepareItemsForOutboundPayment',
          payload: {
            outboundPaymentItemIdentifiers: [identifier],
          },
        }}
        onSuccess={() => {
          refetch();
        }}
        successTemplate={() => ({
          body: `The payment for ${slug} has been removed.`,
          containerClassName: `last:mb-20`,
          header: 'Payment Canceled',
        })}
        variant="text"
      >
        <Cancel size="8" />
      </CommandButton>
    ),
  },
];

export default ({ batch, refetch }: PaymentDetailProps) => {
  const { recipientCompanyIdentifier } = useParams();
  const [notes, setNotes] = useState(batch?.notes || '');
  const history = useHistory();
  // redirect to company index after deleting or approving OPIs
  if (!batch || batch.items.length === 0) {
    history.push(routes.sendPaymentsPrepared(recipientCompanyIdentifier));
    return null;
  }
  const { recipientCompanyName, destinationAccountName, sourceAccountName, preparedBy, preparedAt } = batch;
  return (
    <Sidebar>
      {Object.values(
        groupBy(
          batch.items.filter(({ purpose }) => purpose === 'prepayment'),
          i => i.liableCompanyIdentifier,
        ),
      ).map(group => (
        <ExposureAlert
          companyIdentifier={group[0].liableCompanyIdentifier}
          companyName={group[0].liableCompanyName}
          key={group[0].liableCompanyIdentifier}
        />
      ))}
      <SidebarHeader
        destination={recipientCompanyName}
        amount={formatCurrency(batch.totalAmount)}
        onCloseClicked={() => history.push(routes.sendPaymentsPrepared(recipientCompanyIdentifier))}
      />
      <DescriptionList className="mx-4 overflow-hidden">
        <Title>Source</Title>
        <Datum>{sourceAccountName}</Datum>
        <Title>Destination</Title>
        <Datum>{destinationAccountName}</Datum> {/* Temp until we build funding accounts */}
        <Title>Prepared by</Title>
        <Datum>
          {preparedBy}, {formatFriendly(parseISO(preparedAt))}
        </Datum>
      </DescriptionList>
      <div className="px-2 py-4">
        {Object.entries(groupBy(batch.items, 'purpose')).map(([purpose, purposeItems]) =>
          Object.entries(groupBy(purposeItems, p => p.productType || '')).map(([productType, items]) => (
            <div key={`product-list-${purpose}-${productType}`}>
              <h5 className="m-2 py-2 uppercase text-xs border-b-1">
                <span className="font-bold">{startCase(purpose.replace(/_/, ' '))}</span>
                {productType?.length ? ` | ${formatProductType(productType)}` : ''}
                <span className="float-right font-bold">{formatCurrency(decimalSum('amount', items))}</span>
              </h5>
              <DataTable<OutboundPaymentItem>
                columns={columns(refetch)}
                keyName="identifier"
                rows={items}
                trClass="even:bg-table-highlight"
                data-testid="prepped-opi-details"
              />
            </div>
          )),
        )}
      </div>
      <div className="p-4">
        <TextField
          aria-label="Notes"
          name="notes"
          placeholder="Notes"
          onChange={evt => setNotes(evt.target.value)}
          rows={4}
          type="textarea"
          style={{ height: 'auto' }}
          value={notes}
          data-testid="update-outbound-payment-note"
        />
        <div className="w-full flex justify-end mb-2.5">
          <CommandButton<UpdateOutboundPaymentNote>
            className="py-2 pr-2 font-bold flex-shrink-0 text-sm"
            command={{
              command: 'UpdateOutboundPaymentNote',
              payload: {
                outboundPaymentItemIdentifiers: batch.items.map(({ identifier }) => identifier),
                notes,
              },
            }}
            successTemplate={() => ({
              body: `The note has been updated.`,
              containerClassName: `last:mb-20`,
              header: 'Payment Note Updated',
            })}
            onSuccess={refetch}
            variant="text"
            data-testid="update-outbound-payment-note-button"
          >
            Update Note
          </CommandButton>
        </div>
      </div>
      <SidebarFooter>
        <CommandButton<UnprepareItemsForOutboundPayment>
          className="text-gray-500 px-2"
          color="default"
          command={{
            command: 'UnprepareItemsForOutboundPayment',
            payload: {
              outboundPaymentItemIdentifiers: batch.items.map(({ identifier }) => identifier),
            },
          }}
          onSuccess={refetch}
          successTemplate={() => ({
            body: `This payment to ${batch.recipientCompanyName} has been canceled.`,
            containerClassName: `last:mb-20`,
            header: 'Payment Canceled',
          })}
          variant="text"
        >
          Cancel Payment
        </CommandButton>
        <CommandButton<ApproveOutboundPayment>
          command={{
            command: 'ApproveOutboundPayment',
            payload: {
              outboundPaymentItemIdentifiers: batch.items.map(({ identifier }) => identifier),
              sourceAccountIdentifier: batch.sourceAccountIdentifier,
              destinationAccountIdentifier: batch.destinationAccountIdentifier,
              notes,
            },
          }}
          successTemplate={() => ({
            body: `This payment to ${batch.recipientCompanyName} has been approved.`,
            containerClassName: `last:mb-20`,
            header: 'Payment Approved',
          })}
          onSuccess={refetch}
        >
          Approve
        </CommandButton>
      </SidebarFooter>
    </Sidebar>
  );
};
