import * as Yup from 'yup';

export default Yup.object().shape({
  name: Yup.string().required('Required'),
  institutionName: Yup.string().required('Required'),
  country: Yup.string()
    .length(2)
    .required('Required'),
  wireFee: Yup.number().required('Required'),
  status: Yup.string()
    .oneOf(['active', 'inactive'])
    .required('Required'),
});
