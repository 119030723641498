import { useReducer } from 'react';
import { intersection, every } from 'lodash';

export enum ReducerTypes {
  RESET_FILTERS,
  SET_PURPOSE,
  SET_SHOW_SUSPENDED,
  SET_COMPANIES,
  SET_SEARCH,
}

// Consistent interface for both filter status (criteria for inclusion)
// and normalized form of item data to use in comparison.
export interface NormalizedFilterProperties {
  companyNames?: string[];
  suspended?: boolean;
  purpose?: string;
  search?: string;
}

export interface FilterAction extends NormalizedFilterProperties {
  type: ReducerTypes;
}

export const initialState: NormalizedFilterProperties = {
  companyNames: [],
  suspended: false,
  purpose: null,
  search: null,
};

export const reducer = (
  state: NormalizedFilterProperties = initialState,
  { type, ...action }: FilterAction,
): NormalizedFilterProperties => {
  switch (type) {
    case ReducerTypes.RESET_FILTERS:
      return initialState;
    case ReducerTypes.SET_PURPOSE:
      return {
        ...state,
        purpose: action.purpose,
      };
    case ReducerTypes.SET_SHOW_SUSPENDED:
      return {
        ...state,
        suspended: action.suspended,
      };
    case ReducerTypes.SET_COMPANIES:
      return {
        ...state,
        companyNames: action.companyNames,
      };
    case ReducerTypes.SET_SEARCH:
      return {
        ...state,
        search: action.search,
      };
    default:
      return state;
  }
};

export const useFilter = () =>
  useReducer<(state: NormalizedFilterProperties, action: FilterAction) => NormalizedFilterProperties, FilterAction>(
    reducer,
    null,
    () => initialState,
  );

export const getIsEntitySuspended = (
  item: BalanceReturn | ProductPrepaymentItem | OutboundPaymentItem,
  companies: Company[],
) => {
  if (!companies) {
    return true;
  }
  return (
    Boolean(companies.find(c => c.identifier === item.recipientCompanyIdentifier)?.isSuspended) ||
    ('liableCompanyId' in item &&
      Boolean(companies.find(c => c.identifier === item.liableCompanyIdentifier)?.isSuspended))
  );
};

export const normalizeBalanceReturn = (item: BalanceReturn, companies: Company[]): NormalizedFilterProperties => ({
  companyNames: [item.recipientCompanyName],
  suspended: getIsEntitySuspended(item, companies),
  purpose: 'balance_return',
  search: `${item.slug} ${item.identifier} ${item.referenceNumber}`.toLowerCase(),
});

export const normalizePrepaymentItem = (
  item: ProductPrepaymentItem,
  companies: Company[],
): NormalizedFilterProperties => ({
  companyNames: [item.recipientCompanyName, item.liableCompanyName],
  suspended: getIsEntitySuspended(item, companies),
  purpose: 'prepayment',
  search: `${item.slug} ${item.identifier} ${item.referenceNumber}`.toLowerCase(),
});

export const normalizeOPI = (item: OutboundPaymentItem, companies: Company[]): NormalizedFilterProperties => ({
  companyNames: [item.recipientCompanyName, item.liableCompanyName],
  suspended: getIsEntitySuspended(item, companies),
  purpose: item.purpose,
  search: `${item.slug} ${item.productIdentifier} ${item.referenceNumber}`.toLowerCase(),
});

export const filter = (filterState: NormalizedFilterProperties) => (properties: NormalizedFilterProperties) =>
  every([
    filterState.companyNames.length === 0 || intersection(filterState.companyNames, properties.companyNames).length,
    filterState.suspended ? true : !properties.suspended, // filterState.suspended indicates "show suspended"
    filterState.purpose ? filterState.purpose === properties.purpose : true,
    filterState.search ? properties.search.includes(filterState.search.toLowerCase()) : true,
  ]);
