import React from 'react';
import FundActivityIndex from '../../components/organisms/FundActivityIndex';

const FundActivityIndexView = () => {
  return (
    <>
      <FundActivityIndex />
    </>
  );
};

export default React.memo(FundActivityIndexView);
