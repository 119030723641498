import React from 'react';
import { Field } from 'formik';

import { Grid, TextField } from '@producepay/pp-ui';
import FormLabel from '../../../elements/FormLabel';
import FormikDatePicker from '../../../formik/FormikDatePicker';

const CheckDetails = (): JSX.Element => (
  <Grid container>
    <Grid sm="1/3">
      <FormLabel label="Check # *" for="payment-details-check-number" />
      <Field name="checkNumber" size="small" as={TextField} id="payment-details-check-number" />
    </Grid>
    <Grid sm="2/3">
      <FormLabel label="Scheduled Release*" for="payment-details-scheduled-release" />
      <Field
        alignLeft
        component={FormikDatePicker}
        name="checkReleaseDate"
        size="small"
        id="payment-details-scheduled-release"
      />
    </Grid>
  </Grid>
);

export default CheckDetails;
