import React, { useState } from 'react';
import { useQueryWithToast } from 'src/helpers/graphql';

import { Grid, LoadingSpinner, Select } from '@producepay/pp-ui';
import Card from 'src/components/elements/Card';
import CardBody from 'src/components/elements/CardBody';
import CommandButton from 'src/components/molecules/CommandButton';
import { SetDefaultPrepaymentSourceFundingAccountForCompany } from 'src/helpers/command/types';
import GET_COMPANY_FUNDING_ACCOUNT_CONFIGURATION from 'src/graphql/queries/getCompanyFundingAccountConfiguration';

const SetDefaultPrepaymentSource = ({ companyIdentifier }) => {
  const [currentIdentifier, setCurrentIdentifier] = useState(null);
  const { data, loading, error } = useQueryWithToast(GET_COMPANY_FUNDING_ACCOUNT_CONFIGURATION, {
    variables: { companyIdentifier },
    onCompleted: result => {
      if (result?.company) {
        setCurrentIdentifier(result.company.defaultPrepaymentSourceFundingAccountIdentifier);
      }
    },
  });

  if (loading) {
    return <LoadingSpinner centered={true} />;
  }
  if (!error) {
    const ppFundingAccounts = data.sourceAccounts.map(({ identifier, name }) => ({ label: name, value: identifier }));
    return (
      <>
        <Card square={false}>
          <CardBody>
            <h5 className="text-sm pb-2 font-bold">Default Prepayment Source Account</h5>
            <Grid container>
              <Grid md="1/3">
                <Select
                  items={ppFundingAccounts}
                  selectedItem={ppFundingAccounts.find(a => a.value === currentIdentifier) || ppFundingAccounts[0]}
                  onChange={({ value }) => setCurrentIdentifier(value)}
                  size="small"
                />
              </Grid>
              <Grid md="1/3">
                <CommandButton<SetDefaultPrepaymentSourceFundingAccountForCompany>
                  command={{
                    command: 'SetDefaultPrepaymentSourceFundingAccountForCompany',
                    companyIdentifier,
                    fundingAccountIdentifier: currentIdentifier,
                  }}
                >
                  Save
                </CommandButton>
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </>
    );
  }

  return null;
};

export default SetDefaultPrepaymentSource;
