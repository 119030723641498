import React from 'react';
import { useLocation, matchPath, Link } from 'react-router-dom';
import Card from 'src/components/elements/Card';
import CardBody from 'src/components/elements/CardBody';

interface SideNavItem {
  name: string;
  url: string;
  icon: JSX.Element;
}

interface SideNavProps {
  items: SideNavItem[];
}

const SideNav = ({ items }: SideNavProps) => {
  const { pathname } = useLocation();

  const isActive = (item: SideNavItem) => {
    return matchPath(pathname, { path: item.url });
  };
  return (
    <Card>
      <CardBody>
        <ul>
          {items.map(item => (
            <li
              className={`p-2 w-full text-sm ${isActive(item) ? 'bg-primary-lighter bg-opacity-25' : ''}`}
              key={item.url}
            >
              <Link
                className={`${
                  isActive(item) ? 'text-primary' : 'text-gray-500'
                } w-full font-bold space-x-2 flex flex-row items-center space-x-2`}
                to={item.url}
              >
                <span className="pr-2">{item.icon}</span>
                {item.name}
              </Link>
            </li>
          ))}
        </ul>
      </CardBody>
    </Card>
  );
};

export default SideNav;
