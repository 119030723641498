import React from 'react';

function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
}

function describeArc(x, y, radius, startAngle, endAngle) {
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);

  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

  return ['M', start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(' ');
}
// Appease the purgeCSS gods
// text-gray-300 text-primary

const CircleChart = ({
  backgroundColor = 'gray-300',
  color = 'primary',
  factor = 1,
  size = 24,
  startDegrees = 0,
  strokeWidth = 4,
  ...props
}) => {
  const r = size / 2 - strokeWidth / 2;
  const angle = 360 * Math.min(factor, 0.99999);
  return (
    <svg height={size} viewBox={`0 0 ${size} ${size}`} width={size} {...props}>
      <path
        className={`stroke-current text-${backgroundColor}`}
        fill="transparent"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        d={describeArc(size / 2, size / 2, r, 0, 359.9)}
      />
      <path
        className={`stroke-current text-${color}`}
        fill="transparent"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        d={describeArc(size / 2, size / 2, r, startDegrees, startDegrees + angle)}
      />
    </svg>
  );
};

export default CircleChart;
