import { Decimal } from 'decimal.js';

const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

export const formatCurrency = (amount: number | string | Decimal): string => formatter.format(amount as number);

export const toDecimal = (number: string | number | Decimal): string => new Decimal(number).toFixed(2);

export const decimalSum = <T>(key: string, values: Array<T>) =>
  values.reduce((acc, row) => new Decimal(row[key] || '0.00').plus(acc), new Decimal('0.00'));

export const isNumeric = (value: string) => Boolean(value.match(/^[0-9,.$ ]*$/));

export const cleanNumericString = (value: string) =>
  value.length && isNumeric(value) ? value.replace(/[^.0-9]/g, '') : value;
