import React from 'react';
import { Field, useFormikContext } from 'formik';

import { Grid } from '@producepay/pp-ui';
import CheckDetails from './CheckDetails';
import FormHeader from '../../elements/FormHeader';
import FormLabel from '../../elements/FormLabel';
import Card from '../../elements/Card';
import FormikDatePicker from '../../formik/FormikDatePicker';
import FormikSelect from '../../formik/FormikSelect';
import CompanyAutocomplete from '../CompanyAutocomplete/CompanyAutocomplete';

interface PaymentDetailsProps {
  companies: [];
}

const paymentTypeOptions = [
  { label: 'Check', value: 'check' },
  { label: 'Wire', value: 'wire' },
  { label: 'ACH', value: 'ach' },
];

function PaymentDetails(props: PaymentDetailsProps): JSX.Element {
  const { values, ...formik } = useFormikContext<ReceivePaymentFormValues>();

  return (
    <div data-testid="received-payment-details">
      <FormHeader header="Step 1" title="Enter Payment Details" />
      <div className="px-5 sm:px-8 pb-6">
        <Card className="px-5 py-6">
          <Grid container>
            <Grid sm="1/6">
              <FormLabel label="Received On *" for="payment_details_received_date" />
              <Field id="payment_details_received_date" name="receivedDate" size="small" component={FormikDatePicker} />
            </Grid>
            <Grid sm="1/4">
              <FormLabel label="From / Sender *" id="payment_details_from" />
              <CompanyAutocomplete
                items={props.companies}
                inputSize="small"
                labelledBy="payment_details_from"
                onItemSelected={item => formik.setFieldValue('companyId', item.value)}
              />
            </Grid>
            <Grid sm="1/6">
              <FormLabel label="Type *" id="payment_details_type_label" />
              <FormikSelect
                aria-labelledby="payment_details_type_label"
                name="type"
                size="small"
                items={paymentTypeOptions}
                placeholder="Choose one"
              />
            </Grid>
            {values.type && (
              <Grid sm="1/6">
                <FormLabel label="Amount *" for="payment_details_amount" />
                <Field name="amount">
                  {({ field }) => (
                    <input
                      type="number"
                      step="0.01"
                      id="payment_details_amount"
                      className="w-3/4 textfield-sm border outline-none rounded focus:border-blue-400 textfield-sm text-sm"
                      {...field}
                    />
                  )}
                </Field>
              </Grid>
            )}
            {values.type === 'check' && (
              <Grid sm="1/4">
                <CheckDetails />
              </Grid>
            )}
          </Grid>
        </Card>
      </div>
    </div>
  );
}

export default React.memo(PaymentDetails);
