import { useAuth } from 'src/contexts/auth/AuthProvider';
import api from 'src/helpers/axios';
import { camelToSnakeKeysDeep } from 'src/helpers/lodash';
import { Command } from './types';

export const send = async <T extends Command>(payload: T, { token }) => {
  try {
    return await api.post('/command', payload, { headers: { Authorization: `Bearer ${token}` } });
  } catch (e) {
    if (e.errors?.details) {
      throw new Error(e.errors.details);
    } else if (e.error) {
      throw new Error(e.error);
    } else if (e.response?.data?.error) {
      throw new Error(e.response.data.error.message || e.response.data.error);
    } else if (e.message) {
      throw new Error(e.message);
    } else {
      throw e;
    }
  }
};

export const useCommand = () => {
  const { getTokenSilently } = useAuth();
  return {
    send: async <T extends Command>(payload: T, options = {}) => {
      const token = await getTokenSilently();
      await send<T>(camelToSnakeKeysDeep(payload), { ...options, token });
    },
  };
};
