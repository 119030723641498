import gql from 'graphql-tag';

export default gql`
  fragment fundingAccountFields on FundingAccount {
    companyIdentifier
    country
    identifier
    institutionName
    name
    status
    wireFee
  }
`;
