import React, { useState } from 'react';
import { ChevronDown } from 'react-feather';
import PropTypes from 'prop-types';

const Alert = ({ color, children, className, title, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={`rounded-lg bg-${color} p-4 bg-opacity-25 ${className}`} {...props}>
      <button
        className={`text-${color} font-bold focus:outline-none flex flex-row justify-between w-full`}
        onClick={() => setIsOpen(!isOpen)}
        type="button"
      >
        {title}
        <ChevronDown
          className="transition-transform duration-300 "
          size={18}
          style={{ transform: `rotate(${isOpen ? 360 : 180}deg)` }}
        />
      </button>
      <div
        className={`${isOpen ? 'block opacity-100' : 'hidden opacity-0'} transition-all duration-300`}
        hidden={!isOpen}
      >
        {children}
      </div>
    </div>
  );
};
Alert.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.node.isRequired,
};

Alert.defaultProps = {
  className: '',
  color: 'secondary',
  children: null,
};

export default Alert;
