import React, { createContext, ReactNode, useEffect, useRef, useState } from 'react';
import { Button, Grid, LoadingSpinner, useToast } from '@producepay/pp-ui';

import Footer from 'src/components/elements/Footer';
import { tokenize } from 'src/helpers/common';
import { formatCurrency } from 'src/helpers/currency';

interface AllocationFooterProps {
  children: ReactNode;
  sumLayout: ReactNode;
}

interface SubmitButtonProps {
  label: string;
  shouldConfirmSubmission: () => ReactNode;
  handleSubmit: () => Promise<Error | void> | void;
  className?: string;
  variant?: string;
}

const AllocationFooterContext = createContext({ doSubmit: null, setConfirmationMessage: null, setOnSubmit: null });

export const SubmitButton = ({ label, shouldConfirmSubmission, handleSubmit, ...props }: SubmitButtonProps) => (
  <AllocationFooterContext.Consumer>
    {({ doSubmit, setConfirmationMessage, setOnSubmit }) => (
      <Button
        className="px-12 py-3 bg-dark inline-block"
        label={label}
        type="button"
        onClick={async () => {
          const confirmationMessage = await shouldConfirmSubmission();
          if (confirmationMessage) {
            setConfirmationMessage(confirmationMessage);
            setOnSubmit(handleSubmit);
          } else {
            doSubmit(handleSubmit);
          }
        }}
        {...props}
      />
    )}
  </AllocationFooterContext.Consumer>
);

export const Sum = (props: { label: string; amount: string }) => {
  const id = `allocationfooter-sum-${tokenize(props.label)}`;
  return (
    <Grid sm="1/2">
      <div className="text-xxs-xs uppercase text-white pb-2" id={id}>
        {props.label}
      </div>
      <div aria-labelledby={id} className="text-lg text-white">
        {formatCurrency(props.amount)}
      </div>
    </Grid>
  );
};

const AllocationFooter = (props: AllocationFooterProps): JSX.Element => {
  const [confirmationMessage, setConfirmationMessage] = useState(null);
  const { addToastToQueue } = useToast();
  const [onSubmit, setOnSubmit] = useState({ func: null });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { children, sumLayout } = props;
  const mountState = useRef(false);

  // isMounted is an antipattern, but in this case submitFn often causes the component
  // to unmount as a side effect of routing. So we may not want to call `setIsSubmitting(false)`.
  useEffect(() => {
    mountState.current = true;
    return () => {
      mountState.current = false;
    };
  });

  const doSubmit = async submitFn => {
    setIsSubmitting(true);
    try {
      await submitFn();
    } catch (e) {
      addToastToQueue({
        actions: [{ label: 'Dismiss' }],
        body: e.message,
        header: 'Error',
        type: 'error',
      });
    }
    if (mountState.current) {
      setIsSubmitting(false);
      setConfirmationMessage(false);
    }
  };

  return (
    <AllocationFooterContext.Provider
      value={{ doSubmit, setConfirmationMessage, setOnSubmit: func => setOnSubmit({ func }) }}
    >
      <Footer>
        <div className="w-full">
          <Grid container>
            <Grid sm="1/3">
              <Grid container>{sumLayout}</Grid>
            </Grid>
            {isSubmitting ? (
              <>
                <Grid sm="1/3" />
                <Grid sm="1/6" />
                <Grid sm="1/6" spacing={0}>
                  <div className="px-5 sm:px-8 float-right">
                    <LoadingSpinner className="h-px pr-16" />
                  </div>
                </Grid>
              </>
            ) : confirmationMessage ? (
              <>
                <Grid sm="1/2">
                  <div className="py-3 text-gray-100 float-right">{confirmationMessage}</div>
                </Grid>
                <Grid sm="1/6">
                  <div className="float-right w-1/2 px-3">
                    <Button
                      className="w-full py-3 bg-dark"
                      variant="outlined"
                      label="Cancel"
                      type="button"
                      onClick={() => setConfirmationMessage(false)}
                    />
                  </div>
                  <div className="float-right w-1/2 px-3">
                    <Button className="w-full py-3" label="Yes" type="button" onClick={() => doSubmit(onSubmit.func)} />
                  </div>
                </Grid>
              </>
            ) : (
              <>
                <Grid sm="1/3" />
                <Grid className="flex flex-row justify-end items-center" sm="1/3">
                  {children}
                </Grid>
              </>
            )}
          </Grid>
        </div>
      </Footer>
    </AllocationFooterContext.Provider>
  );
};

export default AllocationFooter;
