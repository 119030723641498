import React from 'react';

interface BreadcrumbsItem {
  name: string;
  url?: string;
}

interface BreadcrumbsProps {
  items: BreadcrumbsItem[];
}

function Breadcrumbs(props: BreadcrumbsProps) {
  const lastIndex = props.items.length - 1;

  return (
    <div className="font-extrabold text-xl">
      {props.items.map((item, index) =>
        index === lastIndex ? (
          item.name
        ) : (
          <>
            <a href={item.url}>{item.name}</a> {' > '}
          </>
        ),
      )}
    </div>
  );
}

export default Breadcrumbs;
