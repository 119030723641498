import { startCase } from 'lodash';
import Decimal from 'decimal.js';

export const PRODUCE_PAY_COMPANY_IDENTIFIER = 'companies_32';

export const getCompanyId = (companyIdentifier: string) =>
  parseInt(/companies_([0-9]+)/.exec(companyIdentifier)[1], 10);

export const getCompanyIdentifierFromLegacyId = (legacyId: string) => `companies_${legacyId}`;

export const formatProductType = (type: string): string => startCase(type.replace(/_/, ' '));

export const PAYMENT_GROUPINGS = ['effective', 'upcoming', 'held'];

/** CompanyFundingAccountConfiguration
 *  Data structure containing info about all accounts relevant to a given Company.
 *  Consumed by `getDefaultAccounts` as well by various components to provide available funding account
 *  options for payments.
 *
 *  Contains:
 *  - company: the Company entity, currently just used to provide `defaultPrepaymentSourceFundingAccount`
 *  - sourceAccounts: for now this should just be the PP source account fixtures)
 *  - destinationAccounts: all FundingAccounts belonging to the given company
 */

export interface CompanyFundingAccountConfiguration {
  company: Company;
  destinationAccounts: FundingAccount[];
  sourceAccounts: FundingAccount[];
}

export const getDefaultAccounts = (data: CompanyFundingAccountConfiguration) => {
  if (!data) {
    return null;
  }

  const prepaymentSourceFundingAccount =
    data.sourceAccounts.find(a => a.identifier === data.company.defaultPrepaymentSourceFundingAccountIdentifier) ||
    data.sourceAccounts.find(({ name }) => name === 'Funds');

  return {
    balanceReturn: {
      destination: data.destinationAccounts.find(() => true),
      source: data.sourceAccounts.find(({ name }) => name === 'Farmers Wire'),
    },
    prepayment: {
      destination: data.destinationAccounts?.find(() => true),
      source: prepaymentSourceFundingAccount,
    },
  };
};

export const getPaymentGrouping = (item: ProductPrepaymentItem | BalanceReturn): string => {
  if (!('heldAmount' in item)) {
    // it is a Prepayment
    return 'effective';
  }
  if (new Decimal(item.heldAmount).greaterThan(0)) {
    return 'held';
  }
  if ((Date.parse(item.checkReleaseDate) || 0) > Date.now()) {
    return 'upcoming';
  }
  return 'effective';
};
