import React, { useReducer, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Decimal } from 'decimal.js';
import { formatCurrency } from 'src/helpers/currency';
import { formatISO, isFuture } from 'date-fns';
import * as routes from 'src/routes';
import GET_PRODUCT_BALANCES from 'src/graphql/queries/getProductBalances';
import { snakeCaseKeys } from 'src/helpers/lodash';
import { useCommand } from 'src/helpers/command';
import { AllocateTransferCommand } from 'src/helpers/command/types';
import AllocationFooter, { Sum, SubmitButton } from 'src/components/molecules/AllocationFooter';
import Card from 'src/components/elements/Card';
import CardBody from 'src/components/elements/CardBody';
import Container from 'src/components/elements/Container';
import CardHeader from 'src/components/elements/CardHeader';
import { LoadingSpinner, Snackbar } from '@producepay/pp-ui';
import { AllocationDestination } from 'src/components/molecules/ProductAllocationForm';
import AllocateTransferForm from './AllocateTransferForm';
import InSeasonTable from './InSeasonTable';

const REDIRECT_DELAY = 2000;

interface TransferFormProps {
  companyIdentifier: string;
  pollInterval?: number;
}

const decimalSum = (collection: Product[], key) =>
  collection.reduce((acc, row) => new Decimal(row[key] || '0.00').plus(acc), new Decimal('0.00'));

const flattenAllocations = (allocationsByCategory: AllocationDestination[]) =>
  Object.keys(allocationsByCategory).reduce((acc, category) => [...acc, ...allocationsByCategory[category]], []);

const calculateAmountAllocated = (destinations): Decimal => {
  if (!destinations.length) {
    return new Decimal('0');
  }
  return decimalSum(destinations, 'amount');
};

const calculateBalanceAvailable = (destinations, sourceTotal) =>
  sourceTotal.minus(calculateAmountAllocated(destinations));

const calculateValueSelected = sources =>
  sources.reduce((acc, row) => new Decimal(row.actualValue).plus(acc), new Decimal('0.00'));

// amount that is selected but predates the check release date
const calculateAmountUnreleased = (sources: Product[]) =>
  decimalSum(
    sources.filter(({ releaseDate }) => isFuture(new Date(releaseDate))),
    'availableBalance',
  );

const formatDestinations = (allocations: AllocationDestination[]) =>
  allocations.map(({ identifier, ...rest }) =>
    snakeCaseKeys({
      productIdentifier: identifier,
      ...rest,
    }),
  );

const allocationsReducer = (state, { category, allocations }) => ({
  ...state,
  [category]: allocations,
});

function TransferForm({ companyIdentifier, pollInterval = 30000 }: TransferFormProps): JSX.Element {
  const [action, setAction] = useState('select');
  const history = useHistory();
  const command = useCommand();
  const [selectedInSeasonIds, setSelectedInSeasonIds] = useState([]);
  const [allocationsByCategory, updateAllocations] = useReducer(allocationsReducer, {});
  const [error, setError] = useState(null);
  const { loading, data, refetch } = useQuery(GET_PRODUCT_BALANCES, {
    variables: { companyIdentifier, positiveBalance: true },
    fetchPolicy: 'no-cache',
    pollInterval,
  });
  const selectedInSeasonSources = selectedInSeasonIds.map(id =>
    data.inSeason?.find(({ identifier }) => identifier === id),
  );

  const amountSelected = decimalSum(selectedInSeasonSources, 'availableBalance');
  const unreleasedAmountSelected = calculateAmountUnreleased(selectedInSeasonSources);
  const heldAmountSelected = decimalSum(selectedInSeasonSources, 'heldBalance');
  const allocations = flattenAllocations(allocationsByCategory);

  async function handleRelease(destinations: AllocationDestination[], allocationDate: Date): Promise<void> {
    const payload = {
      command: 'AllocateTransfer',
      sources: {
        'in-season': selectedInSeasonSources.map(({ identifier }) => snakeCaseKeys({ productIdentifier: identifier })),
        external: [],
      },
      destinations: formatDestinations(destinations),
      allocationDate: formatISO(allocationDate, { representation: 'date' }),
    };
    try {
      command.send<AllocateTransferCommand>(payload);
      await new Promise(resolve => setTimeout(resolve, REDIRECT_DELAY));
      history.push(routes.fundActivityIndex());
    } catch (err) {
      setError(`Error: ${err.message}`);
    }
  }

  return loading ? (
    <div className="text-center py-32">
      <LoadingSpinner />
    </div>
  ) : (
    <>
      {action === 'allocate' ? (
        <AllocateTransferForm
          companyIdentifier={companyIdentifier}
          actualValueSelected={calculateValueSelected(selectedInSeasonSources)}
          balanceAvailable={calculateBalanceAvailable(allocations, amountSelected).toString()}
          balanceAllocated={calculateAmountAllocated(allocations).toString()}
          updateAllocations={updateAllocations}
          onSubmit={date => handleRelease(allocations, date)}
        />
      ) : (
        <>
          <Container>
            <h2 className="text-xl font-extrabold my-0">Transfer Funds</h2>
          </Container>
          <Container>
            <Card square={false}>
              <CardHeader size="lg" title="In-Season" titleClassName="font-bold" />
              <CardBody>
                {data.inSeason.length ? (
                  <InSeasonTable
                    products={data.inSeason}
                    onHoldStatusChanged={refetch}
                    onSelectionChanged={rows => setSelectedInSeasonIds(rows.map(row => row.identifier))}
                  />
                ) : (
                  <p>No In-Season Products Available</p>
                )}
              </CardBody>
            </Card>
          </Container>
          {!loading && (
            <AllocationFooter
              sumLayout={
                <>
                  <Sum amount={amountSelected.toString()} label="Balance Available" />
                  <Sum amount={heldAmountSelected.toString()} label="Held Amount" />
                </>
              }
            >
              {new Decimal(amountSelected).greaterThan(0) && (
                <SubmitButton
                  className="px-12 mx-2 py-3"
                  handleSubmit={() => setAction('allocate')}
                  shouldConfirmSubmission={() =>
                    unreleasedAmountSelected.greaterThan(0) ? (
                      <>
                        <span className="font-bold">{formatCurrency(unreleasedAmountSelected)} </span>
                        is scheduled for release on a future date and has not been cleared yet. Are you sure you want to
                        transfer these funds?
                      </>
                    ) : (
                      false
                    )
                  }
                  label="Allocate Available Funds"
                />
              )}
            </AllocationFooter>
          )}
        </>
      )}
      {error && <Snackbar error onClose={() => setError(null)} isOpen={error} label={error} />}
    </>
  );
}

export default TransferForm;
